// <!-- API -->
import store from '@/store';
import useECNBProfile from '@/hooks/store/useECNBProfile';

/**
 * Fetch the session information.
 */
const fetchSessionInformation = async () => {
    // Wrap store in session profile.
    const profile = useECNBProfile(store);
    // Fetch the session information.
    const session = await profile.getSessionInformation(true);
    // Show session details.
    console.dir(session);
};

/**
 * Register the store.
 *
 * @param {V.App} app Vue application instance.
 */
export const registerStore = async (app) => {
    app.use(store);
    console.log(
        `[plugin::store] registered @ ${new Date().toLocaleTimeString()}`
    );
    // Initialize the store.
    try {
        await fetchSessionInformation();
    } catch (err) {
        console.error(err);
        // Do nothing. Likely failing because user is not currently logged in.
    }
    return store;
};
