// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

// HACK - FormData is not generically typed :'(
//        See: https://github.com/microsoft/TypeScript/issues/43797
/**
 * @typedef {FormData} CreateLocationBatchRequest Request sent to the endpoint.
 * - `file: File`
 */

// <!-- MODELS -->
import { DatasetBatch } from '@/models/datasets';

// <!-- API -->
import {
    fetchAccountBatches,
    fetchAccountBatchById,
    createAccountBatches,
    deleteAccountBatchById,
    updateAccountBatchById,
} from '@/api/accounts/datasets';

// <!-- ROUTES -->
const ROUTES = {
    GET_LOCATION_BATCHES: (account, location) =>
        `accounts/${account}/locations/${location}/datasets`,
    CREATE_LOCATION_BATCH: (account, location) =>
        `accounts/${account}/locations/${location}/datasets`,
    UPDATE_LOCATION_BATCH: (account, location, dataset) =>
        `accounts/${account}/locations/${location}/datasets/${dataset}`,
    DELETE_LOCATION_BATCH: (account, location, dataset) =>
        `accounts/${account}/locations/${location}/datasets/${dataset}`,

    GET_BATCH_DATA: (account, location, dataset) =>
        `accounts/${account}/locations/${location}/datasets/${dataset}/data`,
    EXPORT_BATCH_DATA: (account, location, dataset) =>
        `accounts/${account}/locations/${location}/datasets/${dataset}/file`,
    INGEST_BATCH_DATA: (account, location, dataset) =>
        `accounts/${account}/locations/${location}/datasets/${dataset}/ingest`,
};

// <!-- REQUESTS -->

/**
 * Fetch all dataset batches underneath a specific account.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @returns {Promise<import('@/models/datasets/DatasetBatch').DatasetBatchResource[]>}
 */
export const fetchLocationBatches = async (
    account = { id: 8 },
    location = { id: -1 }
) => {
    /** @type {import('axios').AxiosResponse<{ data: import('@/models/datasets/DatasetBatch').DatasetBatchPayload[] }>} */
    const response = await axios().get(
        ROUTES.GET_LOCATION_BATCHES(account.id, location.id)
    );
    return response.data.data.map((payload) =>
        new DatasetBatch({ payload }).toResource()
    );
};

/**
 * Fetch specific dataset batch by id, if it's underneath the specified location.
 *
 * If not part of the indicated location, it will return null.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @param {{ file: File }} request Request body.
 * @returns {Promise<import('@/models/datasets/DatasetBatch').DatasetBatchResource>}
 */
export const createLocationBatch = async (
    account = { id: 8 },
    location = { id: -1 },
    request = { file: null }
) => {
    const data = new FormData();
    data.append('file', request.file);
    /** @type {import('axios').AxiosResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchPayload }, CreateLocationBatchRequest>} */
    const response = await axios().post(
        ROUTES.CREATE_LOCATION_BATCH(account.id, location.id),
        data
    );
    const payload = response.data.dataset;
    return new DatasetBatch({ payload }).toResource();
};

/**
 * Update location batch by id.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'|'profileId'>} request Request parameter and body.
 * @returns {Promise<import('@/api').StatusResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchResource }>>}
 */
export const updateLocationBatchById = async (
    account = { id: 8 },
    location = { id: -1 },
    request = { id: -1, profileId: -1 }
) => {
    /** @type {import('axios').AxiosResponse<import('@/api').StatusResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchResource }>, Pick<import('@/models/datasets/DatasetBatch').DatasetBatchPayload, 'mapping_profile_id'>>} */
    const response = await axios().post(
        ROUTES.UPDATE_LOCATION_BATCH(account.id, location.id, request.id),
        {
            mapping_profile_id: request.profileId,
        }
    );
    const payload = response.data.dataset;
    return {
        status: response.data.status,
        dataset: new DatasetBatch({ payload }).toResource(),
    };
};

/**
 * Delete location batch by id.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} request Request parameter.
 */
export const deleteLocationBatchById = async (
    account = { id: 8 },
    location = { id: -1 },
    request = { id: -1 }
) => {
    /** @type {import('axios').AxiosResponse<{ status: "deleted", data: import('@/models/datasets/DatasetBatch').DatasetBatchPayload }>} */
    const response = await axios().delete(
        ROUTES.DELETE_LOCATION_BATCH(account.id, location.id, request.id)
    );
    return {
        status: response.data.status,
        dataset: response.data.data,
    };
};

/**
 * Create multiple batches for a single location endpoint.
 *
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationResource, 'id'>} location
 * @param {File[]} files
 * @returns {ReturnType<createAccountBatches>}
 */
export const prepareBatches = async (account, location, files) => {
    const datasets = files.map((file) => ({ location, file }));
    return createAccountBatches(account, datasets);
};

/**
 * Export batch data file for the specified location batch.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} request Request parameter.
 */
export const exportBatchData = async (
    account = { id: 8 },
    location = { id: -1 },
    request = { id: -1 }
) => {
    /** @type {import('axios').AxiosResponse} */
    const response = await axios().get(
        ROUTES.EXPORT_BATCH_DATA(account.id, location.id, request.id)
    );
    // NOTE: This endpoint downloads a file.
    //   If no error has been raised, we can assume it's successful?
    return response.data;
};

/**
 * Get batch data for the specified location batch.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} request Request parameter.
 */
export const fetchBatchData = async (
    account = { id: 8 },
    location = { id: -1 },
    request = { id: -1 }
) => {
    /** @type {import('axios').AxiosResponse<Blob>} */
    const response = await axios().get(
        ROUTES.GET_BATCH_DATA(account.id, location.id, request.id),
        { responseType: 'blob' }
    );
    const contents = response.data;
    return await contents.text();
};

/**
 * Ingest batch data for the specified location batch.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<LocationResource, 'id'>} location Request parameter.
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} request Request parameter.
 * @returns {Promise<import('@/api').StatusResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchResource }>>} Affected resource.
 */
export const ingestBatchData = async (
    account = { id: 8 },
    location = { id: -1 },
    request = { id: -1 }
) => {
    const response = await axios().post(
        ROUTES.INGEST_BATCH_DATA(account.id, location.id, request.id)
    );
    const affected = await fetchAccountBatchById(account, request);
    // If no error, return true.
    return { status: response.status, dataset: affected };
};

// <!-- EXPORTS -->
export {
    fetchAccountBatches,
    fetchAccountBatchById,
    createAccountBatches,
    deleteAccountBatchById,
    updateAccountBatchById,
} from '@/api/accounts/datasets';

export default {
    DatasetBatch,
    // <!-- ACCOUNT CRUD (Forwarded) -->
    fetchAccountBatches,
    fetchAccountBatchById,
    createAccountBatches,
    updateAccountBatchById,
    deleteAccountBatchById,
    // <!-- LOCATION CRUD -->
    fetchLocationBatches,
    createLocationBatch,
    updateLocationBatchById,
    deleteLocationBatchById,
    // <!-- SERVICES -->
    prepareBatches,
    fetchBatchData,
    exportBatchData,
    ingestBatchData,
};
