// <!-- API -->
import { DateRange } from '@/utils/filters/dates';
import { DateRangeModifier } from '@/utils/filters/dates';

// <!-- UTILITIES -->
import pick from 'just-pick';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').IDate} IDate A native {@link Date} instance or JavaScript {@link ITimestamp}. */
/** @typedef {import('@/utils/filters').ITimestamp} ITimestamp Instant in datetime specifing time (in milliseconds) since the JavaScript epoch. */
/** @typedef {import('@/utils/filters').IUnixTimestamp} IUnixTimestamp Instant in datetime specifing time (in seconds) since the Unix epoch */
/** @typedef {import('@/utils/filters').IInterval} IInterval An object that combines two dates to represent the time interval. */
/** @typedef {import('@/utils/filters').IDateRangeModifier} IDateRangeModifier Record containing the checked modifier states for a date range. */
/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter Record containing the range and modifier state */

/**
 * @class
 * Record containing the inclusive lower and upper date range bounds.
 * @implements {IDateRangeFilter}
 */
export class DateRangeFilter {
    /**
     * Create an date range.
     * @param {Readonly<Partial<IDateRangeFilter>>} [props]
     * @returns {IDateRangeFilter}
     */
    static create = (props = {}) => new DateRangeFilter(props);

    /**
     * Clone an existing date range.
     * @param {Readonly<IDateRangeFilter>} [source]
     * @returns {IDateRangeFilter}
     */
    static clone = (source = new DateRangeFilter()) =>
        new DateRangeFilter(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<IDateRangeFilter>} [target]
     * @returns {IDateRangeFilter}
     */
    static data = (target) => pick(target, 'start', 'end', 'checked');

    /**
     * Create an date range.
     * @param {Readonly<Partial<IDateRangeFilter>>} [props]
     */
    constructor(props) {
        // Get the extents.
        const range = DateRange.create(props);

        // Get the modifier.
        const modifier = DateRangeModifier.create(props);

        /** @type {IDate} The start date of the interval. Clamped between safe date values. */
        this.start = range.start;

        /** @type {IDate} The end date of the interval. */
        this.end = range.end;

        /** @type {IDateRangeModifier['checked']} Modifier enabled state. */
        this.checked = modifier.checked;
    }
}
