// <!-- TYPES -->
import { Model } from '@/models/Model';
import { DynamicEnumFactory } from '@/utils/DynamicEnum';
/** @typedef {ReturnType<WeatherState['toPayload']>} WeatherStatePayload */
/** @typedef {ReturnType<WeatherState['toResource']>} WeatherStateResource */

/** Resource and payload use the same fieldnames. */
const FIELDS = DynamicEnumFactory().fromKeys(['id', 'name']);

/**
 * Represents a state with weather stations.
 * @class
 */
export class WeatherState extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    toPayload() {
        return {
            /** @type {String} */
            [FIELDS.id]: this.id,
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
        };
    }

    toResource() {
        return {
            /** @type {String} */
            [FIELDS.id]: this.id,
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
        };
    }
}
