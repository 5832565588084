<template>
    <TeleportModal
        to="#modal"
        :show="isOpen"
        v-bind="{
            size,
            theme,
            disabled,
            busy,
            ignore,
            title,
            subtitle,
            icon,
            hideCloseButton,
            showCloseAction: showCloseAction === true,
        }"
        @close="$emit('close', $event)"
        @escape="$emit('close', $event)"
    >
        <template
            v-if="isHeaderVisible"
            #header
        ></template>
        <template #default="{ disabled, busy }">
            <ModalContent
                :class="[
                    'p-4',
                    {
                        'rounded-lg': !isHeaderVisible,
                        'rounded-b-lg': isHeaderVisible,
                    },
                ]"
                v-bind="{
                    theme,
                    disabled,
                    busy,
                }"
            >
                <slot></slot>
            </ModalContent>
        </template>
    </TeleportModal>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        onMounted,
        onUnmounted,
        ref,
        computed,
        watchEffect,
    } from 'vue';

    // <!-- UTILTIES -->
    import { Size, Theme, ModalIcon } from '@/utils/enums';

    // <!-- COMPONENTS -->
    import TeleportModal from '@/components/modals/TeleportModal.vue';
    import ModalContent from '@/components/modals/ModalContent.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'BasicModal',
        components: {
            TeleportModal,
            ModalContent,
        },
        props: {
            /** Whether the modal should be shown or hidden. */
            show: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Whether the modal's close button should be shown or hidden. */
            hideCloseButton: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Whether the modal's default close action should be shown or hidden. Ignored when using slotted components. */
            showCloseAction: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: null,
            },
            /**
             * Determine modal icon to use, if a slot isn't available.
             *
             * @example
             * ```
             * <ModalHeader :icon="null"  /> // No icon (or fallback to slot#icon).
             * <ModalHeader icon="warning" /> // Warning icon.
             * ```
             * */
            icon: {
                /** @type {V.PropType<ModalIcon[keyof ModalIcon] | null>} */
                // @ts-ignore
                type: String,
                default: null,
                validator: (value) =>
                    value === null ||
                    Object.values(ModalIcon).includes(
                        /** @type {ModalIcon[keyof ModalIcon]} */ (value)
                    ),
            },
            /** Determine height and width variant using the {@link Size} enum. */
            size: {
                /** @type {V.PropType<Size[keyof Size]>} */
                // @ts-ignore
                type: String,
                default: Size.medium,
                validator: (size) =>
                    Object.values(Size).includes(
                        /** @type {Size[keyof Size]} */ (size)
                    ),
            },
            /** Determine the inner component themes using the {@link Theme} enum. */
            theme: {
                /** @type {V.PropType<Theme[keyof Theme]>} */
                // @ts-ignore
                type: String,
                default: Theme.white,
                validator: (theme) =>
                    theme === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme]} */ (theme)
                    ),
            },
            /** Property used to specify the title text. Overriden when a slot is available. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Property used to specify the subtitle text. Overriden when a slot is available. */
            subtitle: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true` pointer events on the modal overlay are ignored. No `close` event is fired when clicking the overlay. Overlay events are 'opt-in' due to some limitations on production. */
            ignore: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
        },
        emits: ['close'],
        setup(props) {
            // <!-- DEFINE -->
            /** @type {V.Ref<Boolean>} */
            const isOpen = ref(props?.show ?? false);
            /** @type {V.Ref<Boolean>} */
            const hasTitle = ref(props?.title?.length > 0);
            /** @type {V.Ref<Boolean>} */
            const hasSubtitle = ref(props?.subtitle?.length > 0);
            /** @type {V.Ref<Boolean>} */
            const hasIcon = ref(props?.icon?.length > 0);
            /** @type {V.Ref<Array<V.WatchStopHandle>>} */
            const handlers = ref([]);

            // <!-- CONDITIONALS -->
            /** @type {V.ComputedRef<Boolean>} */
            const isHeaderVisible = computed(
                () =>
                    hasTitle.value === true ||
                    hasSubtitle.value === true ||
                    hasIcon.value === true
            );

            // <!-- WATCHER -->
            /** onMounted - register watchers. */
            onMounted(() => {
                // Register handlers.
                handlers.value = [
                    watchEffect(() => {
                        isOpen.value = props?.show === true;
                    }),
                    watchEffect(() => {
                        hasTitle.value =
                            props?.title?.length > 0 ||
                            props?.showCloseAction !== null;
                    }),
                    watchEffect(() => {
                        hasSubtitle.value = props?.subtitle?.length > 0;
                    }),
                    watchEffect(() => {
                        hasIcon.value = props?.icon?.length > 0;
                    }),
                ];
            });

            /** onUnmounted - clean up watchers. */
            onUnmounted(() => {
                // Unregister handlers.
                handlers.value.forEach((h) => {
                    if (!!h && typeof h === 'function') {
                        // Unregister the handlers.
                        h();
                    }
                });
            });

            // <!-- EXPOSE -->
            return { isOpen, isHeaderVisible };
        },
    });
</script>
