// <!-- MODELS -->
import { LocationList } from '@/models/locations';

// <!-- ROUTES -->
const ROUTES = {
    GET_LOCATION_LISTS: (account) => `accounts/${account}/location-lists`, // TODO: Implement route.
    CREATE_LOCATION_LIST: (account) => `accounts/${account}/location-lists`, // TODO: Implement route.
    GET_LOCATION_LIST: (account, list) =>
        `accounts/${account}/location-lists/${list}`, // TODO: Implement route.
    UPDATE_LOCATION_LIST: (account, list) =>
        `accounts/${account}/location-lists/${list}`, // TODO: Implement route.
    DELETE_LOCATION_LIST: (account, list) =>
        `accounts/${account}/location-lists/${list}`, // TODO: Implement route.
};

// <!-- REQUESTS -->

// <!-- EXPORTS -->
export default {
    LocationList,
    // fetchLocationLists,
    // fetchLocationListById,
    // createLocationList,
    // updateLocationListById,
    // deleteLocationListById,
};
