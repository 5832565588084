// <!-- API -->
import { DateRange } from '@/utils/filters';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { isValid } from 'date-fns';
import { DateTimeISO, DateTimeLocal } from '@/utils/datetime';

// <!-- TYPES -->
import AnalysisState from '@/store/types/analysis/state';
/** @typedef {import('@/utils/filters').IDate} IDate */
/** @typedef {import('@/utils/filters').IInterval} IInterval */
/** @typedef {import('@/utils/filters').IDateRangeModifierID} IDateRangeModifierID */
/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */

/**
 * @class
 * Getters for the analysis state.
 */
export class AnalysisStateGetters {
    /**
     * Access getters for the {@link IDateRangeFilter}.
     */
    static get dates() {
        return {
            /**
             * Get the date range from the store.
             * @param {AnalysisState} state
             * @param {{ StartDate: Date, EndDate: Date }} getters
             */
            DateRange: (state, getters) => {
                const start = getters['StartDate'];
                const end = getters['EndDate'];
                return {
                    /** @returns {[ start: Date, end: Date ]} */
                    asDates: () => [start, end],
                    /** @returns {[ start: String, end: String ]} */
                    asISOStrings: () => [
                        DateTimeISO.format(start),
                        DateTimeISO.format(end),
                    ],
                    /** @returns {[ start: String, end: String ]} */
                    asLocalStrings: () => [
                        DateTimeLocal.format(start),
                        DateTimeLocal.format(end),
                    ],
                };
            },
            /**
             * Get the start date from the store.
             * @param {AnalysisState} state
             * @returns {Date}
             */
            StartDate: (state) => {
                const target = state.filters.dates;
                const start = DateTimeISO.clone(target.start);
                return start;
            },
            /**
             * Get the end date from the store.
             * @param {AnalysisState} state
             * @returns {Date}
             */
            EndDate: (state) => {
                const target = state.filters.dates;
                const end = DateTimeISO.clone(target.end);
                return end;
            },
            /**
             * Determine if the modifier is checked.
             * @param {AnalysisState} state
             * @param {{ CheckedModifiers: Set<IDateRangeModifierID> }} getters
             */
            DateRangeModifier: (state, getters) => {
                return {
                    all: {
                        /**
                         * Determine if the date range modifier is enabled.
                         * @returns {Boolean}
                         */
                        isEnabled: () => {
                            const $checked = getters['CheckedModifiers'];
                            return $checked.has('all');
                        },
                        /**
                         * Determine if the date range modifier is disabled.
                         * @returns {Boolean}
                         */
                        isDisabled: () => {
                            const $checked = getters['CheckedModifiers'];
                            return !$checked.has('all');
                        },
                    },
                    overlap: {
                        /**
                         * Determine if the date range modifier is enabled.
                         * @returns {Boolean}
                         */
                        isEnabled: () => {
                            const $checked = getters['CheckedModifiers'];
                            return $checked.has('overlap');
                        },
                        /**
                         * Determine if the date range modifier is disabled.
                         * @returns {Boolean}
                         */
                        isDisabled: () => {
                            const $checked = getters['CheckedModifiers'];
                            return !$checked.has('overlap');
                        },
                    },
                };
            },
            /**
             * Get the set of checked modifiers.
             * @param {AnalysisState} state
             * @returns {Set<IDateRangeModifierID>}
             */
            CheckedModifiers: (state) => {
                const modifiers = new Set(state.filters.dates.checked);
                return modifiers;
            },
        };
    }
    /**
     * Access getters for the {@link ILimitFilterRecord}.
     */
    static get limits() {
        return {
            /**
             * Get the limits record properties.
             * @param {AnalysisState} state
             */
            Limits: (state) => {
                return {
                    /**
                     * Get the current limit filter record.
                     */
                    get record() {
                        return state.filters.limits;
                    },
                    /**
                     * Get the current temperature limit filter values.
                     */
                    get temp() {
                        return state.filters.limits.temp;
                    },
                    /**
                     * Get the current relative humidity limit filter values.
                     */
                    get rh() {
                        return state.filters.limits.rh;
                    },
                    /**
                     * Get the current dew point limit filter values.
                     */
                    get dp() {
                        return state.filters.limits.dp;
                    },
                };
            },
        };
    }
    /**
     * Access getters for the {@link IScaleFilterRecord}.
     */
    static get scales() {
        return {
            /**
             * Get the scales record properties.
             * @param {AnalysisState} state
             */
            Scales: (state) => {
                return {
                    /**
                     * Get the current scale filter record.
                     */
                    get record() {
                        return state.filters.scales;
                    },
                    /**
                     * Get the current temperature scale filter values.
                     */
                    get temp() {
                        return state.filters.scales.temp;
                    },
                    /**
                     * Get the current relative humidity scale filter values.
                     */
                    get rh() {
                        return state.filters.scales.rh;
                    },
                    /**
                     * Get the current dew point scale filter values.
                     */
                    get dp() {
                        return state.filters.scales.dp;
                    },
                };
            },
        };
    }
}

// <!-- DEFAULT -->
export default AnalysisStateGetters;
