// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

// HACK - FormData is not generically typed :'(
//        See: https://github.com/microsoft/TypeScript/issues/43797
/**
 * @typedef {FormData} CreateAccountBatchesRequest Request sent to the othe
 * - `datasets[${index}][file]: File`
 * - `datasets[${index}][location_id]: id`
 */
/**
 * @typedef {Object} CreateAccountBatchesResponse
 * @property {import('@/api').StatusResponse<{dataset: import('@/models/datasets/DatasetBatch').DatasetBatchPayload, preview?: ({ line: Number, data: String[] })[] }>[]} datasets Datasets passed back from the axios request.
 */

// <!-- MODELS -->
import { DatasetBatch } from '@/models/datasets';

// <!-- ROUTES -->
const ROUTES = {
    GET_ACCOUNT_BATCHES: (account) => `accounts/${account}/datasets`,
    GET_ACCOUNT_BATCH: (account, dataset) =>
        `accounts/${account}/datasets/${dataset}`,
    CREATE_ACCOUNT_BATCHES: (account) => `accounts/${account}/datasets`,
    UPDATE_ACCOUNT_BATCH: (account, dataset) =>
        `accounts/${account}/datasets/${dataset}`,
    DELETE_ACCOUNT_BATCH: (account, dataset) =>
        `accounts/${account}/datasets/${dataset}`,
    INGEST_ACCOUNT_BATCH: (account, dataset) =>
        `accounts/${account}/datasets/${dataset}/ingest`,
};

// <!-- REQUESTS -->

/**
 * Fetch all dataset batches associated with the specified account.
 * @param {Pick<AccountResource, 'id'>} account
 * @returns {Promise<import('@/models/datasets/DatasetBatch').DatasetBatchResource[]>}
 */
export const fetchAccountBatches = async (account = { id: 8 }) => {
    /** @type {import('axios').AxiosResponse<{ data: import('@/models/datasets/DatasetBatch').DatasetBatchPayload[] }>} */
    const response = await axios().get(ROUTES.GET_ACCOUNT_BATCHES(account.id));
    const collection = response.data.data;
    return collection.map((payload) =>
        new DatasetBatch({ payload }).toResource()
    );
};

/**
 * Fetch specified dataset associated with the specified account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} dataset
 * @returns {Promise<import('@/models/datasets/DatasetBatch').DatasetBatchResource>}
 */
export const fetchAccountBatchById = async (
    account = { id: 8 },
    dataset = { id: 1 }
) => {
    /** @type {import('axios').AxiosResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchPayload }>} */
    const response = await axios().get(
        ROUTES.GET_ACCOUNT_BATCH(account.id, dataset.id)
    );
    const payload = response.data.dataset;
    return new DatasetBatch({ payload }).toResource();
};

/**
 * @typedef {Object} ICreateAccountBatchResponse
 * @property {'created' | 'error'} status
 * @property {import('@/models/datasets/DatasetBatch').DatasetBatchPayload} [dataset]
 * @property {{ type?: 'PEM1' | 'PEM2' | 'CSV', data?: Array<{ line: Number, data: { date: String, temp: Number, rh: Number, dp: Number, pi: Number } }> }} [preview]
 */

/**
 * @typedef {Object} ICreateAccountBatchResult
 * @property {'created' | 'error'} status
 * @property {import('@/models/datasets/DatasetBatch').DatasetBatchResource} [dataset]
 * @property {{ type?: 'PEM1' | 'PEM2' | 'CSV', data?: Array<{ line: Number, data: { date: String, temp: Number, rh: Number, dp: Number, pi: Number } }> }} [preview]
 */

/**
 * Create multiple dataset batches under the specified account.
 *
 * @param {Pick<AccountResource, 'id'>} account
 * @param {{ location: Pick<LocationResource, 'id'>, file: File }[]} datasets
 * @returns {Promise<Array<ICreateAccountBatchResult>>}
 */
export const createAccountBatches = async (
    account = { id: 8 },
    datasets = []
) => {
    /**
     * Extend an existing {@link FormData} with an
     *
     * @param {CreateAccountBatchesRequest} accumulator Composed {@link FormData} aggregation target.
     * @param {{ location: Pick<LocationResource, 'id'>, file: File }} element Request composition element.
     * @param {Number} index Index of the element.
     */
    const composer = (accumulator, element, index) => {
        // Assume accumulator is non-null and supplied by the Array.reduce(composer, new FormData()) call.
        // If element is null, we simply skip appending anything.
        if (!!element) {
            // FormData entries make use of the nested array syntax
            // which is compatible with PHP.
            accumulator.append(`datasets[${index}][file]`, element.file);
            accumulator.append(
                `datasets[${index}][location_id]`,
                String(element.location.id)
            );
        }
        return accumulator;
    };

    // HACK - Please note that JSDocs do not allow us to forward
    //      typeparams to generic functions, such as the native
    //      Array.reduce(), that doesn't know how to respond to
    //      complex accumulator types that are not the same as
    //      the array element.
    //
    //      Typescript DOES allow us to do this with their
    //      fn<type>() syntax. Unfortunately, we're not currently
    //      using Typescript directly for this project.
    //
    //      This is under review: https://github.com/microsoft/TypeScript/issues/27387
    // @ts-ignore
    const request = datasets.reduce(composer, new FormData());

    /**
     * @type {import('axios').AxiosResponse<{ datasets: Array<ICreateAccountBatchResponse> }, CreateAccountBatchesRequest>}
     */
    const response = await axios().post(
        ROUTES.CREATE_ACCOUNT_BATCHES(account.id),
        /** @type {CreateAccountBatchesRequest} */ (request)
    );

    // TODO: Currently the entire request will FAIL if
    //       any one of the request elements are incorrect
    //       or fail Laravel-side validation.
    //
    //       Do we want to support partial transactions? For now, we do not.

    // Parse response collection to have resoures.
    const collection = response.data.datasets;
    const resources = collection.map((result) => ({
        status: result.status,
        dataset: new DatasetBatch({ payload: result.dataset }).toResource(),
        preview: result.preview,
    }));
    return resources;
};

/**
 * Update specified account dataset batch details.
 *
 * @param {Pick<AccountResource, 'id'>} account Account identifier.
 * @param {Partial<Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'|'profileId'>>} request Updated dataset batch entry to attempt to persist.
 * @returns {Promise<import('@/api').StatusResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchResource}>>}
 */
export const updateAccountBatchById = async (
    account = { id: 8 },
    request = { id: -1, profileId: -1 }
) => {
    /**
     * @type {import('axios').AxiosResponse<import('@/api').StatusResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchPayload }>, Pick<import('@/models/datasets/DatasetBatch').DatasetBatchPayload, 'mapping_profile_id'>>}
     */
    if (!request.profileId) {
        console.warn(
            '[Warning]: Using placeholder mapping profile id (1) as edit mapping profile functionality is not complete.'
        );
    }
    const response = await axios().patch(
        ROUTES.UPDATE_ACCOUNT_BATCH(account.id, request.id),
        {
            mapping_profile_id: request.profileId ?? 1,
        }
    );
    const { status, dataset } = response.data;
    return {
        status,
        dataset: new DatasetBatch({ payload: dataset }).toResource(),
    };
};

/**
 * Delete specified account dataset batch.
 *
 * @param {Pick<AccountResource, 'id'>} account Account to
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} request Dataset batch to delete.
 */
export const deleteAccountBatchById = async (
    account = { id: 8 },
    request = { id: -1 }
) => {
    /** @type {import('axios').AxiosResponse<{ status: "deleted", data: import('@/models/datasets/DatasetBatch').DatasetBatchPayload }>} */
    const response = await axios().delete(
        ROUTES.DELETE_ACCOUNT_BATCH(account.id, request.id)
    );
    return {
        status: response.data.status,
        dataset: response.data.data,
    };
};

/**
 * Ingest batch data for the specified dataset.
 *
 * @param {Pick<AccountResource, 'id'>} account Request parameter.
 * @param {Pick<import('@/models/datasets/DatasetBatch').DatasetBatchResource, 'id'>} request Request parameter.
 * @returns {Promise<import('@/api').StatusResponse<{ dataset: import('@/models/datasets/DatasetBatch').DatasetBatchResource }>>} Affected resource.
 */
export const ingestAccountBatch = async (
    account = { id: 8 },
    request = { id: -1 }
) => {
    const response = await axios().post(
        ROUTES.INGEST_ACCOUNT_BATCH(account.id, request.id)
    );
    const affected = await fetchAccountBatchById(account, request);
    // If no error, return true.
    return { status: response.status, dataset: affected };
};

// <!-- EXPORTS -->
export default {
    DatasetBatch,
    // <!-- ACCOUNT CRUD -->
    fetchAccountBatches,
    fetchAccountBatchById,
    createAccountBatches,
    updateAccountBatchById,
    deleteAccountBatchById,
    ingestAccountBatch,
};
