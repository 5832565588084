<template>
    <Teleport :to="to">
        <Comment v-if="!show">The modal will appear here when visible.</Comment>
        <!-- MODAL > CONTAINER -->
        <TransitionRoot
            as="template"
            v-bind="{
                // Controlled by props.
                show,
                appear,
                unmount,
                enter,
                enterFrom,
                enterTo,
                entered,
                leave,
                leaveFrom,
                leaveTo,
            }"
            @beforeEnter="$emit('beforeEnter')"
            @afterEnter="$emit('afterEnter')"
            @beforeLeave="$emit('beforeLeave')"
            @afterLeave="$emit('afterLeave')"
        >
            <div
                class="modal__container relative z-40"
                @keyup.escape="handleEscape"
            >
                <!-- MODAL > OVERLAY (BACKDROP) -->
                <TransitionChild as="template">
                    <div
                        class="modal__overlay modal__backdrop fixed inset-0 z-40 bg-gray-500 bg-opacity-65 hover:bg-opacity-50 transition-colors duration-150 ease-in-out"
                    />
                </TransitionChild>
                <!-- MODAL > OVERLAY (PANEL) -->
                <div
                    class="modal__overlay fixed inset-0 z-50 overflow-y-auto"
                    :class="{
                        'cursor-default': ignore,
                        'cursor-pointer': !ignore,
                    }"
                    @click.prevent="handleOverlay"
                >
                    <!-- MODAL > PANEL (WRAPPER) -->
                    <div
                        :class="[
                            'modal__panel-wrapper',
                            'flex items-center justify-center text-center sm:items-center', // fill backdrop for placement.
                            'min-h-full',
                            'rounded-lg overflow-y-auto',
                        ]"
                    >
                        <!-- MODAL > PANEL -->
                        <TransitionChild
                            as="template"
                            enter="duration-350 ease-out"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-5 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100"
                            leave="duration-200 ease-out"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-5 sm:scale-95"
                        >
                            <div
                                :class="[
                                    'modal_panel',
                                    'relative transform transition-all cursor-default',
                                    'h-full w-full text-left m-4',
                                    'focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-primary-500',
                                    'rounded-lg',
                                    {
                                        // Specify maximum widths based on selected size enum.
                                        'modal__xs max-w-xs': isTiny, // Mobile and Desktop sizes for xl modals.
                                        'modal__sm max-w-prose sm:max-w-screen-sm':
                                            isSmall, // Mobile and Desktop sizes for sm modals.
                                        'modal__md max-w-prose sm:max-w-screen-md':
                                            isMedium, // Mobile and Desktop sizes for md modals.
                                        'modal__lg max-w-prose sm:max-w-screen-lg':
                                            isLarge, // Mobile and Desktop sizes for lg modals.
                                        'modal__xl max-w-prose sm:max-w-screen-xl':
                                            isHuge, // Mobile and Desktop sizes for xl modals.
                                    },
                                    {
                                        'bg-gray-50 border-gray-50':
                                            !isBlack &&
                                            !isWhite &&
                                            !isPrimary &&
                                            !isSecondary &&
                                            !isInfo &&
                                            !isWarning &&
                                            !isSuccess &&
                                            !isFailure &&
                                            !isDanger &&
                                            !isError,
                                        'bg-black border-white': isBlack,
                                        'bg-white border-black': isWhite,
                                        'bg-white border-primary-700':
                                            isPrimary,
                                        'bg-white border-secondary-700':
                                            isSecondary,
                                        'bg-blue-50 border-info': isInfo,
                                        'bg-gray-800 border-warning': isWarning,
                                        'bg-green-50 border-success': isSuccess,
                                        'bg-red-50 border-error':
                                            isFailure || isDanger || isError,
                                    },
                                ]"
                                :tabindex="0"
                                ref="modal"
                                @click.stop="handlePanel"
                            >
                                <div
                                    :class="[
                                        'modal__panel-content',
                                        'h-full w-full text-left',
                                        'rounded-lg',
                                    ]"
                                >
                                    <!-- MODAL > CLOSE -->
                                    <ModalCloseButton
                                        v-if="!hideCloseButton"
                                        label="Close"
                                        :disabled="disabled"
                                        :busy="busy"
                                        @close="
                                            ({ id, event }) =>
                                                handleClose(event, id)
                                        "
                                    />
                                    <!-- MODAL > HEADER -->
                                    <slot
                                        v-if="$slots.header"
                                        name="header"
                                        :title="title"
                                        :subtitle="subtitle"
                                        :icon="icon"
                                        :disabled="disabled"
                                        :busy="busy"
                                    >
                                        <ModalHeader
                                            :title="title"
                                            :subtitle="subtitle"
                                            :border="
                                                border.all === true ||
                                                border.header === true
                                            "
                                            :icon="icon"
                                            :theme="theme"
                                            :disabled="disabled"
                                            :busy="busy"
                                            @close="
                                                handleClose($event, 'header')
                                            "
                                        >
                                            <template #default></template>
                                            <template
                                                v-if="!!showCloseAction"
                                                #actions
                                            ></template>
                                        </ModalHeader>
                                    </slot>

                                    <!-- MODAL CONTENT -->
                                    <slot
                                        v-if="!!$slots.default"
                                        :theme="theme"
                                        :disabled="disabled"
                                        :busy="busy"
                                    >
                                        <!-- DEFAULT CONTENT -->
                                        <ModalContent
                                            v-if="!!$slots.default"
                                            :border="
                                                border.all === true ||
                                                border.content === true
                                            "
                                            :theme="theme"
                                            :disabled="disabled"
                                            :busy="busy"
                                        >
                                            <!-- USE DEFAULT CONTENT -->
                                            <template #default></template>
                                        </ModalContent>
                                    </slot>

                                    <!-- MODAL FOOTER -->
                                    <slot
                                        v-if="$slots.footer"
                                        name="footer"
                                        :theme="theme"
                                        :disabled="disabled"
                                        :busy="busy"
                                    >
                                        <!-- DEFAULT FOOTER WITH CLOSE BUTTON -->
                                        <ModalFooter
                                            :border="
                                                border.all === true ||
                                                border.footer === true
                                            "
                                            :theme="theme"
                                            :disabled="disabled"
                                            :busy="busy"
                                            @close="
                                                handleClose($event, 'footer')
                                            "
                                        >
                                            <!-- USE DEFAULT FOOTER -->
                                            <template #default></template>
                                            <!-- USE FALLBACK ACTIONS -->
                                            <template
                                                v-if="!hideCancelAction"
                                                #actions
                                            ></template>
                                        </ModalFooter>
                                    </slot>
                                </div>
                            </div>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </TransitionRoot>
    </Teleport>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted, toRef, computed, ref } from 'vue';

    // <!-- UTILITIES -->
    import { isHTMLElement } from '@/utils/typeof';
    import { useEnum, Size, Theme, ModalIcon } from '@/utils/enums';
    import { useDebounceFn } from '@vueuse/core';

    // <!-- COMPONENTS -->
    // import ModalOverlay from '@/components/modals/ModalOverlay.vue';
    import ModalCloseButton from '@/components/modals/ModalCloseButton.vue';
    import ModalHeader from '@/components/modals/ModalHeader.vue';
    import ModalContent from '@/components/modals/ModalContent.vue';
    import ModalFooter from '@/components/modals/ModalFooter.vue';
    import { TransitionRoot, TransitionChild } from '@headlessui/vue';
    import { Comment } from '@/components/vnodes';
    import { promiseTimeout } from '@vueuse/shared';

    // <!-- CONSTANTS -->
    const DEFAULT_CLOSE_DELAY = 500;

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'TeleportModal',
        components: {
            TransitionRoot,
            TransitionChild,
            ModalCloseButton,
            ModalHeader,
            ModalContent,
            ModalFooter,
            Comment,
        },
        props: {
            /** Element selector specifying the destination of the {@link Teleport} component contents. */
            to: {
                /** @type {V.PropType<String>} */
                type: String,
                required: true,
            },
            /** Whether the modal should be shown or hidden. */
            show: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Whether the modal's close button should be shown or hidden. */
            hideCloseButton: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Whether the modal's default close action should be shown or hidden. Ignored when using slotted components. */
            showCloseAction: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Whether the modal's default cancel action should be shown or hidden. Ignored when using slotted components. */
            hideCancelAction: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Whether the root transition should run on initial mount. */
            appear: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Whether the element should be unmounted or hidden based on the show state. */
            unmount: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: true,
            },
            /** Classes to add to the transitioning element during the entire enter phase. */
            enter: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'transition-opacity duration-300 ease-out',
            },
            /** Classes to add to the transitioning element before the enter phase starts. */
            enterFrom: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'opacity-0',
            },
            /** Classes to add to the transitioning element immediately after the enter phase starts. */
            enterTo: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'opacity-100',
            },
            /** Classes to add to the transitioning element once the transition is done. These classes will persist after that, until it's time to leave. */
            entered: {
                /** @type {V.PropType<String>} */
                type: String,
                default: '',
            },
            /** Classes to add to the transitioning element during the entire leave phase. */
            leave: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'transition-opacity duration-200 ease-in',
            },
            /** Classes to add to the transitioning element before the leave phase starts. */
            leaveFrom: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'opacity-100',
            },
            /** Classes to add to the transitioning element immediately after the leave phase starts. */
            leaveTo: {
                /** @type {V.PropType<String>} */
                type: String,
                default: 'opacity-0',
            },
            /** If `true` pointer events on the modal overlay are ignored. No `close` event is fired when clicking the overlay. */
            ignore: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** Should the border for the specified content blocks be displayed? */
            border: {
                /** @type {V.PropType<Record<String, Boolean>>} */
                type: Object,
                default: () => ({
                    all: false,
                    header: false,
                    content: false,
                    footer: false,
                }),
            },
            /**
             * Determine modal icon to use, if a slot isn't available.
             *
             * @example
             * ```
             * <ModalHeader :icon="null"  /> // No icon (or fallback to slot#icon).
             * <ModalHeader icon="warning" /> // Warning icon.
             * ```
             * */
            icon: {
                /** @type {V.PropType<ModalIcon[keyof ModalIcon] | null>} */
                // @ts-ignore
                type: String,
                default: null,
                validator: (value) =>
                    value === null ||
                    Object.values(ModalIcon).includes(
                        /** @type {ModalIcon[keyof ModalIcon]} */ (value)
                    ),
            },
            /** Determine height and width variant using the {@link Size} enum. */
            size: {
                /** @type {V.PropType<Size[keyof Size]>} */
                // @ts-ignore
                type: String,
                default: Size.large,
                validator: (size) =>
                    Object.values(Size).includes(
                        /** @type {Size[keyof Size]} */ (size)
                    ),
            },
            /** Determine the inner component themes using the {@link Theme} enum. */
            theme: {
                /** @type {V.PropType<Theme[keyof Theme]>} */
                // @ts-ignore
                type: String,
                default: Theme.white,
                validator: (theme) =>
                    theme === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme]} */ (theme)
                    ),
            },
            /** Property used to specify the title text. Overriden when a slot is available. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Property used to specify the subtitle text. Overriden when a slot is available. */
            subtitle: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'close',
            'escape',
            'tab',
            'beforeEnter',
            'afterEnter',
            'beforeLeave',
            'afterLeave',
        ],
        setup(props, context) {
            // <!-- ELEMENTS -->
            /** @type {V.Ref<HTMLElement | null>} */
            const modal = ref(null); // Variable name must match the `ref="modal"` directive exactly.

            // <!-- DESTRUCTURE -->
            const ignore = toRef(props, 'ignore');
            const disabled = toRef(props, 'disabled');
            const busy = toRef(props, 'busy');
            const size = toRef(props, 'size');
            const theme = toRef(props, 'theme');
            const isSize = useEnum(Size, size);
            const isTheme = useEnum(Theme, theme);

            // <!-- CONDITIONALS -->
            /** @type {V.ComputedRef<Boolean>} */
            const isModalDisabled = computed(
                () => !!disabled.value || isModalBusy.value === true
            );
            /** @type {V.ComputedRef<Boolean>} */
            const isModalBusy = computed(() => !!busy.value);

            // <!-- ACTIONS -->
            /**
             * Trigger the modal close event. Event emission is debounced by ~150ms, to prevent click spam.
             * @type {(e: Pick<MouseEvent | KeyboardEvent, 'target'>) => any}
             */
            const close = useDebounceFn((e) => context.emit('close', e), 150);
            /**
             * Trigger the escape key event. Event emission is debounced by ~100ms, to prevent key press spam.
             * @type {(e: Pick<MouseEvent | KeyboardEvent, 'target'>) => any}
             */
            const escape = useDebounceFn((e) => context.emit('escape', e), 100);
            /**
             * Trigger the modal close event when the overlay is clicked.
             * @type {( id: String, e: Partial<Event> ) => any}
             */
            const log = (id, e) => console.log(id, e);
            /**
             * Stop event propagation if the overlay is ignored.
             * @type {(e: MouseEvent | KeyboardEvent) => any}
             */
            const discardIfOverlayIgnored = (e) => {
                const isOverlayIgnored = !!ignore.value;
                if (isOverlayIgnored) {
                    e.stopPropagation();
                    e.preventDefault();
                    throw new Error(
                        `Click event is prevented while the overlay is ignored.`
                    );
                }
            };
            /**
             * Stop event propagation, if the modal is busy.
             * @type {(e: MouseEvent | KeyboardEvent) => any}
             */
            const discardIfBusy = (e) => {
                const isModalBusy = !!busy.value;
                if (isModalBusy) {
                    e.stopPropagation();
                    e.preventDefault();
                    throw new Error(
                        `Click event is prevented while the modal is busy.`
                    );
                }
            };
            /**
             * Stop event propagation, if the modal is disabled.
             * @type {(e: MouseEvent | KeyboardEvent) => any}
             */
            const discardIfDisabled = (e) => {
                const isModalDisabled = !!disabled.value;
                if (isModalDisabled) {
                    e.stopPropagation();
                    e.preventDefault();
                    throw new Error(
                        `Click event is prevented while the modal is disabled.`
                    );
                }
            };
            /**
             * Trigger the modal close event when the overlay is clicked.
             * @type {(e: MouseEvent | KeyboardEvent) => Promise<any>}
             */
            const handleOverlay = async (e) => {
                try {
                    discardIfOverlayIgnored(e);
                    discardIfBusy(e);
                    // HACK: Give other events a chance to resolve before closing.
                    await promiseTimeout(DEFAULT_CLOSE_DELAY, false);
                    log('overlay', e);
                    close(e); // invoke the debounced close event.
                } catch (e) {
                    // no-op
                    console.warn(e);
                }
            };
            /**
             * No-op consuming handler.
             * @type {(e: MouseEvent) => any}
             */
            const handlePanel = (e) => {
                log('panel', e);
                return true;
            };
            /**
             * Handle the focusout event. Restricts the focus to items within the modal.
             * @type {(e: FocusEvent) => any}
             */
            const handleFocusOut = (e) => {
                const isModalOpen = !!props.show;
                const isModalMounted = modal.value !== null;
                const isModalFocusable = isModalOpen && isModalMounted;
                if (isModalFocusable) {
                    log('focusout', e);
                    e.stopPropagation();
                    if (isHTMLElement(e.relatedTarget)) {
                        if (modal.value?.contains(e.relatedTarget)) {
                            // If the relatedTarget (target receiving focus) is a DOM target
                            // and the relatedTarget is contained by the modal,
                            // allow the focus operation to continue without issue.
                            return false;
                        } else {
                            // Stop the default action?
                            e.preventDefault();
                            // Blur the target.
                            e.relatedTarget.blur();
                            // If invalid or outside of the modal, focus the modal.
                            modal.value?.focus();
                        }
                    }
                }
            };
            /**
             * Handle the escape keydown. Emits a `escape` event if not busy. Allows closing when disabled.
             * @type {(e: KeyboardEvent) => Promise<any>}
             */
            const handleEscape = async (e) => {
                try {
                    discardIfBusy(e);
                    // HACK: Give other events a chance to resolve before closing.
                    await promiseTimeout(DEFAULT_CLOSE_DELAY, false);
                    log('escape', e);
                    escape(e); // invoke the debounced escape event.
                } catch (e) {
                    // no-op
                    console.warn(e);
                }
            };
            /**
             * Handle the close event from a child component. Emits a `close` event if not disabled. Allows closing even if busy.
             * @param {MouseEvent | KeyboardEvent} e
             * @param {String} [id] Optional id used for debug logging.
             */
            const handleClose = async (e, id = 'close') => {
                try {
                    discardIfDisabled(e);
                    discardIfBusy(e);
                    // HACK: Give other events a chance to resolve before closing.
                    await promiseTimeout(DEFAULT_CLOSE_DELAY, false);
                    log(id, e);
                    close(e); // invoke the debounced close event.
                } catch (e) {
                    // no-op
                    console.warn(e);
                }
            };

            // <!-- LIFECYCLE -->
            // onMounted - Event invoked once the component is mounted to the DOM.
            onMounted(() => {
                // Call focus on the modal.
                modal.value?.focus();
            });

            // <!-- EXPOSE -->
            return {
                // TEMPLATE REFS
                modal,
                // CONDITIONALS
                isModalDisabled,
                isModalBusy,
                // SIZE
                Size,
                isTiny: isSize.tiny,
                isSmall: isSize.small,
                isMedium: isSize.medium,
                isLarge: isSize.large,
                isHuge: isSize.huge,
                // THEME
                Theme,
                isBlack: isTheme.black,
                isWhite: isTheme.white,
                isPrimary: isTheme.primary,
                isSecondary: isTheme.secondary,
                isInfo: isTheme.info,
                isWarning: isTheme.warning,
                isSuccess: isTheme.success,
                isFailure: isTheme.failure,
                isDanger: isTheme.danger,
                isError: isTheme.error,
                // ICON
                ModalIcon,
                // ACTIONS
                close,
                handleClose,
                handleOverlay,
                handlePanel,
                handleFocusOut,
                handleEscape,
            };
        },
    });
</script>
