// <!-- TYPES -->
import { Model, FORMAT } from '../Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<SearchTerm['toPayload']>} SearchTermPayload */
/** @typedef {ReturnType<SearchTerm['toResource']>} SearchTermResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'name',
    'description',
    'locationsCount',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.locationsCount, 'locations_count'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class SearchTerm extends Model {
    /**
     * Get the initial state. Nullable keys should be included.
     * @returns {[String, import('@/models/Model').AttributeType][]}
     * @protected
     */
    _initialState() {
        return Object.keys(FIELDS).map((key) => [key, null]);
    }

    /**
     * Get the initialized aliases.
     */
    _initializeAliases() {
        return new Map(ALIASES.map(([key, alias]) => [alias, key]));
    }

    toPayload() {
        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {String} */
            [FIELDS.description]: this.get(FIELDS.description),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].locations_count]: this.get(
                FIELDS.locationsCount
            ),
        };
    }

    toResource() {
        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {String} */
            [FIELDS.description]: this.get(FIELDS.description),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].locationsCount]: this.get(
                FIELDS.locationsCount
            ),
        };
    }
}
