/**
 * Report type.
 */
export const ReportType = Object.freeze({
    overview: 'overview',
    performance: 'performance',
    nara: 'nara',
    compare: 'compare',
    custom: 'custom',
});

/**
 * Type guard for enum key or value.
 * @template {keyof typeof ReportType} [K=keyof typeof ReportType]
 * @param {K} target
 * @param {keyof typeof ReportType} keyOrValue
 * @returns {key is K}
 */
export const isReportType = (target, keyOrValue) =>
    target !== null && target !== undefined && target === keyOrValue;

// <!-- DEFAULT -->
export default ReportType;
