// <!-- TYPES -->
import { Model, FORMAT } from '../Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
import { Account } from '@/models/accounts';
/** @typedef {ReturnType<CurrentUserProfile['toPayload']>} CurrentUserProfilePayload */
/** @typedef {ReturnType<CurrentUserProfile['toResource']>} CurrentUserProfileResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'username',
    'firstname',
    'lastname',
    'role',
    'email',
    'accounts',
    'dateCreated',
    'dateUpdated',
]);

/** Resource <--> Payload aliases. */
export const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.username, 'user_name'],
    [FIELDS.firstname, 'first_name'],
    [FIELDS.lastname, 'last_name'],
    [FIELDS.role, 'role'],
    [FIELDS.email, 'email'],
    [FIELDS.accounts, 'accounts'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.dateUpdated, 'updated_at'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * Represents the current logged in user.
 * @class
 */
export class CurrentUserProfile extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * @param {CurrentUserProfilePayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {(payload: CurrentUserProfilePayload) => [string, import('@/models/Model').AttributeType][]} */
        const transformPayload = (payload) => {
            const { accounts, ...attributes } = payload;
            const entity = {
                ...attributes,
                accounts: accounts.map((account) =>
                    new Account().parsePayload(account)
                ),
            };
            return Object.entries(entity);
        };
        super.parsePayload(payload, transformPayload);
        return this;
    }

    /**
     * @param {import('@api/profile').CurrentUserProfileResource} resource
     * @returns {this}
     */
    parseResource(resource) {
        /** @type {(resource: CurrentUserProfileResource) => [string, import('@/models/Model').AttributeType][]} */
        const transformResource = (resource) => {
            const { accounts, ...attributes } = resource;
            const entity = {
                ...attributes,
                accounts: accounts.map((account) =>
                    new Account().parseResource(account)
                ),
            };
            return Object.entries(entity);
        };
        super.parseResource(resource, transformResource);
        return this;
    }

    toPayload() {
        /** @type {Account[]} */
        const accounts = this.get(FIELDS.accounts);

        return {
            /** @type {Number} */
            [FIELDS.id]: this.id,
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].user_name]: this.get(FIELDS.username),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].first_name]: this.get(FIELDS.firstname),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].last_name]: this.get(FIELDS.lastname),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].role]: this.get(FIELDS.role),
            /** @type {String} */
            [FIELDS.email]: this.get(FIELDS.email),
            /** @type {import('../accounts/Account').AccountPayload[]} */
            [FIELDS.accounts]: accounts.map((account) => account.toPayload()),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].created_at]: new Date(
                this.get(FIELDS.dateCreated)
            ).toISOString(),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].updated_at]: new Date(
                this.get(FIELDS.dateCreated)
            ).toISOString(),
        };
    }

    toResource() {
        /** @type {Account[]} Get accounts before mapping. */
        const accounts = this.get(FIELDS.accounts);

        return {
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].id]: this.id,
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].username]: this.get(FIELDS.username),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].firstname]: this.get(FIELDS.firstname),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].lastname]: this.get(FIELDS.lastname),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].role]: this.get(FIELDS.role),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].email]: this.get(FIELDS.email),
            /** @type {import('../accounts/Account').AccountResource[]} */
            [KEYS[FORMAT.RESOURCE].accounts]: accounts.map((account) =>
                account.toResource()
            ),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateCreated]: new Date(
                this.get(FIELDS.dateCreated)
            ).toISOString(),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateUpdated]: new Date(
                this.get(FIELDS.dateUpdated)
            ).toISOString(),
        };
    }
}
