// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const Temperature = createEnum({
    'Fahrenheit (°F)': 'F',
    'Celcius (°C)': 'C',
});

/**
 * Type guard for enum key.
 * @template {keyof Temperature} [K=keyof Temperature]
 * @param {K} target
 * @param {keyof Temperature} key
 * @returns {key is K}
 */
export const isTemperatureKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {Temperature[keyof Temperature]} [V=Temperature[keyof Temperature]]
 * @param {V} target
 * @param {Temperature[keyof Temperature]} value
 * @returns {value is V}
 */
export const isTemperature = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default Temperature;
