// <!-- API -->
import {
    DateRange,
    LimitFilterRecord,
    ScaleFilterRecord,
    LocationFilter,
    WeatherStationFilter,
} from '@/utils/filters';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import compare from 'just-compare';
import { toDate } from 'date-fns';
import { Tree, NodeRecord } from '@/utils/tree';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import AnalysisState from '@/store/types/analysis/state';
import { DateTimeISO } from '@/utils/datetime';
/** @template [S=any] @template [R=any] @typedef {import('vuex').ActionContext<S,R>} ActionContext<S,R> */
/** @typedef {import('@/utils/date').Day} Day The day of the week type alias (0 | 1 | 2 | 3 | 4 | 5 | 6), from Sunday to Saturday. */
/** @typedef {import('@/utils/date').IDate} IDate A native {@link Date} instance or JavaScript {@link ITimestamp}. */
/** @typedef {import('@/utils/date').IInterval} IInterval An object that combines two dates to represent the time interval. */
/** @typedef {import('@/utils/date').IDuration} IDuration Duration object. All values are initialized to `0` by default. */
/** @typedef {import('@/utils/date').ITimestamp} ITimestamp Instant in datetime specifing time (in milliseconds) since the JavaScript epoch. */
/** @typedef {import('@/utils/date').IUnixTimestamp} IUnixTimestamp Instant in datetime specifing time (in seconds) since the Unix epoch. */
/** @typedef {import('@/utils/filters').IDateRangeModifierID} IDateRangeModifierID */
/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter */
/** @typedef {import('@/utils/filters').IDateRangeModifier} IDateRangeModifier */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */

/**
 * @class
 * Actions for the analysis state.
 */
export class AnalysisStateActions {
    /**
     * Access actions for the {@link IDateRangeFilter}.
     */
    static get dates() {
        return {
            /**
             * Update one or both date range fields.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Partial<{ start: IDate, end: IDate }>>} payload Action payload.
             * @returns {Promise<IInterval>}
             */
            assignDateRange: async ({ state, commit }, payload) => {
                // <!-- DESTRUCTURE -->
                const { start = undefined, end = undefined } = payload;

                // <!-- CONDITIONALS -->
                const useCurrentDateStart = start === undefined;
                const useCurrentDateEnd = end === undefined;

                // <!-- DEFINE -->
                const interval = {
                    start,
                    end,
                };

                // <!-- VALIDATE -->
                if (useCurrentDateStart) {
                    // If payload has undefined start date argument, use existing start date.
                    interval.start = toDate(state.filters.dates.start);
                }
                if (useCurrentDateEnd) {
                    // If payload has undefined end date argument, use existing end date.
                    interval.end = toDate(state.filters.dates.end);
                }

                // <!-- COMMIT -->
                commit('setDateRange', interval);

                // Return updated range.
                return DateRange.clone(state.filters.dates);
            },
            /**
             * Update start date.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<IDate>} payload Action payload.
             * @returns {Promise<IDate>}
             */
            assignStartDate: async ({ dispatch }, payload) => {
                /** @type {IInterval} */
                const updated = await dispatch(`assignDateRange`, {
                    start: payload,
                });
                return updated.start;
            },
            /**
             * Update end date.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<IDate>} payload Action payload.
             * @returns {Promise<IDate>}
             */
            assignEndDate: async ({ dispatch }, payload) => {
                /** @type {IInterval} */
                const updated = await dispatch(`assignDateRange`, {
                    end: payload,
                });
                return updated.end;
            },
            /**
             * Update date range modifiers.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Iterable<{ key: IDateRangeModifierID, value: Boolean }>>} payload Action payload.
             * @returns {Promise<IDateRangeModifier>}
             */
            assignDateRangeModifiers: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    commit('setDateRangeModifiers', payload);
                }
                // Return updated range.
                return { checked: [...state.filters.dates.checked] };
            },
            /**
             * Update a date range modifier.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<IDateRangeModifierID>} payload Action payload.
             * @returns {Promise<IDateRangeModifier>}
             */
            enableDateRangeModifier: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    const $request = { key: payload, value: true };
                    commit('setDateRangeModifier', $request);
                }
                // Return updated range.
                return { checked: [...state.filters.dates.checked] };
            },
            /**
             * Update a date range modifier.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<IDateRangeModifierID>} payload Action payload.
             * @returns {Promise<IDateRangeModifier>}
             */
            disableDateRangeModifier: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    const $request = { key: payload, value: false };
                    commit('setDateRangeModifier', $request);
                }
                // Return updated range.
                return { checked: [...state.filters.dates.checked] };
            },
        };
    }
    /**
     * Access actions for the {@link IScaleFilterRecord}.
     */
    static get limits() {
        return {
            /**
             * Update the limit record.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Partial<ILimitFilterRecord>>} payload Action payload.
             * @returns {Promise<ILimitFilterRecord>}
             */
            assignLimitRecord: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    commit('patchLimits', payload);
                }
                // Return potentially updated limits.
                return LimitFilterRecord.clone(state.filters.limits);
            },
        };
    }
    /**
     * Access actions for the {@link IScaleFilterRecord}.
     */
    static get scales() {
        return {
            /**
             * Update scale record.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Partial<IScaleFilterRecord>>} payload Action payload.
             * @returns {Promise<IScaleFilterRecord>}
             */
            assignScaleRecord: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    commit('patchScales', payload);
                }
                // Return potentially updated scales.
                return ScaleFilterRecord.clone(state.filters.scales);
            },
        };
    }
    /**
     * Access actions for the {@link ILocationFilter}.
     */
    static get locations() {
        return {
            /**
             * Notify location tree of the specified payload.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {any} payload Action payload.
             * @returns {Promise<any>}
             */
            notifyLocationTree: async ({ state }, payload) => {
                if (!isNil(payload)) {
                    console.log(`[notify::locations]`, payload);
                }
                // Do nothing.
            },
            /**
             * Update location filter.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.TreeProps>} payload Action payload.
             * @returns {Promise<ILocationFilter>}
             */
            assignLocationTree: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    commit('patchLocationTree', payload);
                }
                // Return potentially updated tree.
                return LocationFilter.clone(state.filters.locations);
            },
            /**
             * Update location filter's checked locations. Removes unchecked locations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<ILocationFilter>}
             */
            assignSelectedLocations: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.locations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.locations.tree, {
                        nodes: update,
                    });
                    commit('setLocationTree', tree);
                }
                // Return potentially updated tree.
                return LocationFilter.clone(state.filters.locations);
            },
            /**
             * Update location filter's opened locations. Removes closed locations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<ILocationFilter>}
             */
            assignOpenedLocations: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.locations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.locations.tree, {
                        nodes: update,
                    });
                    commit('setLocationTree', tree);
                }
                // Return potentially updated tree.
                return LocationFilter.clone(state.filters.locations);
            },
        };
    }
    /**
     * Access actions for the {@link IWeatherStationFilter}.
     */
    static get stations() {
        return {
            /**
             * Update weather station filter.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.TreeProps>} payload Action payload.
             * @returns {Promise<IWeatherStationFilter>}
             */
            assignWeatherStationTree: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    commit('patchWeatherStationTree', payload);
                }
                // Return potentially updated tree.
                return WeatherStationFilter.clone(state.filters.stations);
            },
            /**
             * Update weather station filter's checked stations. Removes unchecked stations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<IWeatherStationFilter>}
             */
            assignSelectedWeatherStations: async (
                { state, commit },
                payload
            ) => {
                if (!isNil(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.stations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.stations.tree, {
                        nodes: update,
                    });
                    commit('setWeatherStationTree', tree);
                }
                // Return potentially updated tree.
                return WeatherStationFilter.clone(state.filters.stations);
            },
            /**
             * Update weather station filter's opened stations. Removes closed stations as well.
             * @param {import('vuex').ActionContext<AnalysisState, ECNBState>} context Action context.
             * @param {Readonly<Treeview.Node[]>} payload
             * @returns {Promise<IWeatherStationFilter>}
             */
            assignOpenedWeatherStations: async ({ state, commit }, payload) => {
                if (!isNil(payload)) {
                    const record = NodeRecord.fromCollection(payload);
                    const update = NodeRecord.override(
                        state.filters.stations.tree.nodes,
                        record
                    );
                    const tree = Tree.override(state.filters.stations.tree, {
                        nodes: update,
                    });
                    commit('setWeatherStationTree', tree);
                }
                // Return potentially updated tree.
                return WeatherStationFilter.clone(state.filters.stations);
            },
        };
    }
}

// <!-- DEFAULT -->
export default AnalysisStateActions;
