// <!-- API -->
import { useECNBStore } from './useECNBStore';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBStore, ECNBState } from '@/store/types/ECNBStore';
import { NoteResource } from '@/models/notes/Note';
import { LocationHierarchyResource } from '@/models/locations/LocationHierarchy';
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/**
 * Get the computed property, getters, and actions.
 * @param {Store<ECNBState>} [store] Vuex store reference.
 */
export const useECNBCache = (store = null) => {
    // Wrap the provided store or create a new one.
    const $ = useECNBStore(store);
    const cache = new ECNBCache($.api.store);
    return cache;
};

/**
 * @class
 * ECNBStore wrapper.
 */
export class ECNBCache extends ECNBStore {
    /**
     * Create specialized {@link ECNBStore} wrapper.
     * @param {Store} store Vuex store reference.
     */
    constructor(store) {
        super(store);
        /**
         * Get current cached values without fetching.
         */

        this.accounts = this.api.create.computed.cache.accounts;
        /**
         * Get current cached values without fetching.
         */
        this.standards = this.api.create.computed.cache.standards;
        /**
         * Get current cached values without fetching.
         */
        this.locations = this.api.create.computed.cache.locations;
        /**
         * Get current cached values without fetching.
         */
        this.stations = this.api.create.computed.cache.stations;
        /**
         * Get current cached values without fetching.
         */
        this.profiles = this.api.create.computed.cache.profiles;
        /**
         * Get current cached values without fetching.
         */
        this.notes = this.api.create.computed.cache.notes;
        /**
         * Get current cached values without fetching.
         */
        this.hierarchies = this.api.create.computed.cache.hierarchies;
        /**
         * Get current cached values without fetching.
         */
        this.users = this.api.create.computed.cache.users;
    }

    /** Access to the fetch dispatchers. */
    get fetch() {
        const $ = this;
        const { dispatch } = $.api;
        return {
            get accounts() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/accounts')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get standards() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/standards')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get locations() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/locations')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get stations() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/stations')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get notes() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/notes')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get hierarchies() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/hierarchies')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get profiles() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/profiles')
                        .withPayload(payload)
                        .using(dispatch);
            },
            get users() {
                /**
                 * @param {{ ignoreCache: Boolean }} payload
                 */
                return (payload) =>
                    ECNBStore.resolve
                        .action('fetchIndex')
                        .asNamespaced('cache/users')
                        .withPayload(payload)
                        .using(dispatch);
            },

            get location() {
                /**
                 * @param {Number} id
                 * @param {LocationResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<LocationResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/locations')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get station() {
                /**
                 * @param {String} id
                 * @param {import('@/models/weather/WeatherStation').WeatherStationResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<import('@/models/weather/WeatherStation').WeatherStationResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/stations')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get note() {
                /**
                 * @param {Number} id
                 * @param {NoteResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<NoteResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/notes')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get hierarchy() {
                /**
                 * @param {Number} id
                 * @param {LocationHierarchyResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<LocationHierarchyResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/hierarchies')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get profile() {
                /**
                 * @param {Number} id
                 * @param {import('@/models/mappings/MappingProfile').MappingProfileResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<import('@/models/mappings/MappingProfile').MappingProfileResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/profiles')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get user() {
                /**
                 * @param {Number} id
                 * @param {import('@/api/accounts').UserResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<import('@/api/accounts').UserResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/users')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get account() {
                /**
                 * @param {Number} id
                 * @param {AccountResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<AccountResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/accounts')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
            get standard() {
                /**
                 * @param {Number} id
                 * @param {import('@/api/standards').NARAStandardResource} [defaultValue]
                 * @param {Boolean} [forceReload]
                 * @returns {Promise<import('@/api/standards').NARAStandardResource>}
                 */
                return (id, defaultValue, forceReload = true) =>
                    ECNBStore.resolve
                        .action('fetchResource')
                        .asNamespaced('cache/standards')
                        .withPayload({
                            id,
                            defaultValue,
                            ignoreCache: forceReload,
                        })
                        .using(dispatch);
            },
        };
    }
}

// DEFAULT
export default useECNBCache;
