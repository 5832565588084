// <!-- UTILITIES -->
import pick from 'just-pick';

// <!-- TYPES -->
// ==== GRAPH FILTERS ====
/** @typedef {import('@/utils/filters').IDateRangeModifier} IDateRangeModifier Record containing the checked modifier state for a limit or scale. */

/**
 * @class
 * Record containing the range modifier.
 * @implements {IDateRangeModifier}
 */
export class DateRangeModifier {
    /**
     * Create an axis range.
     * @param {Readonly<Partial<IDateRangeModifier>>} [props]
     * @returns {IDateRangeModifier}
     */
    static create = (props = {}) => new DateRangeModifier(props);

    /**
     * Clone an existing axis range.
     * @param {Readonly<IDateRangeModifier>} [source]
     * @returns {IDateRangeModifier}
     */
    static clone = (source = new DateRangeModifier()) =>
        new DateRangeModifier(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<IDateRangeModifier>} [target]
     * @returns {IDateRangeModifier}
     */
    static data = (target) => pick(target, 'checked');

    /**
     * Create an axis range.
     * @param {Readonly<Partial<IDateRangeModifier>>} [props]
     */
    constructor(props) {
        // Get the default values (when a property is `undefined`).
        const { checked = [] } = props ?? {};
        /** @type {IDateRangeModifier['checked']} Modifier enabled state. */
        this.checked = checked;
    }
}

// <!-- DEFAULT -->
export default DateRangeModifier;
