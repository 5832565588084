<template>
    <div
        v-if="isFooterVisible"
        :class="[
            'modal__panel-footer',
            'border-solid border-transparent',
            'rounded-b-lg',
            {
                'border-t-2': !!border,
                'bg-gray-50 border-gray-50':
                    !isBlack &&
                    !isWhite &&
                    !isPrimary &&
                    !isSecondary &&
                    !isInfo &&
                    !isWarning &&
                    !isSuccess &&
                    !isFailure &&
                    !isDanger &&
                    !isError,
                'bg-black text-white border-white': isBlack,
                'bg-white text-black border-black': isWhite,
                'bg-white text-primary-700 border-primary-700': isPrimary,
                'bg-white text-secondary-700 border-secondary-700': isSecondary,
                'bg-blue-50 text-info border-info': isInfo,
                'bg-gray-800 text-warning border-warning': isWarning,
                'bg-green-50 text-success border-success': isSuccess,
                'bg-red-50 text-error border-error':
                    isFailure || isDanger || isError,
            },
        ]"
    >
        <div
            class="rounded-b-lg flex flex-col sm:flex-row sm:flex-wrap sm:justify-start sm:items-start"
        >
            <!-- FOOTER > CONTENT -->
            <div
                v-if="isContentVisible"
                class="footer__content max-w-prose p-4"
            >
                <slot></slot>
            </div>
            <!-- FOOTER > ACTIONS -->
            <ModalActions
                v-if="isActionsVisible"
                class="footer__actions p-4"
            >
                <template #default>
                    <slot
                        name="actions"
                        :disabled="disabled"
                        :busy="busy"
                    >
                        <ModalButton
                            title="Close"
                            :theme="Theme.info"
                            :disabled="disabled"
                            :busy="busy"
                            @click.stop="$emit('close', $event)"
                        >
                            Close
                        </ModalButton>
                    </slot>
                </template>
            </ModalActions>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef, ref, computed, onBeforeUpdate } from 'vue';

    // <!-- UTILITIES -->
    import { useEnum, Theme } from '@/utils/enums';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import ModalActions from '@/components/modals/ModalActions.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalFooter',
        components: {
            ModalButton,
            ModalActions,
        },
        props: {
            /**
             * Determine background color and text color using the {@link Theme} enum.
             * Use `null` to supply your own overrides in the `class` attribute.
             *
             * @example
             * ```
             * <ModalFooter :theme="null" class="bg-pink-400" /> // Override with custom theme.
             * <ModalFooter theme="success" /> // Success theme.
             * ```
             * */
            theme: {
                /** @type {V.PropType<Theme[keyof Theme] | null>} */
                // @ts-ignore
                type: String,
                default: Theme.white,
                validator: (value) =>
                    value === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme]} */ (value)
                    ),
            },
            /** Is the border visible? */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props, context) {
            // <!-- DESTRUCTURE -->
            const theme = toRef(props, 'theme');

            // <!-- DEFINE -->
            /** Create conditionals via the `useEnum` callback. */
            const isTheme = useEnum(Theme, theme);
            /** @type {V.Ref<Boolean>} */
            const showContent = ref(false);
            /** @type {V.Ref<Boolean>} */
            const showActions = ref(false);

            // <!-- CONDITIONALS -->
            /** @type {V.ComputedRef<Boolean>} */
            const isContentVisible = computed(() => showContent.value === true);
            /** @type {V.ComputedRef<Boolean>} */
            const isActionsVisible = computed(() => showActions.value === true);
            /** @type {V.ComputedRef<Boolean>} */
            const isFooterVisible = computed(
                () =>
                    isContentVisible.value === true ||
                    isActionsVisible.value === true
            );

            // <!-- METHODS -->
            /**
             * Update visibility variables based on the `slots` passed into this component.
             * - Note: `attrs` and `slots` are stateful objects that are always updated when the component itself is updated.
             * - This means you should avoid destructuring them and always reference properties as `attrs.x` or `slots.x`.
             * - Also note that, unlike `props`, the properties of `attrs` and `slots` are **not** reactive.
             * - If you intend to apply side effects based on changes to `attrs` or `slots`, you should do so inside an onBeforeUpdate lifecycle hook.
             */
            const updateVisibility = () => {
                // isFooterVisible is a computed property updated after the following change:
                showContent.value = !!context?.slots?.default;
                showActions.value = !!context?.slots?.actions;
            };

            /**
             * Invoke the passed hook once the component's considered 'created'. Has a default function if no hook is passed.
             * @param {Function} hook Callback.
             */
            const onCreated = (hook) => {
                if (!!hook && typeof hook === 'function') {
                    return hook.call(this);
                }
                // Default onCreated functionality.
                console.log('[created]', this);
            };

            // <!-- LIFECYCLE -->

            // onBeforeUpdate: Registers a hook to be called right before the component is about to update its DOM tree due to a reactive state change.
            onBeforeUpdate(() => {
                // Update the slot visibility.
                updateVisibility();
            });

            // onCreated: These are the loose functions called immediately, at the end of the setup function.
            onCreated(() => {
                // Update the slot visibility.
                updateVisibility();
            });

            // <!-- EXPOSE -->
            return {
                // CONDITIONALS
                isContentVisible,
                isActionsVisible,
                isFooterVisible,
                // THEME
                Theme,
                isBlack: isTheme.black,
                isWhite: isTheme.white,
                isPrimary: isTheme.primary,
                isSecondary: isTheme.secondary,
                isInfo: isTheme.info,
                isWarning: isTheme.warning,
                isSuccess: isTheme.success,
                isFailure: isTheme.failure,
                isDanger: isTheme.danger,
                isError: isTheme.error,
            };
        },
    });
</script>
