// <!-- API -->
import { computed } from 'vue';

// <!-- TYPES -->

/** @typedef {import('@/utils/enums').EnumKey} EnumKey */
/** @typedef {import('@/utils/enums').EnumValue} EnumValue */
/** @template {EnumKey} [K=EnumKey] @template {EnumValue} [V=EnumValue] @typedef {import('@/utils/enums').IEnumRecord<K,V>} IEnumRecord */
/** @template {EnumKey} [K=EnumKey] @template {EnumValue} [V=EnumValue] @typedef {import('@/utils/enums').IEnum<K,V>} IEnum */

/**
 * Type guard for enum value.
 * @template {EnumKey} [K=EnumKey]
 * @template {EnumValue} [V=EnumValue]
 * @template {IEnum<K,V>} [E=IEnum<K,V>]
 * @param {keyof E} target
 * @param {any} value
 * @returns {value is E[keyof E]}
 */
const isEnumValue = (target, value) =>
    target !== undefined && target !== null && target === value;

/**
 * Create computed properties based on the provided enum.
 * @template {EnumKey} [K=EnumKey]
 * @template {EnumValue} [V=EnumValue]
 * @template {IEnum<K,V>} [E=IEnum<K,V>]
 * @param {E} Enum Create a series of computed properties based on the passed enum.
 * @param {V.Ref<IEnum[keyof IEnum<K,V>]>} reference Reactive variable that holds an enum value.
 * @returns {Record<keyof E, V.ComputedRef<Boolean>>}
 */
export const useEnum = (Enum, reference) => {
    const source = /** @type {E} */ (Enum);
    const record = /** @type {Record<keyof E, V.ComputedRef<Boolean>>} */ ({});
    const keys = Object.keys(source);
    for (const key of keys) {
        const target = source[key];
        const fn = computed(() => isEnumValue(target, reference.value));
        record[key] = fn;
    }
    return record;
};
