// <!-- API -->
import { Assertion } from '@/store/types/ECNBAssertions';

// <!-- TYPES -->
import { UploadRecord } from '@/store/types/uploader/state';
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * Asynchronous assertions that will test predicates
 * and fail when appropriate, throwing errors to interrupt actions.
 */
export class UploadRecordActions {
    /**
     * Upload record assertions.
     * @param {UploadRecord} context
     */
    static assert(context) {
        return {
            /**
             * Assert record has been marked for removal.
             */
            isMarkedForRemoval: async () => {
                await Assertion.expect(context.isMarkedForRemoval).isTruthy();
            },
            /**
             * Assert record has not been marked for removal.
             */
            isNotMarkedForRemoval: async () => {
                if (!!context) {
                    await Assertion.expect(
                        context.isMarkedForRemoval
                    ).isFalsy();
                }
            },
            /**
             * Assert record has been ingested.
             */
            isUploaded: async () => {
                await Assertion.expect(
                    context.isDatasetBatchUploaded
                ).isTruthy();
            },
            /**
             * Assert record has not been ingested.
             */
            isNotUploaded: async () => {
                if (!!context) {
                    await Assertion.expect(
                        context.isDatasetBatchUploaded
                    ).isFalsy();
                }
            },
            /**
             * Assert record has been ingested.
             */
            isIngested: async () => {
                await Assertion.expect(
                    context.isDatasetBatchIngested
                ).isTruthy();
            },
            /**
             * Assert record has not been ingested.
             */
            isNotIngested: async () => {
                if (!!context) {
                    await Assertion.expect(
                        context.isDatasetBatchIngested
                    ).isFalsy();
                }
            },
            /**
             * Specified property assertions.
             */
            get file() {
                return {
                    /**
                     * Assert resource has been selected by the corresponding context.
                     * @param {Pick<File, 'name'>} resource
                     */
                    isSelected: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isSelected =
                            context.isFileSelected &&
                            context.file.transient.name === resource.name;
                        await Assertion.expect(isSelected).isTruthy();
                    },
                    /**
                     * Assert resource has not been selected.
                     * @param {Pick<File, 'name'>} resource
                     */
                    isNotSelected: async (resource) => {
                        if (!!context) {
                            const isSelected =
                                context.isFileSelected &&
                                context.file.transient.name === resource.name;
                            await Assertion.expect(isSelected).isFalsy();
                        }
                    },
                    /**
                     * Assert resource has been assigned.
                     * @param {Pick<File, 'name'>} resource
                     */
                    isAssigned: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isAssigned =
                            context.isFileAssigned &&
                            context.file.persisted.name === resource.name;
                        await Assertion.expect(isAssigned).isTruthy();
                    },
                    /**
                     * Assert resource has not been selected.
                     * @param {Pick<File, 'name'>} resource
                     */
                    isNotAssigned: async (resource) => {
                        if (!!context) {
                            const isAssigned =
                                context.isFileAssigned &&
                                context.file.persisted.name === resource.name;
                            await Assertion.expect(isAssigned).isFalsy();
                        }
                    },
                };
            },
            /**
             * Specified property assertions.
             */
            get location() {
                return {
                    /**
                     * Assert resource has been selected by the corresponding context.
                     * @param {Pick<LocationResource, 'id'>} resource
                     */
                    isSelected: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isSelected =
                            context.isLocationSelected &&
                            context.location.transient.id === resource.id;
                        await Assertion.expect(isSelected).isTruthy();
                    },
                    /**
                     * Assert resource has not been selected.
                     * @param {Pick<LocationResource, 'id'>} resource
                     */
                    isNotSelected: async (resource) => {
                        if (!!context) {
                            const isSelected =
                                context.isLocationSelected &&
                                context.location.transient.id === resource.id;
                            await Assertion.expect(isSelected).isFalsy();
                        }
                    },
                    /**
                     * Assert resource has been assigned.
                     * @param {Pick<LocationResource, 'id'>} resource
                     */
                    isAssigned: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isAssigned =
                            context.isLocationAssigned &&
                            context.location.persisted.id === resource.id;
                        await Assertion.expect(isAssigned).isTruthy();
                    },
                    /**
                     * Assert resource has not been assigned.
                     * @param {Pick<LocationResource, 'id'>} resource
                     */
                    isNotAssigned: async (resource) => {
                        if (!!context) {
                            const isAssigned =
                                context.isLocationAssigned &&
                                context.location.persisted.id === resource.id;
                            await Assertion.expect(isAssigned).isFalsy();
                        }
                    },
                };
            },
            /**
             * Specified property assertions.
             */
            get profile() {
                return {
                    /**
                     * Assert resource has been selected by the corresponding context.
                     * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} resource
                     */
                    isSelected: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isSelected =
                            context.isMappingProfileSelected &&
                            context.mappingProfile.transient.id === resource.id;
                        await Assertion.expect(isSelected).isTruthy();
                    },
                    /**
                     * Assert resource has not been selected.
                     * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} resource
                     */
                    isNotSelected: async (resource) => {
                        if (!!context) {
                            const isSelected =
                                context.isMappingProfileSelected &&
                                context.mappingProfile.transient.id ===
                                    resource.id;
                            await Assertion.expect(isSelected).isFalsy();
                        }
                    },
                    /**
                     * Assert resource has been created.
                     * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} resource
                     */
                    isCreated: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isCreated =
                            context.isMappingProfileCreated &&
                            context.mappingProfile.persisted.id === resource.id;
                        await Assertion.expect(isCreated).isTruthy();
                    },
                    /**
                     * Assert resource has not been created.
                     * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} resource
                     */
                    isNotCreated: async (resource) => {
                        if (!!context) {
                            const isCreated =
                                context.isMappingProfileCreated &&
                                context.mappingProfile.persisted.id ===
                                    resource.id;
                            await Assertion.expect(isCreated).isFalsy();
                        }
                    },
                    /**
                     * Assert resource has been applied.
                     * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} resource
                     */
                    isApplied: async (resource) => {
                        await Assertion.expect(context).isNotNil();
                        const isApplied =
                            context.isMappingProfileApplied &&
                            context.mappingProfile.persisted.id ===
                                resource.id &&
                            context.isDatasetBatchUploaded &&
                            context.batch.value.profileId === resource.id;
                        await Assertion.expect(isApplied).isTruthy();
                    },
                    /**
                     * Assert resource has not been applied.
                     * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} resource
                     */
                    isNotApplied: async (resource) => {
                        if (!!context) {
                            const isApplied =
                                context.isMappingProfileApplied &&
                                context.mappingProfile.persisted.id ===
                                    resource.id &&
                                context.isDatasetBatchUploaded &&
                                context.batch.value.profileId === resource.id;
                            await Assertion.expect(isApplied).isFalsy();
                        }
                    },
                };
            },
        };
    }
}
