// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const Theme = createEnum({
    black: 'black', // #000000 (only)
    white: 'white', // #FFFFFF (only)
    primary: 'primary', // #1762a3 (and shades)
    secondary: 'secondary', // #EACF28 (and shades)
    info: 'info', // #1e92f4 (only)
    warning: 'warning', // #FBBF24 (only)
    success: 'success', // #4BB543 (only)
    failure: 'failure', // #DC2626 (only)
    // VVVV same colors but with different intent, in case we want to style later:
    danger: 'danger', // #DC2626 (only)
    error: 'error', // #DC2626 (only)
});

/**
 * Type guard for enum key.
 * @template {keyof Theme} [K=keyof Theme]
 * @param {K} target
 * @param {keyof Theme} key
 * @returns {key is K}
 */
export const isThemeKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {Theme[keyof Theme]} [V=Theme[keyof Theme]]
 * @param {V} target
 * @param {Theme[keyof Theme]} value
 * @returns {value is V}
 */
export const isTheme = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default Theme;
