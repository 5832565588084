// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const Size = createEnum({
    tiny: 'xs',
    small: 'sm',
    medium: 'md',
    large: 'lg',
    huge: 'xl',
});

/**
 * Type guard for enum key.
 * @template {keyof Size} [K=keyof Size]
 * @param {K} target
 * @param {keyof Size} key
 * @returns {key is K}
 */
export const isSizeKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {Size[keyof Size]} [V=Size[keyof Size]]
 * @param {V} target
 * @param {Size[keyof Size]} value
 * @returns {value is V}
 */
export const isSize = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default Size;
