// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';

// <!-- TYPES -->

/**
 * @class
 * Base collection management for a resource index.
 * @extends {ECNBResourceIndexState<Number, import('@/models/mappings/MappingProfile').MappingProfileResource>}
 */
export class MappingProfileIndexState extends ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {Map<Number, import('@/models/mappings/MappingProfile').MappingProfileResource>} [index]
     * @param {Map<String, any>} [attributes]
     */
    constructor(index = new Map(), attributes = new Map()) {
        super('profiles', index, attributes);
    }
}
