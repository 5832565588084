// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const settings = {
    accounts: {
        path: '/account/information',
        name: 'Account Information',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '~AccountSettings/views/AccountInformation.vue'
            ),
        meta: { namespace: 'settings' },
    },
    users: {
        path: '/account/user-manager',
        name: 'User Manager',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "settings" */ '~AccountSettings/views/UserManager.vue'
            ),
        meta: { namespace: 'settings' },
    },
};

// <!-- DEFAULT -->
export default settings;
