// <!-- API -->
import { collect } from 'collect.js';
import {
    UploadRecord,
    RecordProperty,
} from '@/store/types/uploader/state/UploadRecord';

// <!-- TYPES -->
import { Collection } from 'collect.js';
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * Upload statuses.
 */
export const UploadStatusID = /** @type {const}*/ ({
    fetching_locations: 'fetching_locations', // When present, fetching locations.
    suggesting_locations: 'suggesting_locations', // When present, suggesting locations.
    fetching_mapping_profiles: 'fetching_mapping_profiles', // When present, fetching mapping profiles.
    suggesting_mapping_profiles: 'suggesting_mapping_profiles', // When present, suggesting mapping profiles.
});

/**
 * @class
 * Partial state representation of the upload form.
 */
export class UploadData {
    /**
     * Construct a data state instance.
     * @param {Partial<UploadData>} attrs
     */
    constructor(attrs = {}) {
        /**
         * Map of {@link File.name}, {@link UploadRecord} entries, pertaining to individual form records.
         * @type {Map<String, UploadRecord>}
         */
        this.records = new Map();

        /**
         * Set of {@link UploadStatusID} entries, indicating active statuses.
         * @type {Set<keyof UploadStatusID>}
         */
        this.status = new Set();

        // Seal and copy initial values.
        Object.seal(this);
        Object.assign(this, attrs);
    }

    /**
     * Get all filenames.
     * @returns {String[]}
     */
    get filenames() {
        return Object.keys(this.records);
    }

    /**
     * Get the number of records currently available.
     * @returns {Number}
     */
    get count() {
        return this.all.count();
    }

    /**
     * Get all records as a collection.
     * @returns {Collection<UploadRecord>}
     */
    get all() {
        const array = [...this.records.values()];
        return collect(array);
    }

    // <!-- STATUSES -->

    /**
     * Are locations being fetched from remote or cache?
     */
    get isFetchingLocations() {
        return this.status.has('fetching_locations');
    }

    /**
     * Are location suggestions being generated?
     */
    get isSuggestingLocations() {
        return this.status.has('suggesting_locations');
    }

    /**
     * Are there records that require location selections?
     */
    get isMissingLocations() {
        return this.whereLocationMissing.isNotEmpty();
    }

    /**
     * Are one or more datasets being uploaded?
     */
    get isUploading() {
        return this.whereUploading.isNotEmpty();
    }

    /**
     * Are mapping profiles being fetched from remote or cache?
     */
    get isFetchingMappingProfiles() {
        return this.status.has('fetching_mapping_profiles');
    }

    /**
     * Are mapping profile suggestions being generated?
     */
    get isSuggestingMappingProfiles() {
        return this.status.has('suggesting_mapping_profiles');
    }

    /**
     * Are there records that require mapping profile assignment?
     */
    get isMissingMappingProfiles() {
        return this.whereMappingProfileMissing.isNotEmpty();
    }

    /**
     * Is the mapping profile preview being refetched?
     */
    get isRefreshingMappingProfilePreview() {
        return this.whereRefreshingMappingProfilePreview.isNotEmpty();
    }

    /**
     * Is a mapping profile being viewed?
     */
    get isViewingMappingProfile() {
        return this.whereViewingMappingProfile.isNotEmpty();
    }

    /**
     * Is a mapping profile being edited?
     */
    get isEditingMappingProfile() {
        return this.whereEditingMappingProfile.isNotEmpty();
    }

    /**
     * Are one or more mapping profiles being created?
     */
    get isCreatingMappingProfiles() {
        return this.whereCreatingMappingProfiles.isNotEmpty();
    }

    /**
     * Are one or more mapping profile being applied?
     */
    get isApplyingMappingProfiles() {
        return this.whereApplyingMappingProfiles.isNotEmpty();
    }

    /**
     * Are one or more records being ingested?
     */
    get isIngesting() {
        return this.whereIngesting.isNotEmpty();
    }

    // <!-- FLAG FILTERS -->

    /**
     * Get all records where marked for removal.
     * @returns {Collection<UploadRecord>}
     */
    get whereMarkedForRemoval() {
        return this.all.filter((record) => record.isMarkedForRemoval);
    }

    /**
     * Get all records where marked as ingested.
     * @returns {Collection<UploadRecord>}
     */
    get whereMarkedAsIngested() {
        return this.all.filter((record) => record.isMarkedAsIngested);
    }

    // <!-- LOCATION FILTERS -->

    /**
     * Get all records where a location has been selected (but not necessarily assigned on the remote).
     */
    get whereLocationSelected() {
        return this.all.filter((record) => record.isLocationSelected);
    }

    /**
     * Get all records where a location has been suggested.
     */
    get whereLocationSuggested() {
        return this.all.filter((record) => record.isLocationSuggested);
    }

    /**
     * Get all records with missing locations.
     */
    get whereLocationMissing() {
        return this.all.filter((record) => !record.isLocationSelected);
    }

    // <!-- DATASET UPLOAD FILTERS -->

    /**
     * Get all records that are currently uploading their file and location data.
     */
    get whereUploading() {
        return this.all.filter((record) => record.isUploadingDataset);
    }

    /**
     * Get all records where a dataset batch had been initialized on the remote.
     */
    get whereDatasetBatchUploaded() {
        return this.all.filter((record) => record.isDatasetBatchUploaded);
    }

    /**
     * Get all records where a file has been assigned.
     */
    get whereFileAssigned() {
        return this.all.filter((record) => record.isFileAssigned);
    }

    /**
     * Get all records where a location has been assigned.
     */
    get whereLocationAssigned() {
        return this.all.filter((record) => record.isLocationAssigned);
    }

    // <!-- MAPPING PROFILE FILTERS -->

    /**
     * Get all records where a mapping profile has been selected (but not necessarily applied on the remote).
     */
    get whereMappingProfileSelected() {
        return this.all.filter((record) => record.isMappingProfileSelected);
    }

    /**
     * Get all records with missing mapping profiles.
     */
    get whereMappingProfileMissing() {
        return this.all.filter(
            (record) =>
                !record.isMappingProfileSelected && !record.isMarkedAsPEM
        );
    }

    /**
     * Get all records where the preview is being refreshed.
     */
    get whereRefreshingMappingProfilePreview() {
        return this.all.filter((record) => record.isRefreshingPreview);
    }

    /**
     * Get all records where the mapping profile is being viewed. (Only 1 at a time).
     */
    get whereViewingMappingProfile() {
        return this.all.filter((record) => record.isViewingMappingProfile);
    }

    /**
     * Get all records where the mapping profile is being edited. (Only 1 at a time).
     */
    get whereEditingMappingProfile() {
        return this.all.filter((record) => record.isEditingMappingProfile);
    }

    /**
     * Get all records where the mapping profile is being 'created'.
     */
    get whereCreatingMappingProfiles() {
        return this.all.filter((record) => record.isCreatingMappingProfile);
    }

    /**
     * Get all records where a mapping profile has created on the remote.
     */
    get whereMappingProfileCreated() {
        return this.all.filter((record) => record.isMappingProfileCreated);
    }

    /**
     * Get all records where a mapping profile has been suggested.
     */
    get whereMappingProfileSuggested() {
        return this.all.filter((record) => record.isMappingProfileSuggested);
    }

    // <!-- DATASET INGEST FILTERS -->

    /**
     * Get all records being ingested.
     */
    get whereIngesting() {
        return this.all.filter((record) => record.isDatasetBatchIngesting);
    }

    /**
     * Get all records where a dataset batch has been ingested.
     */
    get whereDatasetBatchIngested() {
        return this.whereMarkedAsIngested.filter(
            (record) => record.isDatasetBatchIngested
        );
    }

    /**
     * Get all records where the mapping profile is being 'applied'.
     */
    get whereApplyingMappingProfiles() {
        return this.all.filter((record) => record.isApplyingMappingProfile);
    }

    /**
     * Get all records where a mapping profile has been applied to the batch.
     */
    get whereMappingProfileApplied() {
        return this.all.filter((record) => record.isMappingProfileApplied);
    }

    // <!-- MAPPERS -->

    /**
     * Get the file property for each record.
     */
    get selectedFiles() {
        /** @type {Collection<RecordProperty<File>>} */
        const properties = this.all.pluck('file');
        return properties.map((property) => property.transient);
    }

    /**
     * Get the selected location property for each record.
     */
    get selectedLocations() {
        /** @type {Collection<RecordProperty<LocationResource>>} */
        const properties = this.whereLocationSelected.pluck('location');
        return properties.map((property) => property.transient);
    }

    /**
     * Get the suggested location property for each record.
     */
    get suggestedLocations() {
        /** @type {Collection<LocationResource>} */
        return this.whereLocationSuggested.pluck('suggestedLocation');
    }

    /**
     * Get the uploaded dataset batches.
     */
    get uploadedBatches() {
        /** @type {Collection<RecordProperty<import('@/models/datasets/DatasetBatch').DatasetBatchResource>>} */
        const properties = this.whereDatasetBatchUploaded.pluck('batch');
        return properties.map((property) => property.transient);
    }

    /**
     * Get the assigned file property for each record.
     */
    get assignedFiles() {
        /** @type {Collection<RecordProperty<File>>} */
        const properties = this.whereFileAssigned.pluck('file');
        return properties.map((property) => property.persisted);
    }

    /**
     * Get the assigned location property for each record.
     */
    get assignedLocations() {
        /** @type {Collection<RecordProperty<LocationResource>>} */
        const properties = this.whereLocationAssigned.pluck('location');
        return properties.map((property) => property.persisted);
    }

    /**
     * Get the selected mapping profile property for each record.
     */
    get selectedMappingProfiles() {
        /** @type {Collection<RecordProperty<import('@/models/mappings/MappingProfile').MappingProfileResource>>} */
        const properties =
            this.whereMappingProfileSelected.pluck('mappingProfile');
        return properties.map((property) => property.transient);
    }

    /**
     * Get the created mapping profile property for each record.
     */
    get createdMappingProfiles() {
        /** @type {Collection<RecordProperty<import('@/models/mappings/MappingProfile').MappingProfileResource>>} */
        const properties =
            this.whereMappingProfileCreated.pluck('mappingProfile');
        return properties.map((property) => property.persisted);
    }

    /**
     * Get the suggested mapping profile property for each record.
     */
    get suggestedMappingProfiles() {
        /** @type {Collection<import('@/models/mappings/MappingProfile').MappingProfileResource>} */
        return this.whereMappingProfileSuggested.pluck(
            'suggestedMappingProfile'
        );
    }

    /**
     * Get the ingested dataset batches.
     */
    get ingestedBatches() {
        /** @type {Collection<RecordProperty<import('@/models/datasets/DatasetBatch').DatasetBatchResource>>} */
        const properties = this.whereDatasetBatchIngested.pluck('batch');
        return properties.map((property) => property.persisted);
    }

    /**
     * Get the assigned mapping profile property for each record.
     */
    get appliedMappingProfiles() {
        /** @type {Collection<RecordProperty<import('@/models/mappings/MappingProfile').MappingProfileResource>>} */
        const properties =
            this.whereMappingProfileApplied.pluck('mappingProfile');
        return properties.map((property) => property.persisted);
    }

    /**
     * Check if status is active.
     * @param {keyof UploadStatusID} id
     */
    isStatusActive(id) {
        return this.status.has(id);
    }
}
