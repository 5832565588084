// <!-- TYPES -->
/** @typedef {import('@/utils/enums').EnumKey} EnumKey */
/** @typedef {import('@/utils/enums').EnumValue} EnumValue */
/** @template {EnumKey} [K=EnumKey] @template {EnumValue} [V=EnumValue] @typedef {import('@/utils/enums').IEnum} IEnum */

/**
 * Type guard. Allows type inference for the tested variable.
 * @template {EnumKey} [K=EnumKey]
 * @template {EnumValue} [V=EnumValue]
 * @param {IEnum<K,V>} Enum Enum to check membership of.
 * @param {any} value The value to test.
 * @returns {value is V} `true` if input argument is of a key contained by {@link E}.
 */
export const isEnumValue = (Enum, value) => {
    if (Enum !== undefined && Enum !== null && typeof Enum === 'object') {
        /** @type {Readonly<Array<EnumValue>>} */
        const values = Object.freeze(Object.values(Enum));
        if (values?.length > 0) {
            // If valid enum instance has been provided...
            return values.includes(value);
        }
    }
    // Invalid enum instance...
    throw new TypeError(`Cannot test membership of an invalid IEnum instance.`);
};

// <!-- DEFAULT -->
export default isEnumValue;
