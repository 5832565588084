// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- MODELS -->
import { Accounts, Profile } from '@/models';
import { result } from 'lodash';

// <!-- TYPES -->
/** @typedef {import('@/models/accounts/Account').AccountPayload} AccountPayload */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/** @typedef {import('@/models/profile/CurrentUserProfile').CurrentUserProfilePayload} CurrentUserProfilePayload */
/** @typedef {import('@/models/profile/CurrentUserProfile').CurrentUserProfileResource} CurrentUserProfileResource */

/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */

/** @typedef {import('@/api').ChangePasswordRequest} ChangePasswordRequest */
/** @typedef {import('@/api').ChangePasswordResponse} ChangePasswordResponse */
/** @typedef {import('@/api').UpdateCurrentUserProfileRequest} UpdateCurrentUserProfileRequest */
/** @typedef {import('@/api').UpdateCurrentUserProfileResponse} UpdateCurrentUserProfileResponse */

/**
 * @typedef {Object} IForgotPasswordRequest
 * @property {String} email
 */

/**
 * @typedef {Object} IForgotPasswordResponse
 * @property {String} status
 * @property {String} [message]
 */

/**
 * @typedef {Object} IResetPasswordRequest
 * @property {String} token
 * @property {String} email
 * @property {String} password
 * @property {String} password_confirmation
 */

/**
 * @typedef {Object} IResetPasswordSuccessResponse
 * @property {"passwords.reset"} status Status.
 * @property {String} message Success message.
 */

/**
 * @typedef {Object} IResetPasswordValidationErrorResponse
 * @property {String} message Error message.
 * @property {Record<String, String[]>} errors Validation error record mapping rules to violations.
 */

/**
 * @typedef {Object} IResetPasswordInvalidUserResponse
 * @property {"passwords.user"} status Status.
 * @property {String} message Error message.
 * @property {{ user: String }} errors  error record mapping rules to violations.
 */

/**
 * @typedef {Object} IResetPasswordInvalidTokenResponse
 * @property {"passwords.token"} status Status.
 * @property {String} message Error message.
 * @property {{ token: String }} errors  error record mapping rules to violations.
 */

/** @typedef {IResetPasswordSuccessResponse | IResetPasswordValidationErrorResponse | IResetPasswordInvalidTokenResponse | IResetPasswordInvalidUserResponse} IResetPasswordResponse */

/**
 * @typedef {Object} IResetPasswordResult
 * @property {Number} status
 * @property {String} label
 * @property {Set<String>} [errors] Form errors, if any were received.
 */

// <!-- ROUTES -->

/** @typedef {Object} */
const ROUTES = {
    GET_PROFILE_ACCOUNTS: () => 'users/me/accounts', // TODO: Implement route.
    GET_PROFILE: () => 'profile',
    UPDATE_PROFILE: () => 'profile',
    CHANGE_PASSWORD: () => 'profile/change-password',
    FORGOT_PASSWORD: () => 'forgot-password',
    RESET_PASSWORD: () => 'reset-password',
    REGISTER: () => 'register', // TODO: Implement route.
};

// <!-- REQUESTS -->

/**
 * Fetch the current user's accounts.
 * @return {Promise<AccountResource[]>}
 */
export const fetchCurrentUserAccounts = async () => {
    const profile = await fetchCurrentUserProfile();
    const collection = /** @type {AccountPayload[]} */ (
        /** @type {unknown} */ (profile.accounts)
    );
    if (collection.length > 0) {
        const accounts = collection.map((account) =>
            new Accounts.Account({ payload: account }).toResource()
        );
        return accounts;
    } else {
        console.error('No accounts associated with the current user.');
        return [];
    }
};

/**
 * Fetch the current user account.
 * @param {import('@api/profile').CurrentUserProfileResource} [sessionUser] If present, use the session user accounts.
 * @return {Promise<AccountResource>}
 */
export const fetchCurrentUserPrimaryAccount = async (sessionUser = null) => {
    const useSessionAccounts = !!sessionUser && !!sessionUser.accounts.length;
    if (useSessionAccounts) {
        return sessionUser.accounts[0];
    } else {
        return await fetchCurrentUserAccounts()[0];
    }
};

/**
 * Fetch the current user profile. Always makes a new request.
 * @return {Promise<CurrentUserProfileResource>}
 */
export const fetchCurrentUserProfile = async () => {
    /** @type {import('axios').AxiosResponse<{ profile: CurrentUserProfilePayload }>} */
    const response = await axios().get(ROUTES.GET_PROFILE());
    const { profile } = response.data;
    const user = new Profile.CurrentUserProfile({
        payload: profile,
    }).toResource();
    return user;
};

/**
 * Update the current user's username and email.
 * @param {UpdateCurrentUserProfileRequest} request
 */
export const updateCurrentUserProfile = async (
    request = { user_name: 'ieits', email: 'ian.effendi@sharpnotions.com' }
) => {
    /** @type {import('axios').AxiosResponse<UpdateCurrentUserProfileResponse>} */
    const response = await axios().put(ROUTES.UPDATE_PROFILE(), request);
    const { status, profile } = response.data;
    return { status, profile };
};

/**
 * Change the current user profile password.
 * @param {ChangePasswordRequest} request
 * @returns {Promise<ChangePasswordResponse>}
 */
export const changeCurrentUserPassword = async (
    request = { old_password: 'password', new_password: 'password' }
) => {
    /** @type {import('axios').AxiosResponse<ChangePasswordResponse>} */
    const response = await axios().post(ROUTES.CHANGE_PASSWORD(), request);
    const { status, message } = response.data;
    return { status, message };
};

/**
 * Reset a user's password with a given email.
 * @param {IForgotPasswordRequest} request
 * @returns {Promise<IForgotPasswordResponse>}
 */
export const forgotPassword = async (request = { email: 'email' }) => {
    /** @type {import('axios').AxiosResponse<IForgotPasswordResponse>} */
    const response = await axios().post(ROUTES.FORGOT_PASSWORD(), request);
    // const { status, message } = response.data;
    // return { status, message };
    return response.data;
};

/**
 * Reset the specified user's password, using pre-generated reset password token.
 * @param {IResetPasswordRequest} request
 * @returns {Promise<IResetPasswordResult>}
 */
export const resetPassword = async (request) => {
    // <!-- TYPE GUARDS -->

    /**
     * Detect specific response type.
     * @param {IResetPasswordResponse} response
     * @returns {response is IResetPasswordValidationErrorResponse}
     */
    const isValidationErrorResponse = (response) => {
        return (
            !isNil(response) &&
            !('status' in response) &&
            !isNil(response.message) &&
            !isNil(response.errors)
        );
    };

    /**
     * Detect specific response type.
     * @param {IResetPasswordResponse} response
     * @returns {response is IResetPasswordInvalidTokenResponse}
     */
    const isInvalidTokenResponse = (response) => {
        return (
            !isNil(response) &&
            'status' in response &&
            response.status === 'passwords.token'
        );
    };

    /**
     * Detect specific response type.
     * @param {IResetPasswordResponse} response
     * @returns {response is IResetPasswordInvalidUserResponse}
     */
    const isInvalidUserResponse = (response) => {
        return (
            !isNil(response) &&
            'status' in response &&
            response.status === 'passwords.user'
        );
    };

    /**
     * Detect specific response type.
     * @param {IResetPasswordResponse} response
     * @returns {response is IResetPasswordSuccessResponse}
     */
    const isSuccessResponse = (response) => {
        return (
            !isNil(response) &&
            'status' in response &&
            response.status === 'passwords.reset'
        );
    };

    /**
     * Detect axios response.
     * @param {Pick<AxiosResponse, 'status' | 'data'>} response
     * @returns {response is AxiosResponse<IResetPasswordResponse, IResetPasswordRequest>}
     */
    const isResetPasswordAxiosResponse = (response) => {
        return !isNil(response) && 'status' in response && 'data' in response;
    };

    /**
     * Handle the different response types.
     * @param {Number} status
     * @param {IResetPasswordResponse} response
     * @returns {IResetPasswordResult}
     */
    const handleResponse = (status, response) => {
        // Prepare default result.
        /** @type {IResetPasswordResult} */
        const result = {
            status: 500,
            label: 'Your password was not reset!',
            errors: new Set([
                'An unknown server error occurred.',
                'Please contact your system administrator.',
            ]),
        };

        // Assign the status.
        result.status = status;

        // Assign the primary message label.
        result.label = response.message;

        // Handle response based on code and schema.
        switch (result.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response is successful...
                    // ...clear errors.
                    result.errors = new Set([]);
                }
                break;
            case 422:
                if (
                    isValidationErrorResponse(response) ||
                    isInvalidTokenResponse(response) ||
                    isInvalidUserResponse(response)
                ) {
                    // If response (with errors)...
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(response.errors);
                    const violations = rules.flatMap(
                        (rule) => response.errors[rule]
                    );
                    result.errors = new Set(violations);
                }
                if (isInvalidTokenResponse(response)) {
                    // If response (invalid token error)...
                    // ...sanitize the status input based on error.
                    result.status = 403;
                    // ...add error message.
                    result.errors.add(
                        'You must request a new link to reset your password.'
                    );
                }
                if (isInvalidUserResponse(response)) {
                    // If response (invalid token error)...
                    // ...sanitize the status input based on error.
                    result.status = 401;
                }
                break;
            case 500:
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response
                    )}`
                );
        }

        // Return the modified result.
        return result;
    };

    try {
        // Send axios request.
        /** @type {import('axios').AxiosResponse<IResetPasswordResponse, IResetPasswordRequest>} */
        const { status, data: response } = await axios().post(
            ROUTES.RESET_PASSWORD(),
            request
        );
        // Handle success response.
        const success = handleResponse(status, response);
        // Return response.
        return success;
    } catch (e) {
        // Handle error response.
        if (isResetPasswordAxiosResponse(e.response)) {
            // Get the request response properties.
            /** @type {Pick<AxiosResponse<IResetPasswordResponse, IResetPasswordRequest>, 'status' | 'data'>} */
            const { status, data: response } = e.response;
            // Get the error result.
            const failure = handleResponse(status, response);
            // Return response.
            return failure;
        }
        // If no response present in error, throw error up the stack.
        throw e;
    }
};

// <!-- EXPORTS -->
export default {
    Account: Accounts.Account,
    Profile: Profile.CurrentUserProfile,
    fetchCurrentUserAccounts,
    fetchCurrentUserPrimaryAccount,
    fetchCurrentUserProfile,
    updateCurrentUserProfile,
    changeCurrentUserPassword,
    forgotPassword,
    resetPassword,
};
