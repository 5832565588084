// <!-- API -->
import router from '@/router';
import profile from '@api/profile';
import UserStateMutations from '@/store/types/users/mutations';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { UserState } from '@/store/types/users/state';

// CLASS
/**
 * @class
 * User module.
 */
export class UserModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'users';
    }

    /**
     * User module state, getters, mutations, and actions.
     * @type {import('vuex').Module<UserState, ECNBState>}
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: false,
            state: () => new UserState(),
            get getters() {
                /**
                 * Is a valid user logged in?
                 * @param {UserState} state
                 */
                const isLoggedIn = (state) => {
                    return state.hasUser;
                };
                /**
                 * Is the logged in user an administrator?
                 * @param {UserState} state
                 */
                const isAdmin = (state) => {
                    return state?.me?.role === 'admin';
                };
                /**
                 * Is a valid user logged in?
                 * @param {UserState} state
                 */
                const UserID = (state) => {
                    return state.me.id;
                };
                /**
                 * Is a valid user logged in?
                 * @param {UserState} state
                 */
                const UserEmail = (state) => {
                    return state.me.email;
                };
                /**
                 * Is a valid user logged in?
                 * @param {UserState} state
                 */
                const UserName = (state) => {
                    return state.me.username;
                };
                return {
                    isAdmin,
                    isLoggedIn,
                    UserID,
                    UserEmail,
                    UserName,
                };
            },
            get mutations() {
                const $ = UserStateMutations;
                /**
                 * Set the current user.
                 * @param {UserState} state
                 * @param {import('@api/profile').CurrentUserProfileResource} payload
                 */
                const setCurrentUser = (state, payload) => {
                    $.set(state).user(payload);
                };
                /**
                 * Update the current user.
                 * @param {UserState} state
                 * @param {import('@api/profile').CurrentUserProfileResource} payload
                 */
                const patchCurrentUser = (state, payload) => {
                    $.patch(state).user(payload);
                };
                /**
                 * Update the current user.
                 * @param {UserState} state
                 */
                const clearCurrentUser = (state) => {
                    $.clear(state).user();
                };
                return {
                    setCurrentUser,
                    patchCurrentUser,
                    clearCurrentUser,
                };
            },
            get actions() {
                /**
                 * Invoke the logout user command.
                 * @param {import('vuex').ActionContext<UserState, ECNBState>} context
                 */
                const logout = async ({ state, commit, rootState }) => {
                    console.log(
                        `[logout::user] => [${state?.me?.id}] ${state?.me?.username}`,
                        { users: state, root: rootState }
                    );

                    // <- CALL THIS ACTION TO SIGNAL LOGOUT -->
                    // CLEAR UPLOADER STATE
                    commit('uploader/data/clearModule', undefined, {
                        root: true,
                    });
                    commit('uploader/errors/clearModule', undefined, {
                        root: true,
                    });
                    commit('uploader/workflow/clearModule', undefined, {
                        root: true,
                    });
                    commit('uploader/clearModule', undefined, { root: true });

                    // CLEAR CACHE STATE
                    commit('cache/accounts/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/hierarchies/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/locations/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/notes/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/profiles/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/standards/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/stations/clearModule', undefined, {
                        root: true,
                    });
                    commit('cache/users/clearModule', undefined, {
                        root: true,
                    });

                    // CLEAR ANALYSIS FILTERS
                    commit('analysis/clearFilters', undefined, { root: true });

                    // CLEAR ACCOUNT STATE
                    commit('clearCurrentAccount', undefined, { root: true });

                    // CLEAR USER STATE
                    commit('clearCurrentUser', undefined, { root: true });

                    // REMOVE AUTHENTICATION TOKENS.
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('refresh_token');

                    // REMOVE CURRENT SELECTED ACCOUNT.
                    sessionStorage.removeItem('selected_account');

                    // REDIRECT
                    const BanRedirectList = ['Login', 'Sign Up'];
                    const currentRouteName = router.currentRoute.value.name;
                    const IsRedirectAllowed =
                        typeof currentRouteName !== 'string' ||
                        !BanRedirectList.includes(currentRouteName);
                    if (IsRedirectAllowed) {
                        router.push({ name: 'Login' });
                    }
                };
                /**
                 * Fetch the user.
                 * @param {import('vuex').ActionContext<UserState, ECNBState>} context
                 */
                const fetchCurrentUser = async ({ commit }) => {
                    const user = await profile.fetchCurrentUserProfile();
                    commit('setCurrentUser', user);
                    return user;
                };
                return {
                    logout,
                    fetchCurrentUser,
                };
            },
        };
    }
}

// DEFAULT
export default UserModule;
