// <!-- API -->
import { ref, computed } from 'vue';
import { useECNBCache, ECNBCache } from '@/hooks/store/useECNBCache';

// <!-- TYPES -->
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/**
 * Using a cache (or with a new instance), handle index operations.
 * @param {ECNBCache} [vuexCache]
 */
export const useAccountsIndex = (vuexCache) => {
    // STORE

    /** @type {ECNBCache} */
    const cache = vuexCache ?? useECNBCache();

    // STATE

    /** @type {V.Ref<Boolean>} */
    const isFetching = ref(false);

    // COMPUTED PROPERTIES

    /** @type {V.ComputedRef<AccountResource[]>} */
    const accounts = computed(() => {
        // ts-ignore
        const accountsCache = cache.accounts ? cache.accounts.value : null;
        const isEmpty = accountsCache ? accountsCache.is.empty : true;
        return isEmpty ? [] : accountsCache.all.all();
    });

    // METHODS

    /**
     * Refetch the cached index for the page.
     * @param {Boolean} forceReload
     */
    const refreshAccountsIndex = async (forceReload = false) => {
        try {
            isFetching.value = true;
            const ignoreCache = forceReload === true;
            await cache.fetch.accounts({ ignoreCache });
            return accounts.value;
        } catch (err) {
            console.error(err);
        } finally {
            isFetching.value = false;
        }
    };

    return {
        refreshAccountsIndex,
        isFetching,
        cache,
        accounts,
    };
};
