// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const Reminder = createEnum({
    'Not Now': 'never',
    Weekly: 'weekly',
    'Semi-monthly': 'biweekly',
    Monthly: 'monthly',
    Quarterly: 'quarterly',
});

/**
 * Type guard for enum key.
 * @template {keyof Reminder} [K=keyof Reminder]
 * @param {K} target
 * @param {keyof Reminder} key
 * @returns {key is K}
 */
export const isReminderKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {Reminder[keyof Reminder]} [V=Reminder[keyof Reminder]]
 * @param {V} target
 * @param {Reminder[keyof Reminder]} value
 * @returns {value is V}
 */
export const isReminder = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default Reminder;
