// <!-- TYPES -->
import { Model, FORMAT } from '@/models/Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<NARAStandard['toPayload']>} NARAStandardPayload */
/** @typedef {ReturnType<NARAStandard['toResource']>} NARAStandardResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'description',
    'standard',
    'minTemp',
    'maxTemp',
    'minRh',
    'maxRh',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.description, 'description'],
    [FIELDS.standard, 'standard'],
    [FIELDS.minTemp, 'min_temp'],
    [FIELDS.maxTemp, 'max_temp'],
    [FIELDS.minRh, 'min_rh'],
    [FIELDS.maxRh, 'max_rh'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * Represents the current logged in user.
 * @class
 * @extends {Model}
 */
export class NARAStandard extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    toPayload() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.id,
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].description]: this.get(FIELDS.description),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].standard]: this.get(FIELDS.standard),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].min_rh]: this.get(FIELDS.minRh),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].max_rh]: this.get(FIELDS.maxRh),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].min_temp]: this.get(FIELDS.minTemp),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].max_temp]: this.get(FIELDS.maxTemp),
        };
    }

    toResource() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.id,
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].description]: this.get(FIELDS.description),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].standard]: this.get(FIELDS.standard),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].min_rh]: this.get(FIELDS.minRh),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].max_rh]: this.get(FIELDS.maxRh),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].min_temp]: this.get(FIELDS.minTemp),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].max_temp]: this.get(FIELDS.maxTemp),
        };
    }
}
