// <!-- TYPES -->

import { Model, FORMAT } from '@/models/Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys(['id', 'name']);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class - Represents a location hierarchy payload.
 */
export class NoteLocationHierarchyPayload {
    /** @type {Number} Node id. */
    id = null;
    /** @type {String} Node name. */
    name = null;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteLocationHierarchyPayload>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the payload.
     * @param {NoteLocationHierarchy} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);

        // Return this.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
        ];
    }
}

/**
 * @class - Represents a location hierarchy resource.
 */
export class NoteLocationHierarchyResource {
    /** @type {Number} Node id. */
    id = null;
    /** @type {String} Node name. */
    name = null;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteLocationHierarchyResource>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the resource.
     * @param {NoteLocationHierarchy} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);

        // Return this.
        return this;
    }

    /**
     * Convert this resource into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
        ];
    }
}

/**
 * @class
 * @extends {Model<NoteLocationHierarchyPayload, NoteLocationHierarchyResource>}
 */
export class NoteLocationHierarchy extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * @param {import('@/types').ExcludeMethods<NoteLocationHierarchyPayload>} payload
     * @returns {this}
     */
    parsePayload(payload) {
        const instance = new NoteLocationHierarchyPayload(payload);
        return this.parseArray(instance.entries());
    }

    /**
     * @returns {NoteLocationHierarchyPayload}
     */
    toPayload() {
        return new NoteLocationHierarchyPayload().parseModel(this);
    }

    /**
     * Parse the input resource.
     * @param {import('@/types').ExcludeMethods<NoteLocationHierarchyResource>} resource
     * @returns {this}
     */
    parseResource(resource) {
        const instance = new NoteLocationHierarchyResource(resource);
        return this.parseArray(instance.entries());
    }

    /**
     * @returns {NoteLocationHierarchyResource}
     */
    toResource() {
        return new NoteLocationHierarchyResource().parseModel(this);
    }
}
