// <!-- MODELS -->
import { SearchTerms } from '@/models';

// <!-- ROUTES -->
const ROUTES = {
    GET_SEARCH_TERMS: (account) => `accounts/${account}/search-terms`,
    GET_SEARCH_TERM: (account, searchTerm) =>
        `accounts/${account}/search-terms/${searchTerm}`,
    CREATE_SEARCH_TERM: (account) => `accounts/${account}/search-terms`,
    UPDATE_SEARCH_TERM: (account, searchTerm) =>
        `accounts/${account}/search-terms/${searchTerm}`,
    DELETE_SEARCH_TERM: (account, searchTerm) =>
        `accounts/${account}/search-terms/${searchTerm}`,
};

// <!-- REQUESTS -->

// <!-- EXPORTS -->
export default {
    SearchTerm: SearchTerms.SearchTerm,
    // fetchSearchTerms,
    // fetchSearchTermById,
    // createSearchTerm,
    // updateSearchTermById,
    // deleteSearchTermById,
};
