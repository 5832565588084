// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';
import {
    SidebarFilterRecord,
    DateRangeFilter,
    LimitFilterRecord,
    ScaleFilterRecord,
    AxisRange,
    LimitFilter,
    ScaleFilter,
    LocationFilter,
    WeatherStationFilter,
    DateRange,
} from '@/utils/filters';

// <!-- UTILITIES -->
import { DateTimeISO } from '@/utils/datetime';
import { startOfDay, endOfDay, sub } from 'date-fns';
import { Tree } from '@/utils/tree';

// <!-- TYPES -->
import { AnalysisState } from '@/store/types/analysis/state';
/** @typedef {import('@/utils/filters').IDate} IDate */
/** @typedef {import('@/utils/filters').IInterval} IInterval */
/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter */
/** @typedef {import('@/utils/filters').IDateRangeModifierID} IDateRangeModifierID */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IAxisModifier} IAxisModifier */
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter */
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */
/** @typedef {import('@/utils/filters').ILocationFilter} ILocationFilter */
/** @typedef {import('@/utils/filters').IWeatherStationFilter} IWeatherStationFilter */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */

/**
 * Synchronous {@link AnalysisState} mutations.
 */
export class AnalysisStateMutations extends ECNBStateMutations {
    // <!-- HELPERS -->

    /**
     * Provide access to direct state assignment operations.
     * @param {AnalysisState} state State instance.
     */
    static set(state) {
        const $ = AnalysisStateMutations;
        return {
            /**
             * Assign all filters.
             * @param {Readonly<ISidebarFilterRecord>} payload
             */
            filters: (payload) => {
                // Copy the provided payload values.
                const filters = SidebarFilterRecord.clone(payload);
                // Assign the filters.
                state.filters = filters;
            },
            /**
             * Access to date range filter setters.
             */
            get dates() {
                return {
                    /**
                     * Assign the date range filter directly.
                     * @param {Readonly<IDateRangeFilter>} payload
                     */
                    filter: (payload) => {
                        // Copy the provided payload values.
                        const dates = DateRangeFilter.clone(payload);
                        // Patch the entire filters state.
                        $.patch(state).filters({ dates });
                    },
                    /**
                     * Assign the date range strings directly.
                     * @param {Readonly<IInterval>} payload
                     */
                    range: (payload) => {
                        // Copy the provided payload values.
                        const range = DateRange.clone(payload);
                        // Patch the entire filters state.
                        $.patch(state).dates.filter(range);
                    },
                    /**
                     * Assign the start date directly.
                     * @param {Readonly<IDate>} payload
                     */
                    start: (payload) => {
                        // Parse the provided payload values.
                        const start = DateTimeISO.clone(payload).getTime();
                        // Patch the date range filter.
                        $.patch(state).date.range({ start });
                    },
                    /**
                     * Assign the end date directly.
                     * @param {Readonly<IDate>} payload
                     */
                    end: (payload) => {
                        // Parse the provided payload values.
                        const end = DateTimeISO.clone(payload).getTime();
                        // Patch the date range filter.
                        $.patch(state).date.range({ end });
                    },
                    /**
                     * Assign the modifiers directly.
                     * @param {Readonly<Iterable<IDateRangeModifierID>>} [payload]
                     */
                    modifiers: (payload = []) => {
                        // Get the modifiers.
                        const source = new Set([...payload]);
                        // Get the array.
                        const checked = [...source.values()];
                        // Patch the date range filter.
                        $.patch(state).dates.filter({ checked });
                    },
                };
            },
            /**
             * Access to limits filter setters.
             */
            get limits() {
                return {
                    /**
                     * Assign the limit filters explicitly.
                     * @param {Readonly<ILimitFilterRecord>} payload
                     */
                    record: (payload) => {
                        // Copy the payload values.
                        const limits = LimitFilterRecord.clone(payload);
                        // Patch the sidebar filters.
                        $.patch(state).filters({ limits });
                    },
                    /**
                     * Access keyed limits filter axis.
                     */
                    get temp() {
                        return {
                            /**
                             * Assign the limit filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const temp = LimitFilter.temp(payload);
                                // Patch the limit filter record.
                                $.patch(state).limits.record({ temp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the temperature limit filter.
                                $.patch(state).limits.temp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed limits filter axis.
                     */
                    get rh() {
                        return {
                            /**
                             * Assign the limit filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const rh = LimitFilter.rh(payload);
                                // Patch the limit filter record.
                                $.patch(state).limits.record({ rh });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the limit filter.
                                $.patch(state).limits.rh(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.rh({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.rh({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.rh({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed limits filter axis.
                     */
                    get dp() {
                        return {
                            /**
                             * Assign the limit filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const dp = LimitFilter.dp(payload);
                                // Patch the limit filter record.
                                $.patch(state).limits.record({ dp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the limit filter.
                                $.patch(state).limits.dp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.dp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.dp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the limit filter.
                                $.patch(state).limits.dp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                };
            },
            /**
             * Access to scales filter setters.
             */
            get scales() {
                return {
                    /**
                     * Assign the scale filters explicitly.
                     * @param {Readonly<IScaleFilterRecord>} payload
                     */
                    record: (payload) => {
                        // Copy the payload values.
                        const scales = ScaleFilterRecord.clone(payload);
                        // Patch the sidebar filters.
                        $.patch(state).filters({ scales });
                    },
                    /**
                     * Access keyed scales filter axis.
                     */
                    get temp() {
                        return {
                            /**
                             * Assign the scale filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const temp = ScaleFilter.temp(payload);
                                // Patch the scale filter record.
                                $.patch(state).scales.record({ temp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the temperature scale filter.
                                $.patch(state).scales.temp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed scales filter axis.
                     */
                    get rh() {
                        return {
                            /**
                             * Assign the scale filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const rh = ScaleFilter.rh(payload);
                                // Patch the scale filter record.
                                $.patch(state).scales.record({ rh });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the scale filter.
                                $.patch(state).scales.rh(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.rh({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.rh({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.rh({
                                    checked: payload,
                                });
                            },
                        };
                    },
                    /**
                     * Access keyed scales filter axis.
                     */
                    get dp() {
                        return {
                            /**
                             * Assign the scale filters explicitly.
                             * @param {Readonly<IAxisRangeFilter>} payload
                             */
                            filter: (payload) => {
                                // Copy the payload values.
                                const dp = ScaleFilter.dp(payload);
                                // Patch the scale filter record.
                                $.patch(state).scales.record({ dp });
                            },
                            /**
                             * Assign the lower and upper values explicitly.
                             * @param {Readonly<IAxisRange>} payload
                             */
                            extents: (payload) => {
                                // Copy the payload values.
                                const extents = AxisRange.clone(payload);
                                // Patch the scale filter.
                                $.patch(state).scales.dp(extents);
                            },
                            /**
                             * Assign the lower value explicitly.
                             * @param {Readonly<IAxisRange['lower']>} payload
                             */
                            lower: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.dp({ lower: payload });
                            },
                            /**
                             * Assign the upper value explicitly.
                             * @param {Readonly<IAxisRange['upper']>} payload
                             */
                            upper: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.dp({ upper: payload });
                            },
                            /**
                             * Assign the modifier value explicitly.
                             * @param {Readonly<Boolean>} payload
                             */
                            modifier: (payload) => {
                                // Patch the scale filter.
                                $.patch(state).scales.dp({
                                    checked: payload,
                                });
                            },
                        };
                    },
                };
            },
            /**
             * Access to locations filter setters.
             */
            get locations() {
                return {
                    /**
                     * Set the tree explicitly.
                     * @param {Readonly<Treeview.Tree>} payload
                     */
                    tree: (payload) => {
                        // Copy the payload values.
                        const locations = LocationFilter.create(payload);
                        // Patch the analysis sidebar filter.
                        $.patch(state).filters({ locations });
                    },
                };
            },
            /**
             * Access to weather stations filter setters.
             */
            get stations() {
                return {
                    /**
                     * Assign the treeview filter explicitly.
                     * @param {Readonly<Treeview.Tree>} payload
                     */
                    tree: (payload) => {
                        // Copy the payload values.
                        const stations = WeatherStationFilter.create(payload);
                        // Patch the analysis sidebar filter.
                        $.patch(state).filters({ stations });
                    },
                };
            },
        };
    }

    /**
     * Provide access to state update/merging operations.
     * @param {AnalysisState} state State instance.
     */
    static patch(state) {
        const $ = AnalysisStateMutations;
        return {
            /**
             * Update existing filters.
             * @param {Readonly<Partial<ISidebarFilterRecord>>} payload
             */
            filters: (payload) => {
                // Copy the original store values.
                const source = { ...state.filters };

                // Copy the provided payload values.
                const patch = { ...payload };

                // Create the patched limits object.
                const merged = Object.assign({}, source, patch);

                // Patch the filters.
                $.set(state).filters(merged);
            },
            /**
             * Access to date range filter patchers.
             */
            get dates() {
                return {
                    /**
                     * Patch the date range filter.
                     * @param {Readonly<Partial<IDateRangeFilter>>} payload
                     */
                    filter: (payload) => {
                        // Copy the original store values.
                        const source = DateRangeFilter.clone(
                            state.filters.dates
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the merged patched filter.
                        const dates = Object.assign({}, source, patch);

                        // Patch the filters record.
                        $.patch(state).filters({ dates });
                    },
                    /**
                     * Patch the date range in the date range filter.
                     * @param {Readonly<Partial<IInterval>>} payload
                     */
                    range: (payload) => {
                        // Copy the store values.
                        const { start, end } = { ...state.filters.dates };

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the patched range.
                        const range = DateRange.create(
                            Object.assign({}, { start, end }, patch)
                        );

                        // Patch the date range filter.
                        $.patch(state).dates.filter(range);
                    },
                };
            },
            /**
             * Access to limits filter patchers.
             */
            get limits() {
                return {
                    /**
                     * Patch the limit filter record.
                     * @param {Readonly<Partial<ILimitFilterRecord>>} [payload]
                     */
                    record: (payload = {}) => {
                        // Copy the original store values.
                        const source = LimitFilterRecord.clone(
                            state.filters.limits
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const limits = Object.assign({}, source, patch);

                        // Patch the filters.
                        $.patch(state).filters({ limits });
                    },
                    /**
                     * Patch the limit filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    temp: (payload) => {
                        // Copy the original store values.
                        const source = LimitFilter.clone(
                            state.filters.limits.temp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const temp = Object.assign({}, source, patch);

                        // Patch the limit filter record.
                        $.patch(state).limits.record({ temp });
                    },
                    /**
                     * Patch the limit filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    rh: (payload) => {
                        // Copy the original store values.
                        const source = LimitFilter.clone(
                            state.filters.limits.rh
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const rh = Object.assign({}, source, patch);

                        // Patch the limit filter record.
                        $.patch(state).limits.record({ rh });
                    },
                    /**
                     * Patch the limit filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    dp: (payload) => {
                        // Copy the original store values.
                        const source = LimitFilter.clone(
                            state.filters.limits.dp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched limits object.
                        const dp = Object.assign({}, source, patch);

                        // Patch the limit filter record.
                        $.patch(state).limits.record({ dp });
                    },
                };
            },
            /**
             * Access to scales filter patchers.
             */
            get scales() {
                return {
                    /**
                     * Patch the scale filter record.
                     * @param {Readonly<Partial<IScaleFilterRecord>>} [payload]
                     */
                    record: (payload = {}) => {
                        // Copy the original store values.
                        const source = ScaleFilterRecord.clone(
                            state.filters.scales
                        );

                        // Copy the provided payload values.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const scales = Object.assign({}, source, patch);

                        // Patch the filters.
                        $.patch(state).filters({ scales });
                    },
                    /**
                     * Patch the scale filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    temp: (payload) => {
                        // Copy the original store values.
                        const source = ScaleFilter.clone(
                            state.filters.scales.temp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const temp = Object.assign({}, source, patch);

                        // Patch the scale filter record.
                        $.patch(state).scales.record({ temp });
                    },
                    /**
                     * Patch the scale filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    rh: (payload) => {
                        // Copy the original store values.
                        const source = ScaleFilter.clone(
                            state.filters.scales.rh
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const rh = Object.assign({}, source, patch);

                        // Patch the scale filter record.
                        $.patch(state).scales.record({ rh });
                    },
                    /**
                     * Patch the scale filter.
                     * @param {Readonly<Partial<IAxisRangeFilter>>} payload
                     */
                    dp: (payload) => {
                        // Copy the original store values.
                        const source = ScaleFilter.clone(
                            state.filters.scales.dp
                        );

                        // Copy the payload.
                        const patch = { ...payload };

                        // Create the patched scales object.
                        const dp = Object.assign({}, source, patch);

                        // Patch the scale filter record.
                        $.patch(state).scales.record({ dp });
                    },
                };
            },
            /**
             * Access to the location filter patchers.
             */
            get locations() {
                return {
                    /**
                     * Patch the existing location filter tree.
                     * @param {Readonly<Treeview.TreeProps>} payload
                     */
                    tree: (payload) => {
                        // GET current tree.
                        const current = state.filters.locations.tree;
                        const update = Tree.override(current, payload);

                        // UPDATE location filter.
                        const locations = LocationFilter.create(update);

                        // PATCH the analysis sidebar filter.
                        $.patch(state).filters({ locations });
                    },
                };
            },
            /**
             * Access to the weather station filter patchers.
             */
            get stations() {
                return {
                    /**
                     * Patch the existing weather station filter tree.
                     * @param {Readonly<Treeview.TreeProps>} payload
                     */
                    tree: (payload) => {
                        // GET current tree.
                        const current = state.filters.stations.tree;
                        const update = Tree.override(current, payload);

                        // UPDATE weather station filter.
                        const stations = WeatherStationFilter.create(update);

                        // PATCH the analysis sidebar filter.
                        $.patch(state).filters({ stations });
                    },
                };
            },
        };
    }

    /**
     * Provide access to state clear operations.
     * // TODO: Add other filter clearer mutations.
     * @param {AnalysisState} state State instance.
     */
    static clear(state) {
        const $ = AnalysisStateMutations;
        return {
            /**
             * Clear all filters.
             */
            filters: () => {
                $.clear(state).dates.range();
                $.clear(state).limits.record();
                $.clear(state).scales.record();
                $.clear(state).locations.tree();
                $.clear(state).stations.tree();
            },
            /**
             * Access to date range filter clearers.
             */
            get dates() {
                return {
                    /**
                     * Clear the filter.
                     */
                    filter: () => {
                        // Initialize the date range filter.
                        const end = endOfDay(Date.now());
                        const start = startOfDay(sub(end, { years: 1 }));
                        /** @type {IDateRangeFilter} Date range filter containing the start and end dates, as well as modifiers. */
                        const empty = new DateRangeFilter({
                            start: start.valueOf(),
                            end: end.valueOf(),
                            checked: [],
                        });
                        $.set(state).dates.filter(empty);
                    },
                    /**
                     * Clear the date range strings.
                     */
                    range: () => {
                        // Initialize the date range filter.
                        const end = endOfDay(Date.now());
                        const start = startOfDay(sub(end, { years: 1 }));
                        /** @type {IDateRangeFilter} Date range filter containing the start and end dates, as well as modifiers. */
                        const empty = new DateRangeFilter({
                            start: start.valueOf(),
                            end: end.valueOf(),
                            checked: [],
                        });
                        $.set(state).dates.range(empty);
                    },
                    /**
                     * Clear the start date.
                     */
                    start: () => {
                        const end =
                            state.filters.dates.end.valueOf() ??
                            endOfDay(Date.now());
                        const start = startOfDay(sub(end, { years: 1 }));
                        $.set(state).dates.start(start);
                    },
                    /**
                     * Clear the end date.
                     */
                    end: () => {
                        const end = endOfDay(Date.now());
                        $.set(state).dates.end(end);
                    },
                    /**
                     * Clear the modifiers.
                     */
                    modifiers: () => {
                        $.set(state).dates.modifiers([]);
                    },
                };
            },
            get limits() {
                return {
                    /**
                     * Clear the entire record.
                     */
                    record: () => {
                        const record = LimitFilterRecord.create([]);
                        $.set(state).limits.record(record);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    temp: () => {
                        const filter = LimitFilter.temp({});
                        $.set(state).limits.temp.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    rh: () => {
                        const filter = LimitFilter.rh({});
                        $.set(state).limits.rh.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    dp: () => {
                        const filter = LimitFilter.dp({});
                        $.set(state).limits.dp.filter(filter);
                    },
                };
            },
            get scales() {
                return {
                    /**
                     * Clear the entire record.
                     */
                    record: () => {
                        const record = ScaleFilterRecord.create([]);
                        $.set(state).scales.record(record);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    temp: () => {
                        const filter = ScaleFilter.temp({});
                        $.set(state).scales.temp.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    rh: () => {
                        const filter = ScaleFilter.rh({});
                        $.set(state).scales.rh.filter(filter);
                    },
                    /**
                     * Clear the specified filter.
                     */
                    dp: () => {
                        const filter = ScaleFilter.dp({});
                        $.set(state).scales.dp.filter(filter);
                    },
                };
            },
            get locations() {
                return {
                    /**
                     * Clear the entire filter.
                     */
                    tree: () => {
                        const tree = Tree.create({});
                        $.set(state).locations.tree(tree);
                    },
                };
            },
            get stations() {
                return {
                    /**
                     * Clear the entire filter.
                     */
                    tree: () => {
                        const tree = Tree.create({});
                        $.set(state).stations.tree(tree);
                    },
                };
            },
        };
    }

    // <!-- VUEX MUTATIONS -->

    /**
     * Access mutations for the {@link ISidebarFilterRecord}.
     */
    static get sidebar() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<ISidebarFilterRecord>} payload
         */
        const setFilters = (state, payload) => {
            $.set(state).filters(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<ISidebarFilterRecord>>} payload
         */
        const patchFilters = (state, payload) => {
            $.patch(state).filters(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearFilters = (state) => {
            $.clear(state).filters();
        };

        return {
            setFilters,
            patchFilters,
            clearFilters,
        };
    }

    /**
     * Access mutations for the {@link IDateRangeFilter}.
     */
    static get dates() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        // <!-- DATE RANGE -->

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<IInterval>} payload
         */
        const setDateRange = (state, payload) => {
            $.set(state).dates.range(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<IInterval>>} payload
         */
        const patchDateRange = (state, payload) => {
            $.patch(state).dates.range(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearDateRange = (state) => {
            $.clear(state).dates.range();
        };

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {IDate} payload
         */
        const setStartDate = (state, payload) => {
            $.set(state).dates.start(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearStartDate = (state) => {
            $.clear(state).dates.start();
        };

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {IDate} payload
         */
        const setEndDate = (state, payload) => {
            $.set(state).dates.end(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearEndDate = (state) => {
            $.clear(state).dates.end();
        };

        // <!-- MODIFIER -->

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Iterable<{ key: IDateRangeModifierID, value: Boolean }>} payload
         */
        const setDateRangeModifiers = (state, payload) => {
            for (const item of payload) {
                setDateRangeModifier(state, item);
            }
            return;
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearDateRangeModifiers = (state) => {
            $.set(state).dates.modifiers([]);
            return;
        };

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {{ key: IDateRangeModifierID, value: Boolean }} payload
         */
        const setDateRangeModifier = (state, payload) => {
            const { key, value } = payload;
            if (value === true) {
                const current = new Set(state.filters.dates.checked);
                const withValue = [...current.add(key)];
                $.set(state).dates.modifiers(withValue);
                return;
            }
            if (value === false) {
                const current = new Set(state.filters.dates.checked);
                current.delete(key);
                const withoutValue = [...current];
                $.set(state).dates.modifiers(withoutValue);
                return;
            }
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         * @param {IDateRangeModifierID} payload
         */
        const clearDateRangeModifier = (state, payload) => {
            setDateRangeModifier(state, { key: payload, value: false });
        };

        return {
            setDateRange,
            patchDateRange,
            clearDateRange,

            setStartDate,
            clearStartDate,

            setEndDate,
            clearEndDate,

            setDateRangeModifiers,
            clearDateRangeModifiers,

            setDateRangeModifier,
            clearDateRangeModifier,
        };
    }

    /**
     * Access mutations for the {@link ILimitFilterRecord}.
     */
    static get limits() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<ILimitFilterRecord>} payload
         */
        const setLimits = (state, payload) => {
            $.set(state).limits.record(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<ILimitFilterRecord>>} payload
         */
        const patchLimits = (state, payload) => {
            $.patch(state).limits.record(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearLimits = (state) => {
            $.clear(state).limits.record();
        };

        return {
            setLimits,
            patchLimits,
            clearLimits,
        };
    }

    /**
     * Access mutations for the {@link IScaleFilterRecord}.
     */
    static get scales() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<IScaleFilterRecord>} payload
         */
        const setScales = (state, payload) => {
            $.set(state).scales.record(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Partial<IScaleFilterRecord>>} payload
         */
        const patchScales = (state, payload) => {
            $.patch(state).scales.record(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearScales = (state) => {
            $.clear(state).scales.record();
        };

        return {
            setScales,
            patchScales,
            clearScales,
        };
    }

    /**
     * Access mutations for the {@link ILocationFilter}.
     */
    static get locations() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.Tree>} payload
         */
        const setLocationTree = (state, payload) => {
            $.set(state).locations.tree(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.TreeProps>} payload
         */
        const patchLocationTree = (state, payload) => {
            $.patch(state).locations.tree(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearLocationTree = (state) => {
            $.clear(state).locations.tree();
        };

        return {
            setLocationTree,
            patchLocationTree,
            clearLocationTree,
        };
    }

    /**
     * Access mutations for the {@link IWeatherStationFilter}.
     */
    static get stations() {
        /** Alias for the class. */
        const $ = AnalysisStateMutations;

        /**
         * Apply setter mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.Tree>} payload
         */
        const setWeatherStationTree = (state, payload) => {
            $.set(state).stations.tree(payload);
        };

        /**
         * Apply patcher mutation.
         * @param {AnalysisState} state
         * @param {Readonly<Treeview.TreeProps>} payload
         */
        const patchWeatherStationTree = (state, payload) => {
            $.patch(state).stations.tree(payload);
        };

        /**
         * Apply clear mutation.
         * @param {AnalysisState} state
         */
        const clearWeatherStationTree = (state) => {
            $.clear(state).stations.tree();
        };

        return {
            setWeatherStationTree,
            patchWeatherStationTree,
            clearWeatherStationTree,
        };
    }
}
