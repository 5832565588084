<template>
    <div
        :class="[
            'modal__panel-header',
            'border-solid border-transparent',
            'rounded-t-lg',
            {
                'border-b-2': !!border,
                'bg-white':
                    !isBlack &&
                    !isWhite &&
                    !isPrimary &&
                    !isSecondary &&
                    !isInfo &&
                    !isWarning &&
                    !isSuccess &&
                    !isFailure &&
                    !isDanger &&
                    !isError,
                'bg-black text-white border-white': isBlack,
                'bg-white text-black border-black': isWhite,
                'bg-white text-primary-700 border-primary-700': isPrimary,
                'bg-white text-secondary-700 border-secondary-700': isSecondary,
                'bg-blue-50 text-info border-info': isInfo,
                'bg-gray-800 text-warning border-warning': isWarning,
                'bg-green-50 text-success border-success': isSuccess,
                'bg-red-50 text-error border-error':
                    isFailure || isDanger || isError,
            },
        ]"
    >
        <!-- HEADER -->
        <div
            class="header__container p-4 flex flex-col flex-wrap sm:flex-row sm:justify-between sm:items-center gap-y-2"
        >
            <!-- INTRODUCTION -->
            <div
                class="header__introduction flex flex-row flex-wrap sm:flex-nowrap justify-center text-center items-center sm:justify-start sm:text-left"
            >
                <div
                    v-if="
                        !!$slots.icon ||
                        showInfoCircle ||
                        showWarningTriangle ||
                        showSuccessBadge ||
                        showFailureBadge ||
                        showErrorCircle ||
                        showDangerTriangle
                    "
                    title="This is an example icon."
                    class="m-0 mr-2 sm:block rounded-full"
                >
                    <slot
                        v-if="!!$slots.icon"
                        name="icon"
                    >
                        <!-- DEFAULT ICON (NONE) -->
                    </slot>
                    <div v-else-if="showInfoCircle">
                        <InformationCircleIcon
                            class="h-8 w-8 sm:h-6 sm:w-6 text-info"
                            aria-hidden
                        />
                    </div>
                    <div v-else-if="showWarningTriangle">
                        <ExclamationIcon
                            class="h-8 w-8 sm:h-6 sm:w-6 text-warning"
                            aria-hidden
                        />
                    </div>
                    <div v-else-if="showSuccessBadge">
                        <BadgeCheckIcon
                            class="h-8 w-8 sm:h-6 sm:w-6 text-success"
                            aria-hidden
                        />
                    </div>
                    <div v-else-if="showFailureBadge">
                        <XCircleIcon
                            class="h-8 w-8 sm:h-6 sm:w-6 text-error"
                            aria-hidden
                        />
                    </div>
                    <div v-else-if="showErrorCircle">
                        <ExclamationCircleIcon
                            class="h-8 w-8 sm:h-6 sm:w-6 text-error"
                            aria-hidden
                        />
                    </div>
                    <div v-else-if="showDangerTriangle">
                        <ExclamationIcon
                            class="h-8 w-8 sm:h-6 sm:w-6 text-error"
                            aria-hidden
                        />
                    </div>
                </div>
                <div class="flex flex-col">
                    <!-- TITLE -->
                    <slot v-if="$slots.title">
                        <!-- DEFAULT TITLE (SLOT) -->
                        <h3 class="text-lg">Example Title</h3>
                    </slot>
                    <h3 v-else-if="title?.length > 0">
                        <!-- DEFAULT TITLE (PROP) -->
                        <h3 class="text-lg">
                            {{ title }}
                        </h3>
                    </h3>
                    <!-- SUBTITLE -->
                    <slot v-if="$slots.subtitle">
                        <!-- DEFAULT SUBTITLE (SLOT) -->
                        <div class="text-sm">
                            This is the optional subtitle containing the modal's
                            description.
                        </div>
                    </slot>
                    <div v-else-if="subtitle?.length > 0">
                        <!-- DEFAULT SUBTITLE (PROP) -->
                        <div class="text-sm">
                            {{ subtitle }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- ACTIONS -->
            <ModalActions
                v-if="$slots.actions"
                class="header__actions sm:float-right"
            >
                <template #default>
                    <slot
                        name="actions"
                        :disabled="disabled"
                        :busy="busy"
                    >
                        <!-- DEFAULT ACTION -->
                        <ModalButton
                            type="button"
                            label="Close"
                            :disabled="disabled"
                            :busy="busy"
                            :theme="Theme.white"
                            @click.stop="$emit('close', $event)"
                        />
                    </slot>
                </template>
            </ModalActions>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef } from 'vue';

    // <!-- UTILITIES -->
    import { useEnum, Theme, ModalIcon } from '@/utils/enums';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import ModalActions from '@/components/modals/ModalActions.vue';
    import {
        InformationCircleIcon,
        ExclamationIcon,
        BadgeCheckIcon,
        XCircleIcon,
        ExclamationCircleIcon,
    } from '@heroicons/vue/solid';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalHeader',
        components: {
            ModalButton,
            ModalActions,
            InformationCircleIcon,
            ExclamationIcon,
            BadgeCheckIcon,
            XCircleIcon,
            ExclamationCircleIcon,
        },
        props: {
            /** Property used to specify the title text. Overriden when a slot is available. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: '',
            },
            /** Property used to specify the subtitle text. Overriden when a slot is available. */
            subtitle: {
                /** @type {V.PropType<String>} */
                type: String,
                default: '',
            },
            /**
             * Determine modal icon to use, if a slot isn't available.
             *
             * @example
             * ```
             * <ModalHeader :icon="null"  /> // No icon (or fallback to slot#icon).
             * <ModalHeader icon="warning" /> // Warning icon.
             * ```
             * */
            icon: {
                /** @type {V.PropType<ModalIcon[keyof ModalIcon] | null>} */
                // @ts-ignore
                type: String,
                default: null,
                validator: (value) =>
                    value === null ||
                    Object.values(ModalIcon).includes(
                        /** @type {ModalIcon[keyof ModalIcon]} */ (value)
                    ),
            },
            /**
             * Determine background color and text color using the {@link Theme} enum.
             * Use `null` to supply your own overrides in the `class` attribute.
             *
             * @example
             * ```
             * <ModalHeader :theme="null" class="bg-pink-400" /> // Override with custom theme.
             * <ModalHeader theme="success" /> // Success theme.
             * ```
             * */
            theme: {
                /** @type {V.PropType<Theme[keyof Theme] | null>} */
                // @ts-ignore
                type: String,
                default: Theme.white,
                validator: (value) =>
                    value === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme]} */ (value)
                    ),
            },
            /** Is the border visible? */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['close'],
        setup(props) {
            // <!-- DESTRUCTURE -->
            const theme = toRef(props, 'theme');
            const icon = toRef(props, 'icon');

            // <!-- CONDITIONALS -->
            const isTheme = useEnum(Theme, theme);
            const showIcon = useEnum(ModalIcon, icon);

            // <!-- EXPOSE -->
            return {
                // THEME
                Theme,
                isBlack: isTheme.black,
                isWhite: isTheme.white,
                isPrimary: isTheme.primary,
                isSecondary: isTheme.secondary,
                isInfo: isTheme.info,
                isWarning: isTheme.warning,
                isSuccess: isTheme.success,
                isFailure: isTheme.failure,
                isDanger: isTheme.danger,
                isError: isTheme.error,
                // ICON
                ModalIcon,
                showInfoCircle: showIcon.info,
                showWarningTriangle: showIcon.warning,
                showSuccessBadge: showIcon.success,
                showFailureBadge: showIcon.failure,
                showErrorCircle: showIcon.error,
                showDangerTriangle: showIcon.danger,
            };
        },
    });
</script>
