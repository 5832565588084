// <!-- API -->
import {
    SidebarFilterRecord,
    LimitFilterRecord,
    ScaleFilterRecord,
    DateRangeFilter,
    LimitFilter,
    ScaleFilter,
    LocationFilter,
    WeatherStationFilter,
} from '@/utils/filters';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';
import { startOfDay, endOfDay, sub } from 'date-fns';
import { Tree } from '@/utils/tree';

// <!-- TYPES -->
// ==== SIDEBAR ====
/** @typedef {import('@/utils/filters').IAxisID} IAxisID */
/** @typedef {import('@/utils/filters').ISidebarFilterRecord} ISidebarFilterRecord */
// ==== AXIS ====
/** @typedef {import('@/utils/filters').AxisExtent} AxisExtent */
/** @typedef {import('@/utils/filters').AxisRangeFilterPropertyKey} AxisRangeFilterPropertyKey */
/** @typedef {import('@/utils/filters').IAxisRange} IAxisRange */
/** @typedef {import('@/utils/filters').IAxisModifier} IAxisModifier */
/** @typedef {import('@/utils/filters').IAxisRangeFilter} IAxisRangeFilter */
// ==== DATES ====
/** @typedef {import('@/utils/filters').IDate} IDate A native {@link Date} instance or JavaScript {@link ITimestamp}. */
/** @typedef {import('@/utils/filters').ITimestamp} ITimestamp Instant in datetime specifing time (in milliseconds) since the JavaScript epoch. */
/** @typedef {import('@/utils/filters').IUnixTimestamp} IUnixTimestamp Instant in datetime specifing time (in seconds) since the Unix epoch */
/** @typedef {import('@/utils/filters').IInterval} IInterval An object that combines two dates to represent the time interval. */
/** @typedef {import('@/utils/filters').IDateRangeModifierID} IDateRangeModifierID */
/** @typedef {import('@/utils/filters').DateRangeFilterPropertyKey} DateRangeFilterPropertyKey */
/** @typedef {import('@/utils/filters').IDateRangeModifier} IDateRangeModifier */
/** @typedef {import('@/utils/filters').IDateRangeFilter} IDateRangeFilter */
// ==== LIMITS ====
/** @typedef {import('@/utils/filters').ILimitFilter} ILimitFilter */
/** @typedef {import('@/utils/filters').ILimitFilterRecord} ILimitFilterRecord */
// ==== SCALES ====
/** @typedef {import('@/utils/filters').IScaleFilter} IScaleFilter */
/** @typedef {import('@/utils/filters').IScaleFilterRecord} IScaleFilterRecord */

/**
 * @class
 * Analysis state.
 */
export class AnalysisState {
    /**
     * Determine if the state is namespaced?
     */
    static get namespaced() {
        return true;
    }

    /**
     * Instantiate the initial state object using this helper.
     * @returns {{ filters: ISidebarFilterRecord }}
     */
    static initialState() {
        /** End date initialized as end of current date. */
        const end = endOfDay(Date.now());

        /** Start date initialized as start of date exactly 12 months prior to the end date. */
        const start = startOfDay(sub(end, { years: 1 }));

        // Initialize the date range filter.
        /** @type {IDateRangeFilter} Date range filter containing the start and end dates, as well as modifiers. */
        const dates = new DateRangeFilter({
            start: start.valueOf(),
            end: end.valueOf(),
            checked: [],
        });

        // Initialize the limits filter record.
        const limits = new LimitFilterRecord({
            temp: LimitFilter.temp({
                lower: -Infinity,
                upper: Infinity,
                checked: false,
            }),
            rh: LimitFilter.rh({
                lower: -Infinity,
                upper: Infinity,
                checked: false,
            }),
            dp: LimitFilter.dp({
                lower: -Infinity,
                upper: Infinity,
                checked: false,
            }),
        });

        // Initialize the scales filter record.
        const scales = new ScaleFilterRecord({
            temp: ScaleFilter.temp({
                lower: NaN, // 20,
                upper: NaN, // 90,
                checked: false,
            }),
            rh: ScaleFilter.rh({
                lower: NaN, // 20,
                upper: NaN, // 90,
                checked: false,
            }),
            dp: ScaleFilter.dp({
                lower: NaN, // 10,
                upper: NaN, // 80,
                checked: false,
            }),
        });

        // Initialize the locations filter.
        const locationTree = Tree.create({});
        const locations = new LocationFilter({ tree: locationTree });

        // Initialize the stations filter.
        const weatherStationTree = Tree.create({});
        const stations = new WeatherStationFilter({ tree: weatherStationTree });

        // Initialize the filter record.
        /** @type {ISidebarFilterRecord} Record containing sidebar filters. */
        const filters = new SidebarFilterRecord({
            dates,
            limits,
            scales,
            locations,
            stations,
        });

        // <!-- EXPOSE -->
        return { filters };
    }

    /**
     * Construct initial state.
     */
    constructor() {
        // Prepare the initial state.
        const state = AnalysisState.initialState();

        // Assign the prepared state.
        /** @type {ISidebarFilterRecord} Analysis module's filters state. */
        this.filters = state.filters;
    }
}
