<template>
    <div
        v-if="!!$slots.default"
        :class="[
            'modal__panel-content',
            'border-solid border-transparent',
            {
                'border-b-2': !!border,
                'bg-white':
                    !isBlack &&
                    !isWhite &&
                    !isPrimary &&
                    !isSecondary &&
                    !isInfo &&
                    !isWarning &&
                    !isSuccess &&
                    !isFailure &&
                    !isDanger &&
                    !isError,
                'bg-black text-white border-white': isBlack,
                'bg-white text-black border-black': isWhite,
                'bg-white text-primary-700 border-primary-700': isPrimary,
                'bg-white text-secondary-700 border-secondary-700': isSecondary,
                'bg-blue-50 text-info border-info': isInfo,
                'bg-gray-800 text-warning border-warning': isWarning,
                'bg-green-50 text-success border-success': isSuccess,
                'bg-red-50 text-error border-error':
                    isFailure || isDanger || isError,
            },
        ]"
    >
        <div class="p-4 flex flex-col">
            <slot
                :disabled="disabled"
                :busy="busy"
            >
            </slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRef } from 'vue';

    // <!-- UTILITIES -->
    import { useEnum, Theme } from '@/utils/enums';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'ModalContent',
        props: {
            /**
             * Determine background color and text color using the {@link Theme} enum.
             * Use `null` to supply your own overrides in the `class` attribute.
             *
             * @example
             * ```
             * <ModalContent :theme="null" class="bg-pink-400" /> // Override with custom theme.
             * <ModalContent theme="success" /> // Success theme.
             * ```
             * */
            theme: {
                /** @type {V.PropType<Theme[keyof Theme] | null>} */
                // @ts-ignore
                type: String,
                default: Theme.white,
                validator: (value) =>
                    value === null ||
                    Object.values(Theme).includes(
                        /** @type {Theme[keyof Theme]} */ (value)
                    ),
            },
            /** Is the border visible? */
            border: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled. */
            disabled: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            /** If `true`, button actions are disabled and busy. */
            busy: {
                /** @type {V.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        setup(props) {
            // <!-- DESTRUCTURE -->
            const theme = toRef(props, 'theme');

            // <!-- CONDITIONALS -->
            const isTheme = useEnum(Theme, theme);

            // <!-- EXPOSE -->
            return {
                // THEME
                Theme,
                isBlack: isTheme.black,
                isWhite: isTheme.white,
                isPrimary: isTheme.primary,
                isSecondary: isTheme.secondary,
                isInfo: isTheme.info,
                isWarning: isTheme.warning,
                isSuccess: isTheme.success,
                isFailure: isTheme.failure,
                isDanger: isTheme.danger,
                isError: isTheme.error,
            };
        },
    });
</script>
