// <!-- API -->
import profiles from '@api/profile';
import accounts from '@api/accounts';
import AccountStateMutations from '@/store/types/accounts/mutations';

// <!-- TYPES -->
/** @template [S=any] @template [R=any] @typedef {import('vuex').Module<S,R>} Module<S,R> */
/** @template [S=any] @template [R=any] @typedef {import('vuex').ActionContext<S,R>} ActionContext<S,R> */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
import { AccountState } from '@/store/types/accounts/state';
import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';

// CLASS
/**
 * @class
 * Accounts module.
 */
export class AccountModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'accounts';
    }

    /**
     * Module state, getters, mutations, and actions.
     * @type {import('vuex').Module<AccountState, ECNBState>}
     */
    static get module() {
        return {
            namespaced: false,
            state: () => new AccountState(),
            get getters() {
                /** @param {AccountState} state */
                const hasSelectedAccount = (state) => {
                    return state.hasAccount;
                };
                /** @param {AccountState} state */
                const AccountID = (state) => {
                    return state.account.id;
                };
                /** @param {AccountState} state */
                const AccountTreeLevel = (state) => {
                    const labels = state.account.accountTreeLevel;
                    return {
                        labels,
                        site: labels[0],
                        building: labels[1],
                        floor: labels[2],
                        room: labels[3],
                    };
                };
                /** @param {AccountState} state */
                const AccountTimezone = (state) => {
                    return state.account.timezone;
                };
                return {
                    hasSelectedAccount,
                    AccountID,
                    AccountTreeLevel,
                    AccountTimezone,
                };
            },
            get mutations() {
                const $ = AccountStateMutations;
                /**
                 * Set the current account.
                 * @param {AccountState} state
                 * @param {AccountResource} payload
                 */
                const setCurrentAccount = (state, payload) => {
                    const key = String(payload.id);
                    sessionStorage.setItem('selected_account', key);
                    $.set(state).account(payload);
                };
                /**
                 * Update the current account.
                 * @param {AccountState} state
                 * @param {AccountResource} payload
                 */
                const patchCurrentAccount = (state, payload) => {
                    $.patch(state).account(payload);
                };
                /**
                 * Update the current account.
                 * @param {AccountState} state
                 */
                const clearCurrentAccount = (state) => {
                    sessionStorage.removeItem('selected_account');
                    $.clear(state).account();
                };
                return {
                    setCurrentAccount,
                    patchCurrentAccount,
                    clearCurrentAccount,
                };
            },
            get actions() {
                /**
                 * Fetch the selected account details.
                 * @param {import('vuex').ActionContext<AccountState, ECNBState>} context
                 * @param {Object} payload
                 * @param {Number} [payload.id] If `null`, use the session's selected account. Otherwise, assign account after fetching.
                 */
                const fetchSelectedAccount = async (
                    { commit, rootState },
                    { id }
                ) => {
                    /** @param {AccountResource} account */
                    const saveSelectedAccount = (account) => {
                        // Commit the account and update session storage.
                        commit('setCurrentAccount', account);
                    };
                    const currentUser = rootState.users.me;
                    const hasUser = !!currentUser;
                    const isAdmin = hasUser && currentUser?.role === 'admin';
                    if (isAdmin) {
                        // If user is an administrator...
                        // ...fetch the specified account resource...
                        const selectedAccount = await accounts.fetchAccountById(
                            { id }
                        );
                        // ...save the selected account details...
                        saveSelectedAccount(selectedAccount);
                        // ...and return the newly selected account.
                        return selectedAccount;
                    } else {
                        // If user is not an administrator...
                        // ...get the user's assigned accounts...
                        const userAccounts =
                            await accounts.fetchProfileAccounts();
                        // ...confirm account id exists in this list...
                        const selectedAccount = userAccounts.find(
                            (account) => account.id === id
                        );
                        if (!!selectedAccount) {
                            // If the user account is valid and exists, select it.
                            // ...save the selected account details...
                            saveSelectedAccount(selectedAccount);
                            return selectedAccount;
                        } else {
                            // No account is selected at this stage.
                            console.warn(
                                `User does not have permissions to select specified account [${id}]:`,
                                userAccounts
                            );
                            return;
                        }
                    }
                };
                return {
                    fetchSelectedAccount,
                };
            },
        };
    }
}

// DEFAULT
export default AccountModule;
