// <!-- TYPES -->
import { Model, FORMAT } from '@/models/Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<AccountLevel['toPayload']>} AccountLevelPayload */
/** @typedef {ReturnType<AccountLevel['toResource']>} AccountLevelResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys(
    /** @type {const} */ (['id', 'name', 'maxUsers', 'maxLocations'])
);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
    [FIELDS.maxUsers, 'users'],
    [FIELDS.maxLocations, 'datasets'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * Represents an institution account level.
 * @class
 */
export class AccountLevel extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    toResource() {
        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.name]: this.get(FIELDS.name),
            /** @type {Number} */
            [FIELDS.maxUsers]: this.get(FIELDS.maxUsers),
            /** @type {Number} */
            [FIELDS.maxLocations]: this.get(FIELDS.maxLocations),
        };
    }

    toPayload() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.get(FIELDS.id),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].name]: this.get(FIELDS.name),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].users]: this.get(FIELDS.maxUsers),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].datasets]: this.get(FIELDS.maxLocations),
        };
    }
}
