// <!-- UTILITIES -->
import clone from 'just-clone';
import { isString, isNumber, isSymbol, isPrimitive } from '@/utils/typeof'; //TODO: Replace with '@sindresorhus/is'

// <!-- TYPES -->//TODO: Enhance with global types.
/** @typedef {import('@/utils/enums').EnumKey} EnumKey */
/** @typedef {import('@/utils/enums').EnumValue} EnumValue */
/** @template {EnumKey} [K=EnumKey] @template {EnumValue} [V=EnumValue] @typedef {import('@/utils/enums').IEnumRecord<K,V>} IEnumRecord */
/** @template {EnumKey} [K=EnumKey] @template {EnumValue} [V=EnumValue] @typedef {import('@/utils/enums').IEnum<K,V>} IEnum */

// <!-- TYPE GUARD -->
/**
 * Type guard.
 * @template {EnumKey} [K=EnumKey]
 * @param {K} arg Argument to test.
 * @returns {arg is K} If `true` the type assert is valid.
 */
const isEnumKey = (arg) => isString(arg) || isNumber(arg) || isSymbol(arg);
/**
 * Type guard.
 * @template {EnumValue} [V=EnumValue]
 * @param {V} arg Argument to test.
 * @returns {arg is V} If `true` the type assert is valid.
 */
const isEnumValue = (arg) => isPrimitive(arg);
/**
 * Type guard.
 * @template {EnumKey} [K=EnumKey]
 * @template {EnumValue} [V=EnumValue]
 * @template {[ key: K, value: V ]} [T=[ key: K, value: V ]]
 * @param {T} arg
 * @returns {arg is T} If `true` the type assert is valid.
 */
const isEnumTuple = (arg) => {
    if (
        arg !== null &&
        arg !== undefined &&
        Array.isArray(arg) &&
        arg.length === 2
    ) {
        const [key, value] = arg;
        return isEnumKey(key) && isEnumValue(value);
    }
    // Not exactly a two-element array.
    return false;
};
/**
 * Type guard.
 * @template {EnumKey} [K=EnumKey]
 * @template {EnumValue} [V=EnumValue]
 * @template {IEnumRecord<K,V>} [R=IEnumRecord<K,V>]
 * @param {R} arg
 * @returns {arg is IEnumRecord<K,V>} If `true` the type assert is valid.
 */
const isEnumRecord = (arg) => {
    if (arg !== null && arg !== undefined && !isPrimitive(arg)) {
        const keys = Object.keys(arg);
        if (keys.length > 0) {
            for (const key of keys) {
                if (isEnumTuple([key, arg[key]])) {
                    // Valid pair. Continue checking.
                    continue;
                }
                // Invalid pair.
                return false;
            }
            // Loop resolved without error. Valid record.
            return true;
        }
        // Empty record.
        return false;
    }
    // Primitive or nil.
    return false;
};
// <!-- EXPORTS -->
/**
 * @template {EnumKey} [K=EnumKey]
 * @template {EnumValue} [V=EnumValue]
 * @param {IEnumRecord<K,V>} record Record containing enumerated values.
 * @returns {IEnum<K,V>}
 */
export const createEnum = (record) => {
    if (isEnumRecord(record)) {
        const instance = Object.freeze(clone(record));
        return instance;
    }
    // Error: invalid values type.
    throw new TypeError('Values must be a valid enum props instance.');
};

// <!-- DEFAULT -->
export default createEnum;
