// <!-- API -->
import { ECNBResourceIndexStateMutations } from '@/store/types/cache/mutations';

// <!-- UTILITIES -->
import omit from 'just-omit';
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
import { LocationHierarchyIndexState } from '@/store/types/cache/state';
import { LocationHierarchyResource } from '@/models/locations/LocationHierarchy';

/**
 * @class
 * Resource index state mutations.
 */
export class LocationHierarchyIndexStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @param {LocationHierarchyIndexState} state State instance.
     */
    static set(state) {
        const $ = LocationHierarchyIndexStateMutations;
        const $set = ECNBResourceIndexStateMutations.set(state);
        return {
            ...omit($set, 'index', 'resource'),
            /**
             * Directly assign provided payload to the existing property.
             * @param {Map<Number, LocationHierarchyResource> | null} [payload] Optional payload. Clears property cache if null.
             */
            hierarchies: (payload) => $set.index(payload),
            /**
             * Directly assign provided payload to the 'unassigned' attribute.
             * @param {Set<Number> | null} [payload] Optional payload. Clears unassigned locations set if null.
             */
            unassigned: (payload) => {
                if (isNil(payload) || payload.size === 0) {
                    $.clear(state).unassigned();
                } else {
                    const updated = state.attributes.set(
                        'unassigned',
                        payload ?? new Set()
                    );
                    $set.attributes(updated);
                }
            },
            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ id: Number, resource: LocationHierarchyResource } | null} [item] Optional payload. Drops item if null.
             */
            hierarchy: (item) => $set.resource(item),
            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ id: Number, value: Boolean } | null} [item] Optional payload. Drops item if null.
             */
            missing: (item) => {
                const { id, value } = item;
                if (isNil(id)) {
                    throw new TypeError(`No unassigned location ID provided.`);
                }
                if (isNil(value) || value === false) {
                    /** @type {Set<Number>} */
                    const updated =
                        state.attributes.get('unassigned') ?? new Set();
                    if (updated.delete(id)) {
                        $.set(state).unassigned(updated);
                    } else {
                        console.warn(
                            `No unassigned location with matching id '${id}' found. Nothing removed.`
                        );
                    }
                } else {
                    // Assign updated attributes.
                    /** @type {Set<Number>} */
                    const updated =
                        state.attributes.get('unassigned') ?? new Set();
                    updated.add(id);
                    $.set(state).unassigned(updated);
                }
            },
        };
    }

    /**
     * Provide access to clear state operations.
     * @param {LocationHierarchyIndexState} state State instance.
     */
    static clear(state) {
        const $drop = ECNBResourceIndexStateMutations.drop(state);
        const $clear = ECNBResourceIndexStateMutations.clear(state);
        return {
            ...omit($clear, 'index'),
            /**
             * Clear existing property.
             */
            hierarchies: () => $clear.index(),
            /**
             * Clear unassigned collection.
             */
            unassigned: () => $drop.attribute('unassigned'),
        };
    }

    /**
     * Provide access to state add operations.
     * @param {LocationHierarchyIndexState} state State instance.
     */
    static add(state) {
        const $ = LocationHierarchyIndexStateMutations;
        const $add = ECNBResourceIndexStateMutations.add(state);
        return {
            ...omit($add, 'index', 'resource'),
            /**
             * Merge provided payload with existing property.
             * @param {Map<Number, LocationHierarchyResource>} [payload]
             */
            hierarchies: (payload) => $add.index(payload),
            /**
             * Merge provided payload with existing property.
             * @param {Set<Number>} [payload]
             */
            unassigned: (payload) => {
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty unassigned locations payload. Nothing to add.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.add(state).missing(key);
                }
            },
            /**
             * Merge provided payload item with existing property collection.
             * @param {LocationHierarchyResource} [item] Resource.
             */
            hierarchy: (item) => $add.resource(item),
            /**
             * Merge provided payload item with existing property collection.
             * @param {Number} id
             */
            missing: (id) => {
                if (isNil(id)) {
                    console.warn(
                        `Missing unassigned location identifier. Nothing to add.`
                    );
                    return;
                }
                $.set(state).missing({ id, value: true });
            },
        };
    }

    /**
     * Provide access to state drop operations.
     * @param {LocationHierarchyIndexState} state State instance.
     */
    static drop(state) {
        const $ = LocationHierarchyIndexStateMutations;
        const $drop = ECNBResourceIndexStateMutations.drop(state);
        return {
            ...omit($drop, 'index', 'resource'),
            /**
             * Seperate provided payload with existing property.
             * @param {Set<Number> | 'all'} [payload]
             */
            hierarchies: (payload) => $drop.index(payload),
            /**
             * Seperate provided payload with existing property.
             * @param {Set<Number> | 'all'} [payload]
             */
            unassigned: (payload) => {
                if (payload === 'all') {
                    $.clear(state).unassigned();
                    return;
                }
                if (isNil(payload) || payload.size <= 0) {
                    console.warn(
                        `Missing or empty unassigned locations payload. Nothing to drop.`
                    );
                    return;
                }
                for (const key of payload) {
                    $.drop(state).missing(key);
                }
            },
            /**
             * Drop provided payload item from existing property collection.
             * @param {Number} id
             */
            hierarchy: (id) => $drop.resource(id),
            /**
             * Drop provided payload item from existing property collection.
             * @param {Number} id
             */
            missing: (id) => {
                if (isNil(id)) {
                    console.warn(
                        `Missing unassigned location identifier. Nothing to drop.`
                    );
                    return;
                }
                $.set(state).missing({ id, value: false });
            },
        };
    }
}
