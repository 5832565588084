// <!-- FORWARDING EXPORTS -->
export { createUser } from '@/api/users/createUser';
export { updateUserById } from '@/api/users/updateUserById';
export { deleteUserById } from '@/api/users/deleteUserById';
export { resetUserPassword } from '@/api/users/resetUserPassword';

// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- API -->
import { createUser } from '@/api/users/createUser';
import { updateUserById } from '@/api/users/updateUserById';
import { deleteUserById } from '@/api/users/deleteUserById';
import { resetUserPassword } from '@/api/users/resetUserPassword';

// <!-- MODELS -->
import { Accounts, Users } from '@/models';

// <!-- TYPES -->
/** @typedef {import('@/api').ChangePasswordRequest} ChangePasswordRequest */
/** @typedef {import('@/api').ChangePasswordResponse} ChangePasswordResponse */
/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */
// ==== MODELS ====
/** @typedef {import('@/models/accounts/Account').AccountPayload} AccountPayload */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/users/User').UserPayload} UserPayload */
/** @typedef {import('@/models/users/User').UserResource} UserResource */
// ==== EXCEPTIONS ====
/**
 * @typedef {Object} IRequestException
 * @property {String} id Exception class name.
 * @property {String | Number} code Exception code string or number.
 * @property {String} message Exception message.
 */
// ==== RESULTS ====
/**
 * @typedef {Object} IResponseResult
 * @property {Number} status HTTP status code.
 * @property {UserResource} [user] User, if created successfully.
 * @property {String} label Primary message associated with a response.
 * @property {String[]} [messages] Success messages.
 * @property {String[]} [warnings] Warning messages.
 * @property {String[]} [errors] Error messages.
 */

// <!-- ROUTES -->
const ROUTES = {
    // <!-- CRUD -->
    GET_USERS: () => 'users',
    GET_USER_ACCOUNTS: (user) => `users/${user}/accounts`,
    CREATE_USER: () => 'users',
    GET_USER: (user) => `users/${user}`,
    UPDATE_USER: (user) => `users/${user}`,
    DELETE_USER: (user) => `users/${user}`,
    // <!-- SERVICES -->
    CHANGE_PASSWORD: (user) => `users/${user}/password`,
};

// <!-- REQUESTS -->

/**
 * Fetch all institution accounts.
 * - // XXX: This endpoint can take upwards of 30 seconds to resolve, even locally. Defer to other endpoints until this is resolved.
 * - // TODO: Fix the time out issue / paginate this endpoint.
 * @returns {Promise<UserResource[]>}
 */
export const fetchUsers = async () => {
    /** @type {Pick<AxiosResponse<UserResource>, 'data'>} */
    const response = await axios().get(ROUTES.GET_USERS());
    const collection = response.data.data;
    return collection.map((user) =>
        new Users.User({ payload: user }).toResource()
    );
};

/**
 * Fetch accounts associated with the specified user.
 * @param {Pick<UserResource, 'id'>} user
 * @returns {Promise<AccountResource[]>}
 */
const fetchUserAccounts = async (user = { id: 9800 }) => {
    /** @type {import('axios').AxiosResponse<{ data: AccountPayload[] }>} */
    const response = await axios().get(ROUTES.GET_USER_ACCOUNTS(user.id));
    const collection = response.data.data;
    return collection.map((payload) =>
        new Accounts.Account({ payload }).toResource()
    );
};

/**
 * Fetch accounts associated with the specified user.
 * @param {Pick<UserResource, 'id'>} user
 * @returns {Promise<UserResource>}
 */
export const fetchUserById = async (user = { id: 9800 }) => {
    /** @type {import('axios').AxiosResponse<{ user: UserPayload }>} */
    const response = await axios().get(ROUTES.GET_USER(user.id));
    const payload = response.data.user;
    return new Users.User({ payload }).toResource();
};

/**
 * Change the password for the specified user.
 * @param {Pick<UserResource, 'id'>} user
 * @param {ChangePasswordRequest} request
 */
const changeUserPassword = async (
    user = { id: null },
    request = { old_password: 'password', new_password: 'password' }
) => {
    /** @type {import('axios').AxiosResponse<ChangePasswordResponse, ChangePasswordRequest>} */
    const response = await axios().patch(
        ROUTES.CHANGE_PASSWORD(user.id),
        request
    );
    const { status, message } = response.data;
    return { status, message };
};

// <!-- EXPORTS -->
export default {
    User: Users.User,
    fetchUsers,
    fetchUserAccounts,
    fetchUserById,
    createUser,
    updateUserById,
    deleteUserById,
    changeUserPassword,
    resetUserPassword,
};
