// <!-- API -->
import { fetchNARAStandards } from '@/api/standards';
import { NARAStandardIndexState } from '@/store/types/cache/state';
import { Assertion } from '@/store/types/ECNBAssertions';

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { ECNBResourceCacheModule } from '@/store/types/cache/module/ECNBResourceCacheModule';
/** @typedef {import('@/models/standards/NARAStandard').NARAStandardResource} NARAStandardResource */

/**
 * @class
 * Cache resource module.
 */
export class NARAStandardCacheModule extends ECNBResourceCacheModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'cache/standards';
    }

    /**
     * Send fetch request for an index of resources.
     *
     * @returns {Promise<Map<Number, NARAStandardResource>>}
     */
    static async fetchIndex() {
        const resources = await fetchNARAStandards();
        /** @type {Map<Number, NARAStandardResource>} */
        const index = new Map();
        for (const standard of resources) {
            index.set(standard.id, standard);
        }
        return index;
    }

    /**
     * Send fetch request for a single resource.
     *
     * @param {Number} id
     * @returns {Promise<NARAStandardResource>}
     */
    static async fetchResource(id) {
        const request = { id };
        const resource = (await fetchNARAStandards()).find(
            (standard) => standard.id === request.id
        );
        return resource;
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        const $ = NARAStandardCacheModule;
        const $module = ECNBResourceCacheModule.module;
        return {
            ...$module,
            // OVERRIDES
            state: () => new NARAStandardIndexState(),
            get actions() {
                /**
                 * Module resource fetchers.
                 */
                const fetchers = {
                    /**
                     * Fetch and cache index.
                     * @param {import('vuex').ActionContext<NARAStandardIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Boolean} payload.ignoreCache If `true`, force cache refresh from remote.
                     * @returns {Promise<Map<Number, NARAStandardResource>>}
                     */
                    fetchIndex: async (context, payload) => {
                        const { state } = context;
                        const { ignoreCache = false } = payload ?? {};
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        const $invoke = $use.invoke; // Local action dispatcher.
                        // <!-- FETCHING -->
                        try {
                            // Fetch new index and cache it before selecting the index,
                            //   if ignoreCache is true or if the state is empty.

                            if (ignoreCache === true || state.is.empty) {
                                // Enable fetching status.
                                $mutate('addStatus').withPayload('fetching');
                                // Call the axios API endpoint. (Override in child classes).
                                const index = await $.fetchIndex();
                                // Ensure non-null response.
                                await Assertion.expect(index).isNotNil();
                                // Overwrite the index.
                                await $invoke('cacheIndex')
                                    .withPayload({
                                        index,
                                        append: false,
                                    })
                                    .dispatch();
                                // Warn if still empty after fetching and caching.
                                if (state.is.empty) {
                                    console.warn(`Index empty after fetching.`);
                                }
                            }
                        } catch (error) {
                            console.dir(error);
                        } finally {
                            // Disable fetching status.
                            $mutate('dropStatus').withPayload('fetching');
                        }

                        // <!-- SELECTING -->
                        // Return the index map.
                        return state.index;
                    },
                    /**
                     * Fetch and cache resource.
                     * @param {import('vuex').ActionContext<NARAStandardIndexState, ECNBState>} context
                     * @param {Object} payload
                     * @param {Number} payload.id
                     * @param {NARAStandardResource} [payload.defaultValue]
                     * @param {Boolean} [payload.ignoreCache]
                     * @returns {Promise<NARAStandardResource>}
                     */
                    fetchResource: async (context, payload) => {
                        const { state } = context;
                        const {
                            id,
                            ignoreCache = false,
                            defaultValue = null,
                        } = payload ?? {};
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        const $invoke = $use.invoke; // Local action dispatcher.
                        await Assertion.expect(id).isNotNil();

                        try {
                            // <!-- FETCHING -->
                            // Fetch resource and cache it if:
                            // - ignoreCache is `true`, or
                            // - state index is empty, or
                            // - state index is missing this specific resource already.
                            if (
                                ignoreCache === true ||
                                state.is.empty ||
                                !state.has.resource(id)
                            ) {
                                // Start fetching status.
                                $mutate('addStatus').withPayload('fetching');
                                // Call the axios API endpoint. (Override in child classes).
                                const resource = await $.fetchResource(id);
                                // Ensure non-null response.
                                await Assertion.expect(resource).isNotNil();
                                // Cache the index.
                                await $invoke('cacheResource')
                                    .withPayload({
                                        resource,
                                    })
                                    .dispatch();
                                // Warn if still empty after fetching and caching.
                                if (state.is.empty || !state.has.resource(id)) {
                                    console.warn(`Resource not found.`);
                                }
                            }
                        } catch (error) {
                            console.dir(error);
                        } finally {
                            $mutate('dropStatus').withPayload('fetching');
                        }

                        // <!-- SELECTING -->
                        // Return the resource.
                        return state.select(id, defaultValue);
                    },
                };
                return {
                    ...$module.actions,
                    ...fetchers,
                    // EXTENSIONS.
                    /**
                     * Fetch and cache index.
                     * @param {import('vuex').ActionContext<NARAStandardIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Boolean} payload.ignoreCache If `true`, force cache refresh from remote.
                     * @returns {Promise<Map<Number, NARAStandardResource>>}
                     */
                    fetchNARAStandards: fetchers.fetchIndex,
                    /**
                     * Fetch and cache resource.
                     * @param {import('vuex').ActionContext<NARAStandardIndexState, ECNBState>} context
                     * @param {Object} payload
                     * @param {Number} payload.id
                     * @param {NARAStandardResource} [payload.defaultValue]
                     * @param {Boolean} [payload.ignoreCache]
                     * @returns {Promise<NARAStandardResource>}
                     */
                    fetchNARAStandard: fetchers.fetchResource,
                };
            },
        };
    }
}
