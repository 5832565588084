<template>
    <Comment>eClimatenotebook - {{ environment }}</Comment>
    <div class="min-h-screen min-w-screen flex flex-col">
        <MainNav
            id="ecnb-navigation"
            class="flex-none"
        ></MainNav>
        <Content
            id="ecnb-content"
            class="flex-auto"
        ></Content>
        <Footer
            id="ecnb-footer"
            class="flex-auto"
        ></Footer>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';

    // <!-- COMPONENTS -->
    import MainNav from '@/features/layout/MainNav.vue';
    import Content from '@/features/layout/Content.vue';
    import Footer from '@/features/layout/Footer.vue';
    import { Comment } from '@/components/vnodes';

    // <!-- UTILITIES -->
    import { Emoji } from '@/utils/emoji';

    // <!-- STYLES -->
    import 'vue3-treeview/dist/style.css';

    /** ag-grid Alpine Theme */
    import 'ag-grid-community/dist/styles/ag-grid.css';
    import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'App',
        components: {
            MainNav,
            Content,
            Footer,
            Comment,
        },
        setup() {
            // ENV
            const environment = process.env.NODE_ENV ?? 'unknown';

            // LOGGER for mount times.
            const mountTimer = `[app::mount] - ${Emoji.checkmark} App mounted successfully!`;
            console.time(mountTimer);

            // LIFECYCLE
            onMounted(async () => {
                // Stop timer.
                console.timeEnd(mountTimer);
            });

            // Notes the environment.
            return {
                environment,
            };
        },
    });
</script>

<style lang="scss">
    html,
    body {
        min-height: 100vh;
    }

    #app {
        min-height: 100vh;
    }

    /* we will explain what these classes do next! */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    input[type='checkbox'].node-checkbox {
        display: none;
    }

    .checkbox-wrapper.checked {
        background-color: #13538a;
        border-color: #13538a;
    }

    .checkbox-wrapper.checked::after {
        transform: translate(0.15em, 0.3em) rotate(-45deg);
        // Note: Border is used to create the checkbox itself!
        width: 0.7em;
        height: 0.3em;
        border: 0.125em solid #fff;
        border-top-style: none;
        border-right-style: none;
    }

    .checkbox-wrapper.indeterminate {
        background-color: #13538a;
        border-color: #13538a;
    }

    .checkbox-wrapper.indeterminate::after {
        transform: translate(0.15em, 0.25em) rotate(0);
        width: 0.7em;
        height: 0.3em;
        border: 0.125em solid #fff;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
    }

    .ag-body-horizontal-scroll-viewport {
        padding-top: 5px;
        padding-bottom: 5px;
    }
</style>
