// <!-- MODELS -->
import { Labels } from '@/models';

// <!-- ROUTES -->
const ROUTES = {
    GET_LABELS: (account) => `accounts/${account}/labels`,
    GET_LABEL: (account, label) => `accounts/${account}/labels/${label}`,
    CREATE_LABEL: (account) => `accounts/${account}/labels`,
    UPDATE_LABEL: (account, label) => `accounts/${account}/labels/${label}`,
    DELETE_LABEL: (account, label) => `accounts/${account}/labels/${label}`,
};

// <!-- REQUESTS -->

// <!-- EXPORTS -->
export default {
    Label: Labels.Label,
    // fetchLabels,
    // fetchLabelById,
    // createLabel,
    // updateLabelById,
    // deleteLabelById,
};
