// <!-- TYPES -->
import { Model, FORMAT } from '@/models/Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<Label['toPayload']>} LabelPayload */
/** @typedef {ReturnType<Label['toResource']>} LabelResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'title',
    'description',
    'color',
    'notesCount',
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs([
    [FIELDS.notesCount, 'notes_count'],
]);

/**
 * @class
 */
export class Label extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable([
            [
                KEYS[FORMAT.RESOURCE].notesCount,
                KEYS[FORMAT.PAYLOAD].notes_count,
            ],
        ]);
    }

    toPayload() {
        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.title]: this.get(FIELDS.title),
            /** @type {String} */
            [FIELDS.description]: this.get(FIELDS.description),
            /** @type {String} */
            [FIELDS.color]: this.get(FIELDS.color),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].notes_count]: this.get(FIELDS.notesCount),
        };
    }

    toResource() {
        return {
            /** @type {Number} */
            [FIELDS.id]: this.get(FIELDS.id),
            /** @type {String} */
            [FIELDS.title]: this.get(FIELDS.title),
            /** @type {String} */
            [FIELDS.description]: this.get(FIELDS.description),
            /** @type {String} */
            [FIELDS.color]: this.get(FIELDS.color),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].notesCount]: this.get(FIELDS.notesCount),
        };
    }
}
