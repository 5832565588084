// <!-- API -->
import { AnalysisStateGetters } from '@/store/types/analysis/getters';
import { AnalysisStateMutations } from '@/store/types/analysis/mutations';
import { AnalysisStateActions } from '@/store/types/analysis/actions';

// <!-- TYPES -->
import ECNBModule from '@/store/types/ECNBModule';
import { AnalysisState } from '@/store/types/analysis/state';

// CLASS
/**
 * @class
 * Analysis module.
 */
export class AnalysisModule extends ECNBModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'analysis';
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        // EXPOSE
        return {
            namespaced: true,
            state: () => new AnalysisState(),
            get getters() {
                const $getters = AnalysisStateGetters;
                return {
                    ...$getters.dates,
                    ...$getters.limits,
                    ...$getters.scales,
                    ...$getters.locations,
                    ...$getters.stations,
                };
            },
            get mutations() {
                const $mutations = AnalysisStateMutations;
                return {
                    ...$mutations.sidebar,
                    ...$mutations.dates,
                    ...$mutations.limits,
                    ...$mutations.scales,
                    ...$mutations.locations,
                    ...$mutations.stations,
                };
            },
            get actions() {
                const $actions = AnalysisStateActions;
                return {
                    ...$actions.dates,
                    ...$actions.limits,
                    ...$actions.scales,
                    ...$actions.locations,
                    ...$actions.stations,
                };
            },
        };
    }
}

// DEFAULT
export default AnalysisModule;
