// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- TYPES -->
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * @typedef {Object} MappingProfileMetadataRequest
 * @property {LocationResource['id']} location_id Location identifier.
 * @property {String} filename Filename to get suggestions with.
 */

/**
 * @typedef {Object} MappingProfileMetadataResponse
 * @property {{ [location: Number]: { [filename: String]: Number | null } }} suggested_mappings Location identifier.
 */

// <!-- MODELS -->
import { Mappings } from '@/models';

// <!-- ROUTES -->
const ROUTES = {
    GET_MAPPINGS: (account) => `accounts/${account}/mappings`,
    GET_MAPPING: (account, mapping) =>
        `accounts/${account}/mappings/${mapping}`,
    CREATE_MAPPING: (account) => `accounts/${account}/mappings`,
    UPDATE_MAPPING: (account, mapping) =>
        `accounts/${account}/mappings/${mapping}`,
    DELETE_MAPPING: (account, mapping) =>
        `accounts/${account}/mappings/${mapping}`,
    SUGGEST_MAPPINGS: (account) => `accounts/${account}/mappings/metadata`,
};

// <!-- REQUESTS -->
/**
 * Fetch all mapping profiles associated with an institution account.
 * @param {Pick<AccountResource, 'id'>} account
 */
export const fetchMappingProfiles = async (account = { id: 8 }) => {
    /** @type {import('axios').AxiosResponse<{ data: import('@/models/mappings/MappingProfile').MappingProfilePayload[] }>} */
    const response = await axios().get(ROUTES.GET_MAPPINGS(account.id));
    const collection = response.data.data;
    return collection.map((mapping) =>
        new Mappings.MappingProfile({ payload: mapping }).toResource()
    );
};

/**
 * Fetch specified mapping profile by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<import('@/models/mappings/MappingProfile').MappingProfileResource, 'id'>} mapping
 */
export const fetchMappingProfileById = async (
    account = { id: 8 },
    mapping = { id: 1 }
) => {
    /** @type {import('axios').AxiosResponse<{ mapping: import('@/models/mappings/MappingProfile').MappingProfilePayload }>} */
    const response = await axios().get(
        ROUTES.GET_MAPPING(account.id, mapping.id)
    );
    const payload = response.data.mapping;
    return new Mappings.MappingProfile({ payload }).toResource();
};

/**
 * Create new mapping profile using request body content.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Partial<Omit<import('@/models/mappings/MappingProfile').MappingProfilePayload, 'id' | 'account_id' | 'created_at' | 'created_by'>>} definition
 */
export const createMappingProfile = async (
    account = { id: 8 },
    definition = {
        name: 'Mapping Profile (New)',
        settings: new Mappings.MappingSettings({
            entries: [
                ['limit', 10],
                ['offset', 11],
                ['headers', false],
                ['delimiter', ','],
            ],
        }).toPayload(),
        rules: [
            new Mappings.MappingRule({
                entries: [
                    ['index', 4],
                    ['format', 'C'],
                    ['target', 'dp'],
                ],
            }).toPayload(),
            new Mappings.MappingRule({
                entries: [
                    ['index', 3],
                    ['format', '%'],
                    ['target', 'rh'],
                ],
            }).toPayload(),
            new Mappings.MappingRule({
                entries: [
                    ['index', 1],
                    ['format', 'yyyy-MM-dd hh:mm:ss'],
                    ['target', 'date'],
                ],
            }).toPayload(),
            new Mappings.MappingRule({
                entries: [
                    ['index', 2],
                    ['format', 'C'],
                    ['target', 'temp'],
                ],
            }).toPayload(),
            new Mappings.MappingRule({
                entries: [
                    ['index', 1],
                    ['format', 'yyyy-MM-dd hh:mm:ss'],
                    ['target', 'time'],
                ],
            }).toPayload(),
        ],
    }
) => {
    // Get the composite ruleset.
    const rules = definition.rules.reduce((dict, next) => {
        return {
            ...dict,
            ...next,
        };
    }, {});

    // Get the targets.
    // const targets = Object.keys(rules);

    // Create request.
    const request = {
        name: definition.name,
        settings: definition.settings,
        rules,
        // rules: targets.map((t) => rules[t]),
    };

    /** @type {import('axios').AxiosResponse<import('@/api').StatusResponse<{ mapping: import('@/models/mappings/MappingProfile').MappingProfilePayload }>, import('@/models/mappings/MappingProfile').MappingProfilePayload>} */
    const response = await axios().post(
        ROUTES.CREATE_MAPPING(account.id),
        request
    );
    const { status, ...payload } = response.data.mapping;
    return new Mappings.MappingProfile({ payload }).toResource();
};

/**
 * Fetch the suggested profiles.
 *
 * @param {Pick<AccountResource, 'id'>} account
 * @param {{ location: Number, filename: String }[]} requests Collection of request objects to suggest profiles for.
 */
export const suggestProfiles = async (
    account = { id: 8 },
    requests = [{ location: -1, filename: '' }]
) => {
    /** @type {MappingProfileMetadataRequest[]} */
    const payload = requests.map((request) => ({
        location_id: request.location,
        filename: request.filename,
    }));

    /** @type {import('axios').AxiosResponse<{ metadata: MappingProfileMetadataResponse }, MappingProfileMetadataRequest[]>} */
    const response = await axios().post(ROUTES.SUGGEST_MAPPINGS(account.id), {
        request: payload,
    });

    /** @type {(location_suggestions: Record<Number, Record<String, Number>>, location: Number) => Map<String, Number>} */
    const getScopedSuggestions = (location_suggestions, location) => {
        const filename_suggestions = location_suggestions[location];
        const entries = Object.entries(filename_suggestions).filter(
            ([filename, id]) =>
                !!filename && filename.length > 0 && id !== null && id > -1
        );
        return new Map(entries);
    };

    /** @type {(suggested_mappings: Record<Number, Record<String, Number>>) => Map<Number, Map<String, Number>>} */
    const getAllSuggestions = (suggested_mappings) => {
        /** @type {Set<Number>} */
        const locations = new Set(
            Object.keys(suggested_mappings)
                .map((key) => Number(key))
                .filter((key) => !isNaN(key))
        );

        /** @type {([ location: Number, suggestions: Map<String, Number> ])[]} */
        const location_suggestions = [...locations].map((location) => {
            /** @type {[ location: Number, suggestions: Map<String, Number> ]} */
            const entry = [
                location,
                getScopedSuggestions(suggested_mappings, location),
            ];
            return entry;
        });

        // Create the map from the individual entries.
        return new Map(location_suggestions);
    };

    // Get the suggested mappings from the response.
    const { suggested_mappings } = response.data.metadata;
    const collection = getAllSuggestions(suggested_mappings);
    return collection;
};

// <!-- EXPORTS -->
export default {
    MappingProfile: Mappings.MappingProfile,
    MappingRule: Mappings.MappingRule,
    MappingSettings: Mappings.MappingSettings,
    fetchMappingProfiles,
    fetchMappingProfileById,
    createMappingProfile,
    suggestProfiles,
    // updateMappingProfileById,
    // deleteMappingProfileById,
};
