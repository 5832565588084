// <!-- UTILITIES -->
import { Unit } from '@/utils/enums';
import { formatUnit } from '@/utils/formatters';

// <!-- TYPES -->
/** @typedef {import('@/utils/formatters').IDecimalFormatter} IDecimalFormatter */

// <!-- EXPORTS -->
/**
 * @type {IDecimalFormatter} Formats decimal according to the specified options. Set options.minimumFractionDigits and options.maximumFractionDigits to affect presence of decimals.
 *
 * @example
 * ```
 * formatPercent({ value: 12.3 }); // 12.3 ==> "12.3%"
 * formatPercent({ value: 12.3, options: { minimumFractionDigits: 2, maximumFractionDigits: 2 } }); // 12.3 ==> "12.30%"
 * formatPercent({ value: 12.3, options: { minimumFractionDigits: 0, maximumFractionDigits: 0 } }); // 12.3 ==> "12%"
 * ```
 */
export const formatPercent = ({ value, options }) =>
    formatUnit({ value, options: { ...options, unit: Unit.Percent } });

// <!-- DEFAULT -->
export default formatPercent;
