// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const Unit = createEnum({
    None: '',
    Unknown: '?',
    Percent: '%',
    Fahrenheit: '°F',
    Celsius: '°C',
});

/**
 * Type guard for enum key.
 * @template {keyof Unit} [K=keyof Unit]
 * @param {K} target
 * @param {keyof Unit} key
 * @returns {key is K}
 */
export const isUnitKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {Unit[keyof Unit]} [V=Unit[keyof Unit]]
 * @param {V} target
 * @param {Unit[keyof Unit]} value
 * @returns {value is V}
 */
export const isUnit = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default Unit;
