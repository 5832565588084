// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- MODELS -->
import { User } from '@/models/users/User';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
/** @typedef {import('@/api/users').IRequestException} IRequestException */
/** @typedef {import('@/api/users').IResponseResult} IResponseResult */
// ==== MODELS ====
/** @typedef {import('@/models/accounts/Account').AccountPayload} AccountPayload */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/users/User').UserPayload} UserPayload */
/** @typedef {import('@/models/users/User').UserResource} UserResource */
// ==== REQUESTS ====
/** @typedef {Readonly<Pick<UserPayload, 'user_name' | 'email' | 'role'> & Partial<Pick<UserPayload, 'first_name' | 'last_name' | 'accounts'>>>} ICreateUserRequest */
// ==== RESPONSES ====
/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */
/** @typedef {AxiosResponse<ICreateUserSuccessData, ICreateUserRequest>} ICreateUserSuccessResponse */
/** @typedef {AxiosResponse<ICreateUserSuccessWithExceptionData, ICreateUserRequest>} ICreateUserSuccessWithExceptionResponse */
/** @typedef {AxiosResponse<ICreateUserDatabaseErrorData, ICreateUserRequest>} ICreateUserDatabaseErrorResponse */
/** @typedef {AxiosResponse<ICreateUserValidationErrorData, ICreateUserRequest>} ICreateUserValidationErrorResponse */
/** @typedef {ICreateUserSuccessResponse | ICreateUserSuccessWithExceptionResponse | ICreateUserDatabaseErrorResponse | ICreateUserValidationErrorResponse} ICreateUserResponse */
// ==== DATA ====
/** @typedef {ICreateUserSuccessData | ICreateUserSuccessWithExceptionData | ICreateUserDatabaseErrorData | ICreateUserValidationErrorData} ICreateUserData */
/**
 * @typedef {Object} ICreateUserSuccessData
 * @property {"created"} status Response status type.
 * @property {UserPayload} user User resource.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} ICreateUserSuccessWithExceptionData
 * @property {"created"} status Response status type.
 * @property {UserPayload} user User resource.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} ICreateUserDatabaseErrorData
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} ICreateUserValidationErrorData
 * @property {String} message Human-readable message.
 * @property {Record<String, Array<String>>} errors Record containing properties and the corresponding validation errors. Not present when user is created.
 */

// <!-- ROUTES -->
/** @typedef {Object} */
const ROUTES = {
    CREATE_USER: () => `users`,
};

// <!-- METHODS -->
/**
 * Create new user using request body content.
 * @param {ICreateUserRequest} request
 * @returns {Promise<IResponseResult>}
 */
export const createUser = async (request) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is ICreateUserResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {ICreateUserResponse} response
     * @returns {response is ({ status: 200 } & ICreateUserSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'created' &&
            !isNil(response.data.user)
        );
    };

    /**
     * @param {ICreateUserResponse} response
     * @returns {response is ({ status: 200 } & ICreateUserSuccessWithExceptionResponse)}
     */
    const isSuccessResponseWithException = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'created' &&
            !isNil(response.data.user)
        );
    };

    /**
     * @param {ICreateUserResponse} response
     * @returns {response is ({ status: 422 | 400 | 500 } & ICreateUserDatabaseErrorResponse )}
     */
    const isDatabaseErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {ICreateUserResponse} response
     * @returns {response is ({ status: 422 } & ICreateUserValidationErrorResponse )}
     */
    const isValidationErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'errors' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {ICreateUserResponse} response
     */
    const handleResponse = (response) => {
        /** @type {IResponseResult} */
        const result = {
            status: 500,
            user: null,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Assign the user resource.
        if ('user' in response.data) {
            // Assign user resource to the result.
            const payload = response.data.user;
            result.user = new User({ payload }).toResource();
        }
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponseWithException(response)) {
                    // If response (with mail server exception)...
                    // ...append mail server exception message.
                    result.label = 'User created successfully (with warnings)';
                    result.warnings = [
                        `Failed to send password reset email to <${result.user.email}>.`,
                        `Contact your system administrator to manually reset the user's password.`,
                    ];
                    return result;
                } else if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append additional messages.
                    result.messages = [
                        `Password reset email sent to <${result.user.email}>`,
                    ];
                    return result;
                }
            case 422:
                if (isValidationErrorResponse(response)) {
                    // If response (with validation errors)...
                    const { data } = response;
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(data.errors);
                    const violations = rules.flatMap(
                        (rule) => data.errors[rule]
                    );
                    result.errors = violations;
                    return result;
                } else if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            case 500:
                if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {ICreateUserResponse} Response. */
        const response = await axios().post(ROUTES.CREATE_USER(), request);
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

// <!-- DEFAULT -->
export default createUser;
