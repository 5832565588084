// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- MODELS -->
import { User } from '@/models/users/User';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
/** @typedef {import('@/api/users').IRequestException} IRequestException */
/** @typedef {import('@/api/users').IResponseResult} IResponseResult */
// ==== MODELS ====
/** @typedef {import('@/models/accounts/Account').AccountPayload} AccountPayload */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/users/User').UserPayload} UserPayload */
/** @typedef {import('@/models/users/User').UserResource} UserResource */
// ==== REQUESTS ====
/** @typedef {Readonly<Pick<UserPayload, 'id'>>} IEditUserTarget */
/** @typedef {Readonly<Pick<UserPayload, 'user_name' | 'email' | 'role'> & Partial<Pick<UserPayload, 'first_name' | 'last_name' | 'accounts'>>>} IEditUserRequest */
// ==== RESPONSES ====
/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */
/** @typedef {AxiosResponse<IEditUserSuccessData, IEditUserRequest>} IEditUserSuccessResponse */
/** @typedef {AxiosResponse<IEditUserDatabaseErrorData, IEditUserRequest>} IEditUserDatabaseErrorResponse */
/** @typedef {AxiosResponse<IEditUserValidationErrorData, IEditUserRequest>} IEditUserValidationErrorResponse */
/** @typedef {IEditUserSuccessResponse | IEditUserDatabaseErrorResponse | IEditUserValidationErrorResponse} IEditUserResponse */
// ==== DATA ====
/** @typedef {IEditUserSuccessData | IEditUserDatabaseErrorData | IEditUserValidationErrorData} IEditUserData */
/**
 * @typedef {Object} IEditUserSuccessData
 * @property {"updated"} status Response status type.
 * @property {UserPayload} user User resource.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} IEditUserDatabaseErrorData
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} IEditUserValidationErrorData
 * @property {String} message Human-readable message.
 * @property {Record<String, Array<String>>} errors Record containing properties and the corresponding validation errors. Not present when user is updated.
 */

// <!-- ROUTES -->
const ROUTES = {
    UPDATE_USER: (user) => `users/${user}`,
};

// <!-- METHODS -->
/**
 * Update existing user using request body content.
 * @param {IEditUserTarget} target
 * @param {IEditUserRequest} request
 * @returns {Promise<IResponseResult>}
 */
export const updateUserById = async (target, request) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is IEditUserResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {IEditUserResponse} response
     * @returns {response is ({ status: 200 } & IEditUserSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'updated' &&
            !isNil(response.data.user)
        );
    };

    /**
     * @param {IEditUserResponse} response
     * @returns {response is ({ status: 422 | 400 | 500 } & IEditUserDatabaseErrorResponse )}
     */
    const isDatabaseErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IEditUserResponse} response
     * @returns {response is ({ status: 422 } & IEditUserValidationErrorResponse )}
     */
    const isValidationErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'errors' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {IEditUserResponse} response
     */
    const handleResponse = (response) => {
        /** @type {IResponseResult} */
        const result = {
            status: 500,
            user: null,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Assign the user resource.
        if ('user' in response.data) {
            // Assign user resource to the result.
            const payload = response.data.user;
            result.user = new User({ payload }).toResource();
        }
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append additional messages.
                    const username = result.user.username;
                    result.messages = [`User ${username} has been updated.`];
                    return result;
                }
            case 422:
                if (isValidationErrorResponse(response)) {
                    // If response (with validation errors)...
                    const { data } = response;
                    // ...append rule violations as individual errors.
                    const rules = Object.keys(data.errors);
                    const violations = rules.flatMap(
                        (rule) => data.errors[rule]
                    );
                    result.errors = violations;
                    return result;
                } else if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            case 500:
                if (isDatabaseErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception?.message];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {IEditUserResponse} Response. */
        const response = await axios().put(
            ROUTES.UPDATE_USER(target.id),
            request
        );
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

// <!-- DEFAULT -->
export default updateUserById;
