// <!-- API -->
import { ECNBResourceIndexStateMutations } from '@/store/types/cache/mutations';
import omit from 'just-omit';

// <!-- TYPES -->
import { NoteIndexState } from '@/store/types/cache/state';
import { NoteResource } from '@/models/notes/Note';

/**
 * @class
 * Resource index state mutations.
 */
export class NoteIndexStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @param {NoteIndexState} state State instance.
     */
    static set(state) {
        const $set = ECNBResourceIndexStateMutations.set(state);
        return {
            ...omit($set, 'index', 'resource'),
            /**
             * Directly assign provided payload to the existing property.
             * @param {Map<Number, NoteResource> | null} [payload] Optional payload. Clears property cache if null.
             */
            notes: (payload) => $set.index(payload),
            /**
             * Directly assign provided item to the existing property collection.
             * @param {{ id: Number, resource: NoteResource } | null} [item] Optional payload. Drops item if null.
             */
            note: (item) => $set.resource(item),
        };
    }

    /**
     * Provide access to clear state operations.
     * @param {NoteIndexState} state State instance.
     */
    static clear(state) {
        const $clear = ECNBResourceIndexStateMutations.clear(state);
        return {
            ...omit($clear, 'index'),
            /**
             * Clear existing property.
             */
            notes: () => $clear.index(),
        };
    }

    /**
     * Provide access to state add operations.
     * @param {NoteIndexState} state State instance.
     */
    static add(state) {
        const $add = ECNBResourceIndexStateMutations.add(state);
        return {
            ...omit($add, 'index', 'resource'),
            /**
             * Merge provided payload with existing property.
             * @param {Map<Number, NoteResource>} [payload]
             */
            notes: (payload) => $add.index(payload),
            /**
             * Merge provided payload item with existing property collection.
             * @param {NoteResource} [item] Resource.
             */
            note: (item) => $add.resource(item),
        };
    }

    /**
     * Provide access to state drop operations.
     * @param {NoteIndexState} state State instance.
     */
    static drop(state) {
        const $drop = ECNBResourceIndexStateMutations.drop(state);
        return {
            ...omit($drop, 'index', 'resource'),
            /**
             * Seperate provided payload with existing property.
             * @param {Set<Number> | 'all'} [payload]
             */
            notes: (payload) => $drop.index(payload),
            /**
             * Drop provided payload item from existing property collection.
             * @param {Number} id
             */
            note: (id) => $drop.resource(id),
        };
    }
}
