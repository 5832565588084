// <!-- TYPES -->
// ts-ignore
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/**
 * @class
 * Account state.
 */
export class AccountState {
    /**
     * Construct initial account state.
     */
    constructor() {
        /** @type {AccountResource} */
        this.account = null;
    }
    /** Does the account state have a valid state assigned? */
    get hasAccount() {
        return !!this.account && !!this.account.id;
    }
}
