// <!-- TYPES -->
import { Model, FORMAT } from '@/models/Model';

import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'username',
    'email',
    'accessLevel',
    'accountId',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.username, 'username'],
    [FIELDS.email, 'email'],
    [FIELDS.accessLevel, 'access_level'],
    [FIELDS.accountId, 'account_id'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class NoteAuthorPayload {
    /** @type {Number} User identifier. */
    id = null;
    /** @type {String} Username. */
    username = '';
    /** @type {String} Email. */
    email = '';
    /** @type {String} Access level. */
    access_level = '';
    /** @type {Number} Account identifier. */
    account_id = null;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteAuthorPayload>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the payload.
     * @param {NoteAuthor} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.username = model.get(FIELDS.username);
        this.email = model.get(FIELDS.email);
        this.access_level = model.get(FIELDS.accessLevel);
        this.account_id = model.get(FIELDS.accountId);

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.username, this.username],
            [FIELDS.email, this.email],
            [FIELDS.accessLevel, this.access_level],
            [FIELDS.accountId, this.account_id],
        ];
    }
}

/**
 * @class
 */
export class NoteAuthorResource {
    /** @type {Number} User identifier. */
    id = null;
    /** @type {String} Username. */
    username = '';
    /** @type {String} Email. */
    email = '';
    /** @type {String} Access level. */
    accessLevel = '';
    /** @type {Number} Account identifier. */
    accountId = null;

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteAuthorResource>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the resource.
     * @param {NoteAuthor} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.username = model.get(FIELDS.username);
        this.email = model.get(FIELDS.email);
        this.accessLevel = model.get(FIELDS.accessLevel);
        this.accountId = model.get(FIELDS.accountId);
        // Return parsed instance.
        return this;
    }

    /**
     * Convert this resource into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.username, this.username],
            [FIELDS.email, this.email],
            [FIELDS.accessLevel, this.accessLevel],
            [FIELDS.accountId, this.accountId],
        ];
    }
}

/**
 * @class
 * @extends {Model<NoteAuthorPayload, NoteAuthorResource>}
 */
export class NoteAuthor extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<NoteAuthorPayload>} payload
     * @returns {this}
     */
    parsePayload(payload) {
        const instance = new NoteAuthorPayload(payload);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {NoteAuthorPayload}
     */
    toPayload() {
        return new NoteAuthorPayload().parseModel(this);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<NoteAuthorResource>} resource
     * @returns {this}
     */
    parseResource(resource) {
        const instance = new NoteAuthorResource(resource);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {NoteAuthorResource}
     */
    toResource() {
        return new NoteAuthorResource().parseModel(this);
    }
}
