// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- FORWARD EXPORTS -->
export * as LocationDatasets from '@/api/accounts/locations/datasets';
export * as Lists from '@/api/accounts/locations/lists';

// <!-- TYPES -->
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
import { LocationPayload, LocationResource } from '@/models/locations/Location';

// <!-- MODELS -->
import { Location, LocationList, LocationMetadata } from '@/models/locations';

// <!-- UTILITIES -->
import { saveAs } from 'file-saver';

// <!-- ROUTES -->
const ROUTES = {
    GET_LOCATIONS: (account) => `accounts/${account}/locations`,
    GET_LOCATION: (account, location) =>
        `accounts/${account}/locations/${location}`,
    CREATE_LOCATION: (account) => `accounts/${account}/locations`,
    UPDATE_LOCATION: (account, location) =>
        `accounts/${account}/locations/${location}`,
    DELETE_LOCATION: (account, location) =>
        `accounts/${account}/locations/${location}`,
    SUGGEST_LOCATIONS: (account) => `accounts/${account}/locations/metadata`,
    EXPORT_LOCATION: (account, location) =>
        `accounts/${account}/locations/${location}/export`,
};

// <!-- REQUESTS -->
/**
 * Fetch all locations associated with an institution account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {import('@/types').AxiosRequestConfig} [config]
 * @returns {Promise<LocationResource[]>}
 */
export const fetchLocations = async (account = { id: 8 }, config = {}) => {
    /** @type {import('axios').AxiosResponse<{ data: LocationPayload[] }>} */
    const response = await axios().get(
        ROUTES.GET_LOCATIONS(account.id),
        config ?? {}
    );
    const collection = response.data.data;
    return collection.map((location) =>
        new Location({ payload: location }).toResource()
    );
};

/**
 * Fetch specified location by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationResource, 'id'>} location
 */
export const fetchLocationById = async (
    account = { id: 8 },
    location = { id: 1 }
) => {
    /** @type {import('axios').AxiosResponse<{ location: LocationPayload }>} */
    const response = await axios().get(
        ROUTES.GET_LOCATION(account.id, location.id)
    );
    const payload = response.data.location;
    return new Location({ payload }).toResource();
};

/**
 * Create new location using request body content.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {FormData} request
 */
export const createLocation = async (
    account = { id: 8 },
    request = new FormData()
) => {
    /** @type {import('axios').AxiosResponse<{ location: LocationPayload }, LocationPayload | FormData>} */
    const response = await axios().post(
        ROUTES.CREATE_LOCATION(account.id),
        request
    );
    const payload = response.data.location;
    return new Location({ payload }).toResource();
};

/**
 * Update specified location by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationResource, 'id'>} location
 * @param {FormData} request
 */
export const updateLocationById = async (
    account = { id: 8 },
    location = { id: 1 },
    request = new FormData()
) => {
    /** @type {import('axios').AxiosResponse<{ location: LocationPayload }, FormData>} */
    request.append('_method', 'PUT');
    // HACK: Laravel/PHP has an error when handling multipart/form-data
    //   and cannot accept multipart/form-data content on the PUT/PATCH
    //   endpoints. Please POST when you need to upload a file, and
    //   append "_method": "PUT", so Laravel recognizes this.
    const response = await axios().post(
        ROUTES.UPDATE_LOCATION(account.id, location.id),
        request
    );
    const payload = response.data.location;
    return new Location({ payload }).toResource();
};

/**
 * Delete specified location by id for the supplied account.
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationResource, 'id'>} location
 */
export const deleteLocationById = async (
    account = { id: 8 },
    location = { id: 1 }
) => {
    /** @type {import('axios').AxiosResponse<{ location: LocationPayload }>} */
    const response = await axios().delete(
        ROUTES.DELETE_LOCATION(account.id, location.id)
    );
    const payload = response.data.location;
    return new Location({ payload }).toResource();
};

/**
 * Fetch the suggested locations.
 *
 * @param {Pick<AccountResource, 'id'>} account
 * @param {String[]} files Collection of filenames to request location suggestions for.
 */
export const suggestLocations = async (
    account = { id: 8 },
    files = ['example_filename.csv']
) => {
    const request = files.map((file) => ({ filename: file }));
    /** @type {import('axios').AxiosResponse<{ metadata: import('@/models/locations/LocationMetadata').LocationMetadataPayload }>} */
    const response = await axios().post(ROUTES.SUGGEST_LOCATIONS(account.id), {
        request,
    });
    /** @type {import('@/models/locations/LocationMetadata').LocationMetadataPayload}  */
    const payload = response.data.metadata;
    return new LocationMetadata({ payload }).toResource();
};

/**
 * Export location as a CSV dataset. Does not download the file alone.
 *
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationResource, 'id'>} location
 */
export const exportLocation = async (account, location) => {
    return await axios().get(
        ROUTES.EXPORT_LOCATION(account.id, location.id),
        { responseType: 'blob' }
    );
};

/**
 * Export location as a CSV dataset. Does not download the file alone.
 *
 * @param {Pick<AccountResource, 'id'>} account
 * @param {Pick<LocationResource, 'id'>} location
 * @param {String} filename Filename to download file as.
 */
export const downloadLocation = async (account, location, filename) => {
    // Get the exported response.
    const response = await exportLocation(account, location);

    // Download the CSV file.
    saveAs(response.data, filename);

    // Return the response.
    return response;
};

// <!-- EXPORTS -->
export default {
    Location,
    LocationMetadata,
    LocationList,
    fetchLocations,
    fetchLocationById,
    createLocation,
    updateLocationById,
    deleteLocationById,
    suggestLocations,
    exportLocation,
    downloadLocation,
};
