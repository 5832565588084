// <!-- TYPES -->
import { Model, FORMAT } from '../Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<DatasetBatch['toPayload']>} DatasetBatchPayload */
/** @typedef {ReturnType<DatasetBatch['toResource']>} DatasetBatchResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'locationId',
    'accountId',
    'profileId',
    'filename',
    'filetype',
    'points',
    'dateCreated',
    'createdBy',
    'dateDeleted',
    'deletedBy',
    'dateDataStart',
    'dateDataEnd',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.locationId, 'location_id'],
    [FIELDS.accountId, 'account_id'],
    [FIELDS.profileId, 'mapping_profile_id'],
    [FIELDS.filename, 'filename'],
    [FIELDS.filetype, 'filetype'],
    [FIELDS.points, 'number_of_points'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.createdBy, 'created_by'],
    [FIELDS.dateDeleted, 'deleted_at'],
    [FIELDS.deletedBy, 'deleted_by'],
    [FIELDS.dateDataStart, 'data_start'],
    [FIELDS.dateDataEnd, 'data_end'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class DatasetBatch extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    toResource() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].id]: this.get(FIELDS.id),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].locationId]: this.get(FIELDS.locationId),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].accountId]: this.get(FIELDS.accountId),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].profileId]: this.get(FIELDS.profileId),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].filename]: this.get(FIELDS.filename),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].filetype]: this.get(FIELDS.filetype),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].points]: this.get(FIELDS.points),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateCreated]: this.get(FIELDS.dateCreated),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].createdBy]: this.get(FIELDS.createdBy),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateDeleted]: this.get(FIELDS.dateDeleted),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].deletedBy]: this.get(FIELDS.deletedBy),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateDataStart]: this.get(
                FIELDS.dateDataStart
            ),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateDataEnd]: this.get(FIELDS.dateDataEnd),
        };
    }

    toPayload() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.get(FIELDS.id),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].location_id]: this.get(FIELDS.locationId),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].account_id]: this.get(FIELDS.accountId),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].mapping_profile_id]: this.get(
                FIELDS.profileId
            ),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].filename]: this.get(FIELDS.filename),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].filetype]: this.get(FIELDS.filetype),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].number_of_points]: this.get(FIELDS.points),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].created_at]: this.get(FIELDS.dateCreated),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].created_by]: this.get(FIELDS.createdBy),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].deleted_at]: this.get(FIELDS.dateDeleted),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].deleted_by]: this.get(FIELDS.deletedBy),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].data_start]: this.get(FIELDS.dateDataStart),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].data_end]: this.get(FIELDS.dateDataEnd),
        };
    }
}
