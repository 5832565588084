// <!-- TYPES -->
import { Model, FORMAT } from '../Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<User['toPayload']>} UserPayload */
/** @typedef {ReturnType<User['toResource']>} UserResource */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'username',
    'firstname',
    'lastname',
    'protected',
    'email',
    'role',
    'accounts',
    'dateCreated',
    'dateUpdated',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.username, 'user_name'],
    [FIELDS.firstname, 'first_name'],
    [FIELDS.lastname, 'last_name'],
    [FIELDS.email, 'email'],
    [FIELDS.role, 'role'],
    [FIELDS.accounts, 'accounts'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.dateUpdated, 'updated_at'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * Represents the current logged in user.
 * @class
 * @extends {Model}
 */
export class User extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * @param {UserResource} resource
     * @returns {this}
     */
    parseResource(resource) {
        /** @type {(resource: UserResource) => [string, import('@/models/Model').AttributeType][]} */
        const transformResource = (resource) => {
            const { accounts, ...attributes } = resource;

            const _accountList = accounts.map((acct) => {
                return { id: acct.id, name: acct.name };
                // return new Account().parseResource(acct);
            });

            const entity = {
                ...attributes,
                accounts: [..._accountList],
            };
            return Object.entries(entity);
        };
        super.parseResource(resource, transformResource);
        return this;
    }

    toPayload() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.id,
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].user_name]: this.get(FIELDS.username),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].first_name]: this.get(FIELDS.firstname),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].last_name]: this.get(FIELDS.lastname),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].email]: this.get(FIELDS.email),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].role]: this.get(FIELDS.role),
            /** @type {String[]} */
            [KEYS[FORMAT.PAYLOAD].accounts]: this.get(FIELDS.accounts),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].created_at]: new Date(
                this.get(FIELDS.dateCreated)
            ).toISOString(),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].updated_at]: new Date(
                this.get(FIELDS.dateCreated)
            ).toISOString(),
        };
    }

    toResource() {
        return {
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].id]: this.id,
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].username]: this.get(FIELDS.username),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].email]: this.get(FIELDS.email),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].firstname]: this.get(FIELDS.firstname),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].lastname]: this.get(FIELDS.lastname),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].role]: this.get(FIELDS.role),
            /** @type {import('../accounts/Account').AccountResource[]} */
            [KEYS[FORMAT.PAYLOAD].accounts]: this.get(FIELDS.accounts),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateCreated]: new Date(
                this.get(FIELDS.dateCreated)
            ).toISOString(),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateUpdated]: new Date(
                this.get(FIELDS.dateUpdated)
            ).toISOString(),
        };
    }
}
