// <!-- UTILITIES -->
import { LocalStorage } from '@/features/auth/utils/LocalStorage';

// <!-- TYPES -->
/** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
/** @typedef {import('@/store/types/ECNBStore').ECNBState} ECNBState */

/**
 * Checks if the bearer authentication token has been stored on successful login.
 * @returns {Boolean}
 */
export const isUserAuthenticated = () => {
    if (LocalStorage.has('auth_token')) {
        const isValidToken = !!LocalStorage.get('auth_token', null);
        return isValidToken;
    }
    // Not authenticated.
    return false;
};

/**
 * Checks if the user is authorized for their selected account.
 * @param {Store<ECNBState>} store
 * @returns {Boolean}
 */
export const isUserAuthorized = (store) => {
    const hasUser =
        isUserAuthenticated() && store.state.users?.hasUser === true;
    const hasAccount = store.state.accounts?.hasAccount === true;
    return hasUser && hasAccount;
};

/**
 * Set of routes that should redirect when the user is logged in.
 * @type {Set<String | Symbol>}
 */
const RequireUnauthenticated = new Set(
    /** @type {const} */ ([
        'Login',
        'Sign Up',
        'Forgot Password',
        'Reset Password',
    ])
);

/**
 * Set of routes that should redirect when the user is not logged in OR when the user does not have a selected account.
 * @type {Set<String | Symbol>}
 */
const RequireAccount = new Set(
    /** @type {const}*/ ([
        'Home',
        'Analysis',
        'Statistics',
        'Compare Metrics',
        'NARA Standards',
        'Reports',
        'Admin Dashboard',
        'Admin Accounts',
        'Admin Users',
        'Admin NARA Standards',
        'Data Manager',
        'Locations',
        'Location Detail',
        'Weather Stations',
        'Note Manager',
        'Notes',
        'Account Information',
        'User Manager',
        'CSV Uploader',
        'Upload',
    ])
);

/**
 * Set of routes that should redirect when the user is not logged in.
 * @type {Set<String | Symbol>}
 */
const RequireAuthenticated = new Set(/** @type {const} */ (['Select Account']));

/**
 * Determine if the route has a valid name.
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 * @returns {Boolean}
 */
const isRouteValid = (route) => !!route && !!route?.name && route?.name !== '';

/**
 * Is the specified route one that has no authorization pre-requisites?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isPublic = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresAuthentication = RequireAuthenticated.has(name);
        const requiresAccount = RequireAccount.has(name);
        return !requiresAuthentication && !requiresAccount;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Is the provided route one that requires authentication?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isAuthenticationRequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresAuthentication = RequireAuthenticated.has(name);
        const requiresAccount = RequireAccount.has(name);
        return requiresAccount || requiresAuthentication;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Is the provided route one that requires authentication and an authorized account?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isAccountRequired = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresAccount = RequireAccount.has(name);
        return requiresAccount;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Is the provided route one that requires the user to be unauthenticated?
 * @param {Pick<Router.RouteLocationNormalizedLoaded, 'name'>} route
 */
export const isAuthenticationSkipped = ({ name }) => {
    const isValid = isRouteValid({ name });
    if (isValid) {
        const requiresUnauthenticated = RequireUnauthenticated.has(name);
        return requiresUnauthenticated;
    }
    // Invalid routes are falsy.
    return false;
};

/**
 * Register the navigation guard.
 * @param {Router.Router} router
 * @param {Store<ECNBState>} store
 */
export const useAuthRedirects = (router, store) => {
    // Register the navigation guard.
    router?.beforeEach(async (to, from, next) => {
        // Determine if user is authenticated.
        const authenticatedUser = isUserAuthenticated();

        // Determine if user has an account selected.
        const authorizedUser = isUserAuthorized(store);

        if (!authenticatedUser) {
            // If the user is unauthenticated...
            if (isAuthenticationRequired(to)) {
                // ...and if the destination requires authentication...
                // ...redirect to the login page.
                next({ name: 'Login' });
                return;
            }
        }

        if (authenticatedUser) {
            // If the user is authenticated...
            if (isAuthenticationSkipped(to)) {
                // ...and if the destination should be skipped when authenticated...
                // ...redirect to the home page.
                next({ name: 'Home' });
                return;
            }
            if (!authorizedUser) {
                // ...and if the does not have a selected account...
                if (isAccountRequired(to)) {
                    // ...and if the destination requires an account...
                    // ...redirect to the select account page.
                    next({ name: 'Select Account' });
                    return;
                }
            }
        }

        // If no guard condition is met, allow user to continue.
        next();
        return;
    });
};

// <!-- DEFAULT -->
export default useAuthRedirects;
