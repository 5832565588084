// <!-- API -->
import stations from '@/api/accounts/weather';
import { WeatherStationIndexState } from '@/store/types/cache/state';
import { Assertion } from '@/store/types/ECNBAssertions';

// <!-- TYPES -->

import { ECNBState } from '@/store/types/ECNBStore';
import { ECNBModule } from '@/store/types/ECNBModule';
import { ECNBResourceCacheModule } from '@/store/types/cache/module/ECNBResourceCacheModule';

/**
 * @class
 * Cache resource module.
 */
export class WeatherStationCacheModule extends ECNBResourceCacheModule {
    /**
     * Name of the module.
     */
    static get namespace() {
        return 'cache/stations';
    }

    /**
     * Send fetch request for an index of resources.
     *
     * @param {import('vuex').ActionContext} context
     * @returns {Promise<Map<String, import('@/models/weather/WeatherStation').WeatherStationResource>>}
     */
    static async fetchIndex(context) {
        const { rootState } = context;
        const account = rootState.accounts.account;
        const resources = await stations.fetchWeatherStations(account);
        /** @type {Map<String, import('@/models/weather/WeatherStation').WeatherStationResource>} */
        const index = new Map();
        for (const station of resources) {
            index.set(String(station.id), station);
        }
        return index;
    }

    /**
     * Send fetch request for a single resource.
     *
     * @param {import('vuex').ActionContext} context
     * @param {String} id
     * @returns {Promise<import('@/models/weather/WeatherStation').WeatherStationResource>}
     */
    static async fetchResource(context, id) {
        const { rootState } = context;
        const account = rootState.accounts.account;
        const request = { id };
        const resource = await stations.fetchWeatherStationById(
            account,
            request
        );
        return resource;
    }

    /**
     * Module state, getters, mutations, and actions.
     */
    static get module() {
        const $ = WeatherStationCacheModule;
        const $module = ECNBResourceCacheModule.module;
        return {
            ...$module,
            // OVERRIDES
            state: () => new WeatherStationIndexState(),
            get actions() {
                /**
                 * Module resource fetchers.
                 */
                const fetchers = {
                    /**
                     * Fetch and cache index.
                     * @param {import('vuex').ActionContext<WeatherStationIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Boolean} payload.ignoreCache If `true`, force cache refresh from remote.
                     * @returns {Promise<Map<String, import('@/models/weather/WeatherStation').WeatherStationResource>>}
                     */
                    fetchIndex: async (context, payload) => {
                        // ts-ignore
                        const { state, rootState } = context;
                        const { ignoreCache = false } = payload ?? {};
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        const $invoke = $use.invoke; // Local action dispatcher.
                        // <!-- FETCHING -->
                        try {
                            // Fetch new index and cache it before selecting the index,
                            //   if ignoreCache is true or if the state is empty.
                            if (ignoreCache === true || state.is.empty) {
                                // Enable fetching status.
                                $mutate('addStatus').withPayload('fetching');
                                // Call the axios API endpoint. (Override in child classes).
                                const index = await $.fetchIndex(context);
                                // Ensure non-null response.
                                await Assertion.expect(index).isNotNil();
                                // Overwrite the index.
                                await $invoke('cacheIndex')
                                    .withPayload({
                                        index,
                                        append: false,
                                    })
                                    .dispatch();
                                // Warn if still empty after fetching and caching.
                                if (state.is.empty) {
                                    console.warn(`Index empty after fetching.`);
                                }
                            }
                        } catch (error) {
                            console.dir(error);
                        } finally {
                            // Disable fetching status.
                            $mutate('dropStatus').withPayload('fetching');
                        }

                        // <!-- SELECTING -->
                        // Return the index map.
                        return state.index;
                    },
                    /**
                     * Fetch and cache resource.
                     * @param {import('vuex').ActionContext<WeatherStationIndexState, ECNBState>} context
                     * @param {Object} payload
                     * @param {String} payload.id
                     * @param {import('@/models/weather/WeatherStation').WeatherStationResource} [payload.defaultValue]
                     * @param {Boolean} [payload.ignoreCache]
                     * @returns {Promise<import('@/models/weather/WeatherStation').WeatherStationResource>}
                     */
                    fetchResource: async (context, payload) => {
                        // ts-ignore
                        const { state, rootState } = context;
                        const {
                            id,
                            ignoreCache = false,
                            defaultValue = null,
                        } = payload ?? {};
                        const $use = ECNBModule.use(context);
                        const $mutate = $use.mutate; // Local mutation applicator.
                        const $invoke = $use.invoke; // Local action dispatcher.
                        await Assertion.expect(id).isNotNil();

                        try {
                            // <!-- FETCHING -->
                            // Fetch resource and cache it if:
                            // - ignoreCache is `true`, or
                            // - state index is empty, or
                            // - state index is missing this specific resource already.
                            if (
                                ignoreCache === true ||
                                state.is.empty ||
                                !state.has.resource(id)
                            ) {
                                // Start fetching status.
                                $mutate('addStatus').withPayload('fetching');
                                // Call the axios API endpoint. (Override in child classes).
                                const resource = await $.fetchResource(
                                    context,
                                    id
                                );
                                // Ensure non-null response.
                                await Assertion.expect(resource).isNotNil();
                                // Cache the index.
                                await $invoke('cacheResource')
                                    .withPayload({
                                        resource,
                                    })
                                    .dispatch();
                                // Warn if still empty after fetching and caching.
                                if (state.is.empty || !state.has.resource(id)) {
                                    console.warn(`Resource not found.`);
                                }
                            }
                        } catch (error) {
                            console.dir(error);
                        } finally {
                            $mutate('dropStatus').withPayload('fetching');
                        }

                        // <!-- SELECTING -->
                        // Return the resource.
                        return state.select(id, defaultValue);
                    },
                };
                return {
                    ...$module.actions,
                    ...fetchers,
                    // EXTENSIONS.
                    /**
                     * Fetch and cache index.
                     * @param {import('vuex').ActionContext<WeatherStationIndexState, ECNBState>} context
                     * @param {Object} payload Action payload.
                     * @param {Boolean} payload.ignoreCache If `true`, force cache refresh from remote.
                     * @returns {Promise<Map<String, WeatherStationResource>>}
                     */
                    fetchWeatherStations: fetchers.fetchIndex,
                    /**
                     * Fetch and cache resource.
                     * @param {import('vuex').ActionContext<WeatherStationIndexState, ECNBState>} context
                     * @param {Object} payload
                     * @param {String} payload.id
                     * @param {WeatherStationResource} [payload.defaultValue]
                     * @param {Boolean} [payload.ignoreCache]
                     * @returns {Promise<WeatherStationResource>}
                     */
                    fetchWeatherStation: fetchers.fetchResource,
                };
            },
        };
    }
}
