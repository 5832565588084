// <!-- TYPES -->
import { Model, FORMAT } from '../Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<MappingProfile['toPayload']>} MappingProfilePayload */
/** @typedef {ReturnType<MappingProfile['toResource']>} MappingProfileResource */
import { MappingSettings } from './MappingSettings';
import { MappingRule } from './MappingRule';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'accountId',
    'name',
    'settings',
    'rules',
    'dateCreated',
    'createdBy',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
    [FIELDS.accountId, 'account_id'],
    [FIELDS.settings, 'settings'],
    [FIELDS.rules, 'rules'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.createdBy, 'created_by'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class MappingProfile extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * @param {MappingProfilePayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {(payload: MappingProfilePayload) => [string, import('@/models/Model').AttributeType][]} */
        const transformPayload = (payload) => {
            const { rules, settings, ...attributes } = payload;

            /** @type {(import('./MappingRule').MappingRuleResource['target'])[]} */
            const targets = !!rules
                ? /** @type {any} */ (Object.keys(rules))
                : [];
            const collection = !!rules
                ? targets.map((target) => {
                      return { [target]: rules[target] };
                  })
                : [];

            const entity = {
                ...attributes,
                settings: !!settings
                    ? new MappingSettings().parsePayload(settings)
                    : null,
                rules: !!rules
                    ? collection.map((rule) =>
                          new MappingRule().parsePayload(rule)
                      )
                    : null,
            };
            return Object.entries(entity);
        };
        super.parsePayload(payload, transformPayload);
        return this;
    }

    /**
     * @param {MappingProfileResource} resource
     * @returns {this}
     */
    parseResource(resource) {
        /** @type {(resource: MappingProfileResource) => [string, import('@/models/Model').AttributeType][]} */
        const transformResource = (resource) => {
            const { rules, settings, ...attributes } = resource;
            const entity = {
                ...attributes,
                settings: new MappingSettings().parseResource(settings),
                rules: rules.map((rule) =>
                    new MappingRule().parseResource(rule)
                ),
            };
            return Object.entries(entity);
        };
        super.parseResource(resource, transformResource);
        return this;
    }

    toPayload() {
        /** @type {MappingSettings} */
        const settings = this.get(FIELDS.settings) ?? {};

        /** @type {MappingRule[]} */
        const rules = this.get(FIELDS.rules) ?? [];

        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.get(FIELDS.id),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].name]: this.get(FIELDS.name),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].account_id]: this.get(FIELDS.accountId),
            /** @type {import('./MappingSettings').MappingSettingsPayload} */
            [KEYS[FORMAT.PAYLOAD].settings]:
                settings?.toPayload?.() ?? new MappingSettings().toPayload(),
            /** @type {import('./MappingRule').MappingRulePayload[]} */
            [KEYS[FORMAT.PAYLOAD].rules]: rules.map((r) => r?.toPayload()),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].created_at]: this.get(FIELDS.dateCreated),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].created_by]: this.get(FIELDS.createdBy),
        };
    }

    toResource() {
        /** @type {MappingSettings} */
        const settings = this.get(FIELDS.settings) ?? {};

        /** @type {MappingRule[]} */
        const rules = this.get(FIELDS.rules) ?? [];

        return {
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].id]: this.get(FIELDS.id),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].name]: this.get(FIELDS.name),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].accountId]: this.get(FIELDS.accountId),
            /** @type {import('./MappingSettings').MappingSettingsResource} */
            [KEYS[FORMAT.RESOURCE].settings]:
                settings?.toResource?.() ?? new MappingSettings().toResource(),
            /** @type {import('./MappingRule').MappingRuleResource[]} */
            [KEYS[FORMAT.RESOURCE].rules]: rules.map((r) => r?.toResource()),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].dateCreated]: this.get(FIELDS.dateCreated),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].createdBy]: this.get(FIELDS.createdBy),
        };
    }
}
