// <!-- EXPORTS -->
/** @typedef {Record<String, Readonly<Router.RouteRecordRaw>>} */
export const auth = {
    login: {
        path: '/login',
        name: 'Login',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: 'auth' */ '@/features/auth/views/Login.vue'
            ),
        meta: { namespace: 'auth__login' },
    },
    'select-account': {
        path: '/select-account',
        name: 'Select Account',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: 'auth' */ '@/features/switch-account/views/SelectAccount.vue'
            ),
        meta: { namespace: 'auth__select-account' },
    },
    signup: {
        path: '/signup',
        name: 'Sign Up',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "signup" */ '@/features/auth/views/SignUp.vue'
            ),
        meta: { namespace: 'auth__signup' },
    },
    'forgotten-password': {
        path: '/password/forgotten',
        name: 'Forgot Password',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "signup" */ '@/features/auth/views/ForgotPassword.vue'
            ),
        meta: { namespace: 'auth__forgotten-password' },
    },
    'password-reset': {
        path: '/password/reset',
        name: 'Reset Password',
        alias: '/reset',
        component: () =>
            import(
                /* webpackPrefetch: true */ /* webpackChunkName: "signup" */ '@/features/auth/views/PasswordReset.vue'
            ),
        meta: { namespace: 'auth__reset-password' },
    },
};

// <!-- DEFAULT -->
export default auth;
