// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';

// <!-- TYPES -->
/** @typedef {import('@/models/standards/NARAStandard').NARAStandardResource} NARAStandardResource */

/**
 * @class
 * Base collection management for a resource index.
 * @extends {ECNBResourceIndexState<Number, NARAStandardResource>}
 */
export class NARAStandardIndexState extends ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {Map<Number, NARAStandardResource>} [index]
     * @param {Map<String, any>} [attributes]
     */
    constructor(index = new Map(), attributes = new Map()) {
        super('standards', index, attributes);
    }
}
