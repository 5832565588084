// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const Resource = createEnum({
    notes: Symbol('[notes::index]'),
});

/**
 * Type guard for enum key.
 * @template {keyof Resource} [K=keyof Resource]
 * @param {K} target
 * @param {keyof Resource} key
 * @returns {key is K}
 */
export const isResourceKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {Resource[keyof Resource]} [V=Resource[keyof Resource]]
 * @param {V} target
 * @param {Resource[keyof Resource]} value
 * @returns {value is V}
 */
export const isResource = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default Resource;
