// <!-- PLUGINS -->
import { useAxios as axios } from '@/plugins/axios';

// <!-- UTILITIES -->
import isNil from 'lodash-es/isNil';

// <!-- TYPES -->
/** @typedef {import('@/api/users').IRequestException} IRequestException */
/** @typedef {import('@/api/users').IResponseResult} IResponseResult */
// ==== MODELS ====
/** @typedef {import('@/models/accounts/Account').AccountPayload} AccountPayload */
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
/** @typedef {import('@/models/users/User').UserPayload} UserPayload */
/** @typedef {import('@/models/users/User').UserResource} UserResource */
// ==== REQUESTS ====
/** @typedef {Readonly<Pick<UserPayload, 'id' | 'user_name' | 'email'>>} IDeleteUserTarget */
// ==== RESPONSES ====
/** @template [T=any] @template [D=any] @typedef {import('axios').AxiosResponse<T,D>} AxiosResponse */
/** @typedef {AxiosResponse<IDeleteUserSuccessData, any>} IDeleteUserSuccessResponse */
/** @typedef {AxiosResponse<IDeleteUserErrorData, any>} IDeleteUserForbiddenErrorResponse */
/** @typedef {AxiosResponse<IDeleteUserErrorData, any>} IDeleteUserServerErrorResponse */
/** @typedef {AxiosResponse<IDeleteUserExceptionData, any>} IDeleteUserExceptionResponse */
/** @typedef {IDeleteUserSuccessResponse | IDeleteUserForbiddenErrorResponse | IDeleteUserServerErrorResponse | IDeleteUserExceptionResponse } IDeleteUserResponse */
// ==== DATA ====
/** @typedef {IDeleteUserSuccessData | IDeleteUserErrorData | IDeleteUserExceptionData} IDeleteUserData */
/**
 * @typedef {Object} IDeleteUserSuccessData
 * @property {"deleted"} status Response status type.
 * @property {String} message Human-readable message.
 */
/**
 * @typedef {Object} IDeleteUserErrorData
 * @property {"error"} status Response status type.
 * @property {String} message Human-readable message.
 * @property {IRequestException} exception Exception, if one was raised while sending the password reset link. If not present, no error when sending the email occurred.
 */
/**
 * @typedef {Object} IDeleteUserExceptionData
 * @property {String} message Human-readable message.
 * @property {String} exception Exception title.
 * @property {String} file File location.
 * @property {Number} line Line numbrer.
 * @property {({ file: String, line: Number, function: String, class: String, type: String })[]} trace Stack trace.
 */

// <!-- ROUTES -->
const ROUTES = {
    DELETE_USER: (id) => `users/${id}`,
};

// <!-- METHODS -->
/**
 * Delete the target user.
 * @param {IDeleteUserTarget} target
 * @returns {Promise<IResponseResult>}
 */
export const deleteUserById = async (target) => {
    // <!-- TYPE GUARDS -->
    /**
     * @param {any} response
     * @returns {response is IDeleteUserResponse}
     */
    const isResponse = (response) => {
        return (
            !isNil(response) && // Non-null Axios response.
            !isNil(response.status) && // Non-null Axios status.
            !isNil(response.data) // Non-null Axios data property.
        );
    };

    /**
     * @param {IDeleteUserResponse} response
     * @returns {response is ({ status: 200 } & IDeleteUserSuccessResponse)}
     */
    const isSuccessResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 200 &&
            'status' in response.data &&
            !('exception' in response.data) &&
            response.data.status === 'deleted'
        );
    };

    /**
     * @param {IDeleteUserResponse} response
     * @returns {response is ({ status: 403 } & IDeleteUserForbiddenErrorResponse )}
     */
    const isForbbidenErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 403 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteUserResponse} response
     * @returns {response is ({ status: 500 } & IDeleteUserServerErrorResponse )}
     */
    const isServerErrorResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 500 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteUserResponse} response
     * @returns {response is ({ status: 404 } & IDeleteUserExceptionResponse )}
     */
    const isMissingResponse = (response) => {
        return (
            isResponse(response) &&
            response.status === 404 &&
            'status' in response.data &&
            'exception' in response.data &&
            response.data.status === 'error'
        );
    };

    /**
     * @param {IDeleteUserResponse} response
     * @returns {response is ({ status: 422 | 400 } & IDeleteUserExceptionResponse )}
     */
    const isExceptionResponse = (response) => {
        return (
            isResponse(response) &&
            response.status !== 200 &&
            !('status' in response.data) &&
            'exception' in response.data
        );
    };

    /**
     * Get the result from the response.
     * @param {IDeleteUserResponse} response
     */
    const handleResponse = (response) => {
        /** @type {Omit<IResponseResult, 'user'>} */
        const result = {
            status: 500,
            label: 'An unknown error occurred.',
            messages: [],
            warnings: [],
            errors: [],
        };
        // Assign the status.
        result.status = response.status;
        // Assign the primary message / result label.
        result.label = response.data.message;
        // Handle response based on status code and schema.
        switch (response.status) {
            case 200:
                if (isSuccessResponse(response)) {
                    // If response (without mail server exception)...
                    // ...append message about the target user.
                    result.messages = [
                        `User ${target.user_name} deleted successfully.`,
                    ];
                    return result;
                }
            case 403:
                if (isForbbidenErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception.message];
                    return result;
                }
            case 404:
                if (isMissingResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception];
                    return result;
                }
            case 500:
                if (isServerErrorResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception.message];
                    return result;
                }
            case 400:
            case 422:
                if (isExceptionResponse(response)) {
                    // If response (with database / query errors)...
                    const { data } = response;
                    // ...append database exception message.
                    result.errors = [data.exception];
                    return result;
                }
            default:
                // If response of unknown schema.
                throw new TypeError(
                    `Unknown API schema received. Please contact your server administrator: ${JSON.stringify(
                        response.data
                    )}`
                );
        }
    };

    try {
        // <!-- REQUEST -->
        /** @type {IDeleteUserResponse} Response. */
        const response = await axios().delete(ROUTES.DELETE_USER(target.id));
        const result = handleResponse(response);
        return result;
    } catch (e) {
        if ('response' in e && 'isAxiosError' in e) {
            // Get the error result, if possible.
            const result = handleResponse(e.response);
            return result;
        }
        // If no response present in error, throw the error up the stack.
        throw e;
    }
};

// <!-- DEFAULT -->
export default deleteUserById;
