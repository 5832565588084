// <!-- UTILITIES -->
import { Unit } from '@/utils/enums';
import { formatUnit } from '@/utils/formatters';

// <!-- TYPES -->
/** @typedef {import('@/utils/formatters').ITemperatureFormatter} ITemperatureFormatter */

// <!-- EXPORTS -->
/**
 * @type {ITemperatureFormatter} Formats temperature according to the specified options. Set options.minimumFractionDigits and options.maximumFractionDigits to affect presence of decimals.
 * - Defaults to the Fahrenheit format if not specified.
 *
 * @example
 * ```
 * formatTemperature({ value: 12.3 }); // 12.3 ==> "12.3°F"
 * formatTemperature({ value: 12.3, options: { unit: 'C', minimumFractionDigits: 2, maximumFractionDigits: 2 } }); // 12.3 ==> "12.30°C"
 * ```
 */
export const formatTemperature = ({ value, options }) => {
    // <!-- DESTRUCTURE -->
    const {
        unit = Unit.Fahrenheit,
        minimumFractionDigits = 0,
        maximumFractionDigits = 2,
    } = options ?? {};

    // <!-- COMPUTE -->
    const formatted = formatUnit({
        value,
        options: { unit, minimumFractionDigits, maximumFractionDigits },
    });

    // <!-- EXPOSE -->
    return formatted;
};

// <!-- DEFAULT -->
export default formatTemperature;
