/**
 * axios.js
 *
 * Provides utility functions to configure the axios plugin
 * as a singleton instance and add middleware.
 */
import axios from 'axios';
import {
    useLocalBearerTokenRequest,
    onRequestError,
    onResponseError,
} from '@api/middleware';

/**
 * Hidden global state tracking the axios plugin's singleton.
 */
const _axios = {
    /** @type {import('axios').AxiosInstance} Axios instance. */
    instance: null,
};

/**
 * Create new axios instance and provide it with configuration defaults
 * that are reasonable for specifically the ECNB backend request/response
 * handling.
 *
 * If we needed an axios instance with different defaults,
 * we would just `import axios from 'axios';` and create
 * a new one directly.
 *
 * @returns {import('axios').AxiosInstance} Axios instance.
 */
export const createAxiosInstance = () => {
    /**
     * Prepare the axios instance with set defaults.
     * See: https://axios-http.com/docs/config_defaults
     */
    const instance = axios.create({
        // equivalent to axios.defaults.baseURL = '...'
        baseURL: process.env.VUE_APP_API_PREFIX + '/api/v1',
    });

    /** Set the axios instance reference. */
    _axios.instance = instance;

    // Configure the instance here.
    // instance.axios.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";

    /** Register request inteceptors here. */
    // _axios.instance.interceptors.request.use(useXXXRequest, onXXXError);
    _axios.instance.interceptors.request.use(
        useLocalBearerTokenRequest,
        onRequestError
    );

    /** Register response inteceptors here. */
    // _axios.instance.interceptors.response.use(useXXXResponse, useXXXError);
    _axios.instance.interceptors.response.use(
        // TODO: Remove debug line.
        (response) => {
            if (process.env.VUE_APP_DEBUG_AXIOS) {
                const timestamp = new Date().toLocaleTimeString();
                const anonymous = '<Anonymous Response>';
                const id = response.config.url ?? anonymous;
                console.groupCollapsed(
                    `[${id}] (${response.status}) @ ${timestamp}`
                );
                console.log(response);
                console.groupEnd();
            }
            return response;
        },
        onResponseError
    );

    _axios.instance.interceptors.response.use(
        (response) => { 
            return response;
        },
        (error) => {
            // If the auth token expires, refresh it and retry the request.
            const refresh_token = localStorage.getItem('refresh_token');

            // Exit early if no refresh token is present, the error was not a 401,
            // the error occured while attempting to refresh the token, or the
            // request has already been retried once.
            if (!refresh_token ||
                error.response?.status !== 401 || 
                error.config.url === 'oauth/token' ||
                error.config._retry
            ) {
                return Promise.reject(error);
            }
            
            return _axios.instance.post('oauth/token', {
                client_id: process.env.VUE_APP_CLIENT_ID,
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                grant_type: 'refresh_token',
                refresh_token,
            }, {
                baseURL: process.env.VUE_APP_API_PREFIX
            }).then(({ data }) => {
                localStorage.setItem('auth_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);

                // Retry the original request.
                return _axios.instance({
                    ...error.config,
                    headers: { ...error.config.headers },
                    _retry: true
                });
            }, () => {
                // Token refresh failed! Throw the original error.
                throw error;
            });
        }
    );

    /**
     * Return the instance from this function directly.
     *
     * In most cases, you'll want to use `useAxios()` to get the Singleton instance.
     */
    return instance;
};

/**
 * Use the properly configured Axios instance. If it does not exist,
 * an error will be thrown.
 *
 * @returns {import('axios').AxiosInstance} Axios Singleton instance.
 */
export const useAxios = () => {
    /** If Singleton is nullish, throw an error. */
    if (!_axios.instance) {
        throw new Error(
            'Axios instance has not been configured. Have you called createAxiosInstance()?'
        );
        return null;
    }
    /** Return the axios instance directly. */
    return _axios.instance;
};

/**
 * Register the Axios plugin.
 *
 * @param {V.App} app Vue application instance.
 * @param {String} key Key to set axios property as.
 * @returns Vue app instance.
 */
export const registerAxios = (app, key = '$axios') => {
    /**
     * Create and configure the axios instance.
     */
    const axios = createAxiosInstance();
    window['axios'] = axios;
    app.config.globalProperties[key] = axios;
    console.log(
        `[plugin::axios] registered @ ${new Date().toLocaleTimeString()}`
    );
    return axios;
};
