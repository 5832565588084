// <!-- TYPES -->
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
import { Model, FORMAT } from '../Model';
import { AccountLevel } from './AccountLevel';
/** @typedef {ReturnType<Account['toPayload']>} AccountPayload */
/** @typedef {ReturnType<Account['toResource']>} AccountResource */

/** Model attribute names. */
export const FIELDS = DynamicEnumFactory().fromKeys(
    /** @type {const} */ ([
        'id',
        'name',
        'email',
        'billingEmail',
        'reminder',
        'timezone',
        'tempScale',
        'expiration',
        'level',
        'usersCount',
        'userLevel',
        'accountTreeLevel',
        'city',
        'state',
        'country',
        'lastUploadDate',
        'dateUpdated',
        'dateCreated',
    ])
);

/** Resource <--> Payload aliases. */
export const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
    [FIELDS.email, 'email'],
    [FIELDS.billingEmail, 'billing_email'],
    [FIELDS.reminder, 'reminder'],
    [FIELDS.timezone, 'timezone'],
    [FIELDS.tempScale, 'temp_scale'],
    [FIELDS.expiration, 'expiration'],
    [FIELDS.level, 'level'],
    [FIELDS.usersCount, 'users_count'],
    [FIELDS.userLevel, 'user_level'],
    [FIELDS.accountTreeLevel, 'account_tree_level'],
    [FIELDS.city, 'city'],
    [FIELDS.state, 'state'],
    [FIELDS.country, 'country'],
    [FIELDS.lastUploadDate, 'last_upload'],
    [FIELDS.dateCreated, 'created_at'],
    [FIELDS.dateUpdated, 'updated_at'],
]);

/** Resource and payload keys. */
export const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * Represents an institution account(s).
 * @class
 * @extends {Model<any, any>}
 */
export class Account extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * @param {AccountPayload} payload
     * @returns {this}
     */
    parsePayload(payload) {
        /** @type {(payload: AccountPayload) => [string, import('@/models/Model').AttributeType][]} */
        const transformPayload = (payload) => {
            const { level, ...attributes } = payload;
            const entity = {
                ...attributes,
                level: new AccountLevel().parsePayload(level || {}),
            };
            return Object.entries(entity);
        };
        super.parsePayload(payload, transformPayload);
        return this;
    }

    /**
     * @param {AccountResource} resource
     * @returns {this}
     */
    parseResource(resource) {
        /** @type {(resource: AccountResource) => [string, import('@/models/Model').AttributeType][]} */
        const transformResource = (resource) => {
            const { level, ...attributes } = resource;
            const entity = {
                ...attributes,
                level: new AccountLevel().parseResource(level),
            };
            return Object.entries(entity);
        };
        super.parseResource(resource, transformResource);
        return this;
    }

    toPayload() {
        /** @type {AccountLevel} */
        const level = this.get(FIELDS.level);

        return {
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].id]: this.get(FIELDS.id),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].name]: this.get(FIELDS.name),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].email]: this.get(FIELDS.email),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].billing_email]: this.get(FIELDS.billingEmail),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].reminder]: this.get(FIELDS.reminder),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].timezone]: this.get(FIELDS.timezone),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].temp_scale]: this.get(FIELDS.tempScale),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].expiration]: this.get(FIELDS.expiration),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].city]: this.get(FIELDS.city),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].state]: this.get(FIELDS.state),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].country]: this.get(FIELDS.country),
            /** @type {import('./AccountLevel').AccountLevelPayload} */
            [KEYS[FORMAT.PAYLOAD].level]: level.toPayload(),
            /** @type {Number} */
            [KEYS[FORMAT.PAYLOAD].users_count]: this.get(FIELDS.usersCount),
            /** @type {String} */
            [KEYS[FORMAT.PAYLOAD].user_level]: this.get(FIELDS.userLevel),
            /** @type {[ site: String, building: String, floor: String, room: String ]} */
            [KEYS[FORMAT.PAYLOAD].account_tree_level]: this.get(
                FIELDS.accountTreeLevel
            ),
            /** @type {Date} */
            [KEYS[FORMAT.PAYLOAD].created_at]: this.get(FIELDS.dateCreated),
            /** @type {Date} */
            [KEYS[FORMAT.PAYLOAD].updated_at]: this.get(FIELDS.dateUpdated),
            /** @type {Date} */
            [KEYS[FORMAT.PAYLOAD].last_upload]: this.get(FIELDS.lastUploadDate),
        };
    }

    toResource() {
        /** @type {AccountLevel} */
        const level = this.get(FIELDS.level);

        return {
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].id]: this.get(FIELDS.id),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].name]: this.get(FIELDS.name),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].email]: this.get(FIELDS.email),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].billingEmail]: this.get(FIELDS.billingEmail),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].reminder]: this.get(FIELDS.reminder),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].timezone]: this.get(FIELDS.timezone),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].tempScale]: this.get(FIELDS.tempScale),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].city]: this.get(FIELDS.city),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].state]: this.get(FIELDS.state),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].country]: this.get(FIELDS.country),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].expiration]: this.get(FIELDS.expiration),
            /** @type {import('./AccountLevel').AccountLevelResource} */
            [KEYS[FORMAT.RESOURCE].level]: level.toResource(),
            /** @type {Number} */
            [KEYS[FORMAT.RESOURCE].usersCount]: this.get(FIELDS.usersCount),
            /** @type {String} */
            [KEYS[FORMAT.RESOURCE].userLevel]: this.get(FIELDS.userLevel),
            /** @type {[ site: String, building: String, floor: String, room: String ]} */
            [KEYS[FORMAT.RESOURCE].accountTreeLevel]: this.get(
                FIELDS.accountTreeLevel
            ),
            /** @type {Date} */
            [KEYS[FORMAT.RESOURCE].lastUploadDate]: this.get(
                FIELDS.lastUploadDate
            ),
            /** @type {Date} */
            [KEYS[FORMAT.RESOURCE].dateCreated]: this.get(FIELDS.dateCreated),
            /** @type {Date} */
            [KEYS[FORMAT.RESOURCE].dateUpdated]: this.get(FIELDS.dateUpdated),
        };
    }
}
