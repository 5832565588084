/**
 * Alert type.
 */
export const AlertType = Object.freeze({
    info: 'info',
    success: 'success',
    failure: 'error',
    error: 'error',
    warning: 'warning',
});

/**
 * Type guard for enum key or value.
 * @template {keyof typeof AlertType} [K=keyof typeof AlertType]
 * @param {K} target
 * @param {keyof typeof AlertType} keyOrValue
 * @returns {key is K}
 */
export const isAlertType = (target, keyOrValue) =>
    target !== null && target !== undefined && target === keyOrValue;

// <!-- DEFAULT -->
export default AlertType;
