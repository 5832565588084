// <!-- API -->
import { ECNBResourceIndexState } from '@/store/types/cache/state';

// <!-- TYPES -->
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * @class
 * Base collection management for a resource index.
 * @extends {ECNBResourceIndexState<Number, LocationResource>}
 */
export class LocationIndexState extends ECNBResourceIndexState {
    /**
     * Initial resource index.
     * @param {Map<Number, LocationResource>} [index]
     * @param {Map<String, any>} [attributes]
     */
    constructor(index = new Map(), attributes = new Map()) {
        super('locations', index, attributes);
    }
}
