// <!-- API -->
import { ECNBStateMutations } from '@/store/types/ECNBStateMutations';

// <!-- TYPES -->
// ts-ignore
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */
import { AccountState } from '@/store/types/accounts/state/AccountState';

/**
 * Synchronous {@link AccountState} instance mutations.
 */
export class AccountStateMutations extends ECNBStateMutations {
    /**
     * Provide access to direct state assignment operations.
     * @param {AccountState} state State instance.
     */
    static set(state) {
        return {
            /**
             * Assign current account.
             * @param {AccountResource} payload
             */
            account: (payload) => {
                state.account = Object.assign({}, payload);
            },
        };
    }
    /**
     * Provide access to state update/merging operations.
     * @param {AccountState} state State instance.
     */
    static patch(state) {
        const $ = AccountStateMutations;
        return {
            /**
             * Update current account.
             * @param {AccountResource} payload
             */
            account: (payload) => {
                const current = state.account;
                const updated = Object.assign({}, current, payload);
                $.set(state).account(updated);
            },
        };
    }
    /**
     * Provide access to state clear operations.
     * @param {AccountState} state State instance.
     */
    static clear(state) {
        const $ = AccountStateMutations;
        return {
            /**
             * Clear current account.
             */
            account: () => {
                $.set(state).account(null);
            },
        };
    }
}
