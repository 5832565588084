// <!-- API -->
import { createEnum } from '@/utils/enums/createEnum';

// <!-- EXPORTS -->//TODO: Enhance with global types.
export const ModalIcon = createEnum({
    info: 'info',
    warning: 'warning',
    success: 'success',
    failure: 'failure',
    error: 'error',
    danger: 'danger',
});

/**
 * Type guard for enum key.
 * @template {keyof ModalIcon} [K=keyof ModalIcon]
 * @param {K} target
 * @param {keyof ModalIcon} key
 * @returns {key is K}
 */
export const isModalIconKey = (target, key) =>
    target !== null && target !== undefined && target === key;

/**
 * Type guard for enum value.
 * @template {ModalIcon[keyof ModalIcon]} [V=ModalIcon[keyof ModalIcon]]
 * @param {V} target
 * @param {ModalIcon[keyof ModalIcon]} value
 * @returns {value is V}
 */
export const isModalIcon = (target, value) =>
    target !== null && target !== undefined && target === value;

// <!-- DEFAULT -->
export default ModalIcon;
