// <!-- TYPES -->
import { Model, FORMAT } from '@/models/Model';
import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
/** @typedef {ReturnType<LocationMetadata['toPayload']>} LocationMetadataPayload */
/** @typedef {ReturnType<LocationMetadata['toResource']>} LocationMetadataResource */
/** @typedef {{ [filename: String ]: Number }} LocationSuggestion */

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys(['suggestedLocations']);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs([
    [FIELDS.suggestedLocations, 'suggested_locations'],
]);

/**
 * @class
 */
export class LocationMetadata extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable([
            [KEYS[0].suggestedLocations, KEYS[1].suggested_locations],
        ]);
    }

    toPayload() {
        const payload = {
            /** @type {{ [filename: String ]: Number }} */
            [KEYS[FORMAT.PAYLOAD].suggested_locations]: this.get(
                FIELDS.suggestedLocations
            ),
        };
        return payload;
    }

    toResource() {
        const resource = {
            /** @type {{ [filename: String ]: Number }} */
            [KEYS[FORMAT.RESOURCE].suggestedLocations]: this.get(
                FIELDS.suggestedLocations
            ),
        };
        return resource;
    }
}
