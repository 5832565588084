// <!-- API -->
import ActionLogger from '@/utils/ActionLogger';
import { useECNBStore } from './useECNBStore';

// <!-- TYPES -->
import { Store } from 'vuex';
import { ECNBStore, ECNBState } from '@/store/types/ECNBStore';
/** @typedef {import('@/models/accounts/Account').AccountResource} AccountResource */

/**
 * Get the computed property, getters, and actions.
 * @param {Store<ECNBState>} [store] Vuex store reference.
 */
export const useECNBProfile = (store = null) => {
    // Wrap the provided store or create a new one.
    const $ = useECNBStore(store);
    const profile = new ECNBProfile($.api.store);
    return profile;
};

/**
 * @class
 * ECNBStore wrapper.
 */
export class ECNBProfile extends ECNBStore {
    /**
     * Create specialized {@link ECNBStore} wrapper.
     * @param {Store<ECNBState>} store Vuex store reference.
     */
    constructor(store) {
        super(store);
        /**
         * Get account as computed property.
         */
        this.account = this.api.create.computed.accounts.account;
        /**
         * Get user as computed property.
         */
        this.user = this.api.create.computed.users.user;
    }

    /**
     * Is the primary user account an administrator level account?
     * @returns {Boolean}
     */
    get isAdmin() {
        return this.api.getters['isAdmin'];
    }

    /**
     * Is there a current user that is logged in?
     * @returns {Boolean}
     */
    get isLoggedIn() {
        return this.api.getters['isLoggedIn'];
    }

    /**
     * Get the account hierarchy labels.
     *
     * @returns {{ labels: [ site: String, building: String, floor: String, room: String ]; site: String; building: String; floor: String; room: String }}
     */
    get hierarchy() {
        return this.api.getters['AccountTreeLevel'];
    }

    /**
     * Get the account timezone.
     * @returns {String}
     */
    get timezone() {
        return this.api.getters['AccountTimezone'];
    }

    /**
     * Dispatch event to the store. Fetches the current user's primary account details.
     * @param {Number} id Account id.
     * @returns {Promise<AccountResource>} Unresolved promise.
     */
    fetchSelectedAccount(id) {
        return this.api.dispatch('fetchSelectedAccount', { id });
    }

    /**
     * Dispatch event to the store. Fetches the current user's details.
     * @returns {Promise<import('@/api/profile').CurrentUserProfileResource>} Unresolved promise.
     */
    fetchCurrentUser() {
        return this.api.dispatch('fetchCurrentUser');
    }

    /**
     * Fetch session information and return reference to self (so one can get the account and user).
     * @param {Boolean} [ignoreCache] Force refetch.
     * @returns {Promise<this>} Unresolved promise.
     */
    async getSessionInformation(ignoreCache = false) {
        try {
            console.groupCollapsed(
                `[session::info] @ ${new Date().toLocaleString()}`
            );
            const notify = ActionLogger.log(`[session::info]`);
            if (
                ignoreCache === true ||
                !this.context.state.accounts.hasAccount ||
                !this.context.state.users.hasUser
            ) {
                try {
                    // Fetch (and assign) the current user, if already authenticated.
                    const authenticatedUser = await this.fetchCurrentUser();
                    const isAuthenticatedUser =
                        !!authenticatedUser && !!authenticatedUser?.id;

                    if (isAuthenticatedUser) {
                        // If user is logged in...
                        // ...check if session already has a selected account...
                        const selectedAccountID = parseInt(
                            sessionStorage.getItem('selected_account'),
                            10
                        );
                        const isValidAccountID =
                            !Number.isNaN(selectedAccountID);
                        if (isValidAccountID) {
                            // If valid account id...
                            // ...fetch (and assign) the current session's selected account...
                            const account = await this.fetchSelectedAccount(
                                selectedAccountID
                            );
                        } else {
                            throw new Error(
                                `No account is currently selected.`
                            );
                        }
                    } else {
                        // If user is not logged in...
                        // ...clear the selected account from session storage, if present...
                        sessionStorage.removeItem('selected_account');
                        // ...throw an error to signal problem...
                        throw new Error(`No user is currently logged in.`);
                    }
                    notify.success(
                        `Successfully requested session user and selected account.`
                    );
                } catch (error) {
                    notify.failure(error);
                }
            } else {
                notify.warning(`Cached user information.`);
            }
        } finally {
            console.groupEnd();
        }
        return this;
    }
}

// DEFAULT
export default useECNBProfile;
