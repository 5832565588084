<template>
    <div>
        <!-- MODAL -->
        <BasicModal
            :show="isSwitchAccountOpen"
            @close="onClick.button.closeSwitchAccountModal"
        >
            <SwitchAccountGrid
                label="Switch Account"
                @select="
                    [
                        onClick.button.selectAccount(),
                        onClick.button.closeSwitchAccountModal(),
                    ]
                "
                @error="$log('Failed to select an account...', $event)"
            />
        </BasicModal>
        <!-- NAVIGATION -->
        <div class="min-h-full">
            <!-- MAIN NAVIGATION -->
            <nav
                id="main-navigation"
                role="navigation"
                class="bg-primary-800"
            >
                <!-- NAVIGATION BAR -->
                <div
                    id="navigation-bar"
                    class="mx-auto px-4 sm:px-6 lg:px-8"
                >
                    <!-- BRAND, NAVLINKS, CTA and MENU BUTTON -->
                    <div
                        class="flex flex-row justify-between items-center h-16"
                    >
                        <!-- SITE NAVLINKS -->
                        <div
                            class="flex flex-row justify-start items-center h-full"
                        >
                            <!-- BRAND -->
                            <div
                                id="brand"
                                class="flex-shrink-0 mr-5 lg:mr-10"
                            >
                                <router-link
                                    id="logo"
                                    :to="isPublicRoute ? '/analysis' : '#'"
                                    class="flex h-full justify-center items-center gap-x-2"
                                    :class="{
                                        'cursor-default': !isPublicRoute,
                                        'group cursor-pointer hover:shadow-xl':
                                            isPublicRoute,
                                    }"
                                    exact
                                >
                                    <ECNBLogo
                                        class="relative top-1"
                                        :class="{
                                            'group-hover:animate-pulse':
                                                isPublicRoute,
                                        }"
                                    ></ECNBLogo>
                                    <NARALogo
                                        class="relative"
                                        :class="{
                                            'group-hover:animate-pulse':
                                                isPublicRoute,
                                        }"
                                    ></NARALogo>
                                </router-link>
                            </div>
                            <!-- NAVLINKS -->
                            <div
                                class="hidden md:block h-full"
                                v-if="isPublicRoute && !isSelectAccountRoute"
                            >
                                <div
                                    class="flex flex-row justify-center items-center text-center h-full text-sm lg:text-base font-medium"
                                >
                                    <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                                    <router-link
                                        :class="[
                                            'flex items-center text-center h-full active:bg-white active:bg-opacity-10',
                                            $route.meta.namespace !== 'analysis'
                                                ? 'hover:animate-pulse text-white opacity-65 hover:opacity-100 bg-gray-900 bg-opacity-0 hover:bg-opacity-10'
                                                : 'text-white opacity-75 hover:opacity-100 bg-white bg-opacity-5 hover:bg-opacity-10',
                                        ]"
                                        to="/analysis"
                                    >
                                        <div class="mx-2 md:mx-4 lg:mx-6">
                                            Data Analysis
                                        </div>
                                    </router-link>
                                    <router-link
                                        :class="[
                                            'flex items-center text-center h-full active:bg-white active:bg-opacity-10',
                                            $route.meta.namespace !==
                                            'note-manager'
                                                ? 'hover:animate-pulse text-white opacity-65 hover:opacity-100 bg-gray-900 bg-opacity-0 hover:bg-opacity-10'
                                                : 'text-white opacity-75 hover:opacity-100 bg-white bg-opacity-5 hover:bg-opacity-10',
                                        ]"
                                        to="/notes"
                                        :key="$route.fullPath"
                                    >
                                        <div class="mx-2 md:mx-4 lg:mx-6">
                                            Note Manager
                                        </div>
                                    </router-link>
                                    <router-link
                                        :class="[
                                            'flex items-center text-center h-full active:bg-white active:bg-opacity-10',
                                            $route.meta.namespace !==
                                            'data-manager'
                                                ? 'hover:animate-pulse text-white opacity-65 hover:opacity-100 bg-gray-900 bg-opacity-0 hover:bg-opacity-10'
                                                : 'text-white opacity-75 hover:opacity-100 bg-white bg-opacity-5 hover:bg-opacity-10',
                                        ]"
                                        to="/data-manager"
                                        :key="$route.fullPath"
                                    >
                                        <div class="mx-2 md:mx-4 lg:mx-6">
                                            Data Manager
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- CTA, and USER MENU BUTTON -->
                        <div
                            v-if="isPublicRoute"
                            class="hidden lg:flex flex-row justify-center items-center h-full gap-x-4"
                        >
                            <!-- UPLOAD DATA CALL TO ACTION -->
                            <div
                                id="btn-upload"
                                class="flex items-center h-full whitespace-nowrap"
                                v-if="!isSelectAccountRoute"
                            >
                                <router-link
                                    v-if="
                                        !isUploadRoute &&
                                        isDataAnalyst === false
                                    "
                                    to="/upload"
                                    variant="secondary"
                                    class="bg-secondary-500 text-black px-3 py-2 rounded-md text-sm font-medium hover:bg-secondary-400"
                                    :class="[
                                        'shadow-none hover:shadow-xl',
                                        'border-2 border-transparent hover:border-white active:border-primary-500',
                                        'transform scale-90 hover:scale-110 active:scale-100',
                                        'transition-all duration-150 ease-in-out',
                                    ]"
                                >
                                    Upload Data
                                </router-link>
                                <a
                                    v-else-if="isDataAnalyst === false"
                                    :href="$router.resolve('/upload').href"
                                    variant="secondary"
                                    class="bg-secondary-500 text-black px-3 py-2 rounded-md text-sm font-medium hover:bg-secondary-400"
                                    :class="[
                                        'shadow-none hover:shadow-xl',
                                        'border-2 border-transparent hover:border-white active:border-primary-500',
                                        'transform scale-90 hover:scale-110 active:scale-100',
                                        'transition-all duration-150 ease-in-out',
                                    ]"
                                >
                                    Upload Data
                                </a>
                            </div>
                            <!-- USER MENU BUTTON (GRAVATAR) -->
                            <div
                                id="btn-gravatar"
                                class="flex-shrink-0 flex items-center h-full"
                            >
                                <VariantButton
                                    variant="avatar"
                                    type="button"
                                    id="user-menu-button"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                    :class="[
                                        'shadow-none hover:shadow-xl',
                                        'border-2 border-transparent hover:border-white active:border-primary-500',
                                        'transform scale-100 hover:scale-125 active:scale-100',
                                        'transition-all duration-150 ease-in-out',
                                    ]"
                                    @click="onToggle.userButton('click')"
                                    @blur="onToggle.userButton('blur')"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <Gravatar
                                        :email="user?.email"
                                        :size="80"
                                        class="h-8 w-8 rounded-full"
                                    />
                                </VariantButton>
                            </div>
                            <!-- USER MENU DROPDOWN -->
                            <div
                                id="user-menu-items"
                                class="user-dropdown absolute origin-top-right top-14 right-8 w-48 py-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="user-menu-button"
                                tabindex="-1"
                                @focusout.stop="onToggle.userButton('blur')"
                                @focusin.stop="onToggle.userButton('click')"
                            >
                                <!-- Active: "bg-gray-100", Not Active: "" -->
                                <div
                                    class="px-4 py-1 text-sm font-medium leading-none text-gray"
                                >
                                    {{ user?.username }}
                                </div>
                                <div
                                    class="px-4 text-sm font-medium leading-none text-gray-400 mb-2"
                                >
                                    {{ account?.name }}
                                </div>
                                <hr />
                                <VariantButton
                                    variant="menu-item"
                                    role="menuitem"
                                    id="user-menu-item-2"
                                    @mousedown="
                                        onClick.button.openSwitchAccountModal()
                                    "
                                    @click="onToggle.userButton('click')"
                                    v-if="!isSelectAccountRoute"
                                >
                                    <span class="flex">
                                        <p>Switch Account</p>
                                        <SwitchHorizontalIcon
                                            class="absolute right-3 ml-1 mt-.5 pt-0.5 h-5 w-5"
                                        />
                                    </span>
                                </VariantButton>
                                <hr />
                                <VariantButton
                                    variant="menu-item"
                                    role="menuitem"
                                    id="user-menu-item-2"
                                    @mousedown="onClick.link.adminRedirect()"
                                    @click="onToggle.userButton('click')"
                                    v-if="
                                        isAdministrator && !isSelectAccountRoute
                                    "
                                >
                                    Admins
                                </VariantButton>
                                <hr v-if="!isSelectAccountRoute" />
                                <VariantButton
                                    variant="menu-item"
                                    role="menuitem"
                                    tabindex="-1"
                                    id="user-menu-item-2"
                                    @mousedown="
                                        onClick.link.loginRedirect(),
                                            onClick.link.logoutUser()
                                    "
                                    @blur="onToggle.userButton('blur')"
                                    @click="onToggle.userButton('click')"
                                >
                                    <div class="flex items-stretch">
                                        <p>Logout</p>
                                        <LogoutIcon
                                            class="absolute right-3 ml-1 mt-.5 pt-0.5 h-5 w-5"
                                        />
                                    </div>
                                </VariantButton>
                            </div>
                        </div>
                        <!-- HAMBURGER MENU BUTTON -->
                        <div
                            class="flex lg:hidden relative left-2"
                            v-if="isPublicRoute"
                        >
                            <!-- Mobile menu button -->
                            <VariantButton
                                variant="hamburger"
                                type="button"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                                @click.stop="onToggle.userMobileButton"
                            >
                                <span class="sr-only">Open main menu</span>
                                <!--
                                    Heroicon name: outline/menu
                                    Menu open: "hidden", Menu closed: "block"
                                -->
                                <svg
                                    class="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                                <!--
                                    Heroicon name: outline/x
                                    Menu open: "block", Menu closed: "hidden"
                                -->
                                <svg
                                    class="hidden h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </VariantButton>
                        </div>
                    </div>
                </div>
                <!-- MOBILE MENU -->
                <div
                    class="xl:hidden"
                    id="mobile-menu"
                    v-if="isPublicRoute"
                >
                    <div
                        class="px-2 pt-2 pb-3 sm:px-3"
                        v-if="!isSelectAccountRoute"
                    >
                        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                        <router-link
                            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                            to="/analysis"
                            @click="onToggle.userMobileButton"
                        >
                            Data Analysis
                        </router-link>
                        <div
                            class="pl-8"
                            v-if="isAnalysisRoute"
                        >
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/analysis"
                                @click="onToggle.userMobileButton"
                            >
                                Graph
                            </router-link>
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/analysis/statistics"
                                @click="onToggle.userMobileButton"
                            >
                                Statistics
                            </router-link>
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/analysis/compare"
                                @click="onToggle.userMobileButton"
                            >
                                Compare Metrics
                            </router-link>
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/analysis/narastandards"
                                @click="onToggle.userMobileButton"
                            >
                                NARA Standards
                            </router-link>
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/analysis/reports"
                                @click="onToggle.userMobileButton"
                            >
                                Reports
                            </router-link>
                            <a
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                :href="Links.RESOURCES"
                                target="_blank"
                            >
                                Resources
                                <ExternalLinkIcon
                                    class="inline-block relative -top-0.5 h-4 w-4"
                                    aria-hidden="true"
                                />
                            </a>
                            <a
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                :href="Links.DEW_POINT_CALCULATOR"
                                target="_blank"
                            >
                                Dew Point Calculator
                                <ExternalLinkIcon
                                    class="inline-block relative -top-0.5 h-4 w-4"
                                    aria-hidden="true"
                                />
                            </a>
                        </div>
                        <router-link
                            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                            to="/notes"
                            @click="onToggle.userMobileButton"
                        >
                            Note Manager
                        </router-link>
                        <router-link
                            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                            to="/data-manager"
                            @click="onToggle.userMobileButton"
                        >
                            Data Manager
                        </router-link>
                        <div
                            class="pl-8"
                            v-if="isDataManagerRoute"
                        >
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/data-manager/locations"
                                @click="onToggle.userMobileButton"
                            >
                                Locations
                            </router-link>
                            <router-link
                                class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/data-manager/weather-stations"
                                @click="onToggle.userMobileButton"
                            >
                                Weather Stations
                            </router-link>
                        </div>
                        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                        <router-link
                            v-if="!isUploadRoute && isDataAnalyst === false"
                            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                            to="/upload"
                            @click="onToggle.userMobileButton"
                        >
                            Upload Data
                        </router-link>
                        <a
                            v-else-if="isDataAnalyst === false"
                            :href="$router.resolve('/upload').href"
                            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                            @click="onToggle.userMobileButton"
                        >
                            <!-- 
                                // HACK: When on the Upload Data page,
                                //  this uses a normal link instead of router link due to
                                //  the Vue creators' opinion that we shouldn't be forcing a page reload.
                                //  We should eventually enact an actual page reload change.
                                //  but this will require further research.
                                //  See: https://stackoverflow.com/questions/51717785/vue-routers-router-link-with-actual-refresh
                                //  See: https://github.com/vuejs/vue-router/issues/2430
                            -->
                            Upload Data
                        </a>
                    </div>
                    <div class="pt-4 pb-3 border-t border-gray-500">
                        <div class="flex items-center px-5">
                            <div class="flex-shrink-0">
                                <Gravatar
                                    :email="user?.email"
                                    :size="80"
                                    class="h-10 w-10 rounded-full mr-3"
                                ></Gravatar>
                            </div>
                            <div class="ml-3">
                                <div
                                    class="text-base font-medium leading-none text-white"
                                >
                                    {{ user?.username }}
                                </div>
                                <div
                                    class="text-sm font-medium leading-none text-gray-400 pt-2"
                                >
                                    {{ account?.name }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="ml-2 mt-2"
                            v-if="!isSelectAccountRoute"
                        >
                            <a
                                class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-primary-700"
                                href="#"
                                @click="
                                    [
                                        onClick.button.openSwitchAccountModal(),
                                        onToggle.userMobileButton(),
                                    ]
                                "
                            >
                                <SwitchHorizontalIcon
                                    class="absolute right-3 ml-1 mt-.5 pt-0.5 h-5 w-5"
                                />
                                Switch Account
                            </a>
                        </div>
                        <div class="px-2 space-y-1">
                            <router-link
                                class="text-gray-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                to="/admin"
                                @click="onToggle.userMobileButton"
                                v-if="isAdministrator && !isSelectAccountRoute"
                            >
                                Admins
                            </router-link>
                            <div
                                class="pl-8"
                                v-if="isAdministrator && isAdminRoute"
                            >
                                <!-- TODO: Do we need an explicit accounts link? -->
                                <router-link
                                    class="text-gray-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                    to="/admin/accounts"
                                    @click="onToggle.userMobileButton"
                                >
                                    Accounts
                                </router-link>
                                <router-link
                                    class="text-gray-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                    to="/admin/users"
                                    @click="onToggle.userMobileButton"
                                >
                                    Users
                                </router-link>
                                <router-link
                                    class="text-gray-400 hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:bg-primary-700"
                                    to="/admin/nara-standards"
                                    @click="onToggle.userMobileButton"
                                >
                                    NARA Standards
                                </router-link>
                            </div>
                            <div
                                class="pl-8"
                                v-if="isAccountSettingsRoute"
                            >
                                <router-link
                                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-primary-700"
                                    to="/account/information"
                                    @click="onToggle.userMobileButton"
                                    v-if="isAdministrator"
                                >
                                    Account Information
                                </router-link>
                                <router-link
                                    class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-primary-700"
                                    to="/account/user-manager"
                                    @click="onToggle.userMobileButton"
                                    v-if="isAdministrator"
                                >
                                    User Manager
                                </router-link>
                            </div>
                            <router-link
                                class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-primary-700"
                                @click="
                                    [
                                        onClick.link.loginRedirect(),
                                        onClick.link.logoutUser(),
                                        onToggle.userMobileButton(),
                                    ]
                                "
                                to="/login"
                            >
                                <LogoutIcon
                                    class="absolute right-3 ml-1 mt-.5 pt-0.5 h-5 w-5"
                                />
                                Logout
                            </router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, ref, onMounted, nextTick } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import router, { isUserAuthenticated, isUserAuthorized } from '@/router';

    // <!-- COMPONENTS -->
    import Gravatar from '@/components/Gravatar.vue';
    import ECNBLogo from '@/components/brand/ECNBLogo.vue';
    import NARALogo from '@/components/brand/NARALogo.vue';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import BasicModal from '@components/BasicModal.vue';
    import SwitchAccountGrid from '@/features/switch-account/components/SwitchAccountGrid.vue';
    import {
        LogoutIcon,
        SwitchHorizontalIcon,
        ExternalLinkIcon,
    } from '@heroicons/vue/outline';

    // <!-- COMPOSABLES -->
    // import { useMarketingDomain } from '@/hooks/env/useMarketingDomain';
    import useSwitchAccountModal from '@/hooks/useSwitchAccountModal';

    // <!-- TYPES -->

    /** @template [S=any] @typedef {import('vuex').Store<S>} Store<S> */
    import { ECNBState } from '@/store/types/ECNBStore';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'MainNav',
        props: {
            /** TODO: This prop is not currently used. Consider removing it. */
            onClose: {
                /** @type {V.PropType<Function>} */
                type: Function,
                default: () => false,
            },
        },
        components: {
            Gravatar,
            ECNBLogo,
            NARALogo,
            VariantButton,
            BasicModal,
            SwitchAccountGrid,
            ExternalLinkIcon,
            LogoutIcon,
            SwitchHorizontalIcon,
        },
        setup(props, context) {
            // ==== TEMPLATE REFS ====

            // ==== COMPOSABLES ====
            const {
                openModal,
                isSwitchAccountOpen,
                setOpenModal,
                onShow,
                onHide,
            } = useSwitchAccountModal();
            // const { getMarketingLink } = useMarketingDomain();

            // ==== STATE ====
            const Links = Object.freeze({
                RESOURCES:
                    'https://imagepermanenceinstitute.org/education/publications.html',
                // RESOURCES: getMarketingLink('resources/docs/getting-started'),
                DEW_POINT_CALCULATOR: 'http://dpcalc.org',
            });

            // ==== SESSION ====
            /**
             * Define session computed properties.
             * @param {Store<ECNBState>} store
             */
            const useSessionProperties = (store) => {
                /** @type {V.ComputedRef<Store<ECNBState>['state']['accounts']['account']>} */
                const account = computed(() => {
                    return store.state.accounts.account;
                });

                /** @type {V.ComputedRef<Store<ECNBState>['state']['users']['me']>} */
                const user = computed(() => {
                    return store.state.users.me;
                });

                /** @type {V.ComputedRef<Boolean>} */
                const isAdministrator = computed(
                    () => user.value?.role === 'admin'
                );

                /** @type {V.ComputedRef<Boolean>} */
                const isDataAnalyst = computed(
                    () => user.value?.role === 'data-analyst'
                );

                /** @type {V.ComputedRef<Boolean>} */
                const isAuthenticatedUser = computed(() =>
                    isUserAuthenticated()
                );

                return {
                    store,
                    account,
                    user,
                    isAdministrator,
                    isDataAnalyst,
                    isAuthenticatedUser,
                };
            };

            // ==== ROUTE ====
            /**
             * Define route computed properties.
             * @param {ReturnType<useRoute>} route
             */
            const useRouteProperties = (route) => {
                /** @type {V.ComputedRef<Boolean>} */
                const isPublicRoute = computed(
                    () =>
                        !['Login', 'Sign Up', 'Forgot Password'].includes(
                            String(route.name)
                        ) && isUserAuthenticated()
                );
                /** @type {V.ComputedRef<Boolean>} */
                const isSelectAccountRoute = computed(
                    () =>
                        ['Select Account'].includes(String(route.name)) &&
                        isUserAuthenticated()
                );
                /** @type {V.ComputedRef<route['name']>} */
                const currentRouteName = computed(() => route.name);

                /** @type {V.ComputedRef<Boolean>} */
                const isAnalysisRoute = computed(() => {
                    const currentRoute = currentRouteName.value;
                    return (
                        currentRoute == 'Analysis' ||
                        currentRoute == 'Compare' ||
                        currentRoute == 'Reports' ||
                        currentRoute == 'Analysis - NARA Standards' ||
                        currentRoute == 'Statistics'
                    );
                });

                /** @type {V.ComputedRef<Boolean>} */
                const isDataManagerRoute = computed(() => {
                    const currentRoute = currentRouteName.value;
                    return (
                        currentRoute == 'Locations' ||
                        currentRoute == 'Weather Stations'
                    );
                });

                /** @type {V.ComputedRef<Boolean>} */
                const isAccountSettingsRoute = computed(() => {
                    const currentRoute = currentRouteName.value;
                    return (
                        currentRoute == 'Account Information' ||
                        currentRoute == 'User Manager'
                    );
                });

                /** @type {V.ComputedRef<Boolean>} */
                const isUploadRoute = computed(() => {
                    const currentRoute = currentRouteName.value;
                    return (
                        currentRoute === 'CSV Uploader' ||
                        currentRoute === 'Upload'
                    );
                });

                /** @type {V.ComputedRef<Boolean>} */
                const isAdminRoute = computed(() => {
                    const currentRoute = currentRouteName.value;
                    return (
                        currentRoute == 'Administrator Dashboard' ||
                        currentRoute == 'Admin Accounts' ||
                        currentRoute == 'Admin Users' ||
                        currentRoute == 'NARA Standards'
                    );
                });

                return {
                    currentRouteName,
                    isPublicRoute,
                    isSelectAccountRoute,
                    isAnalysisRoute,
                    isDataManagerRoute,
                    isAccountSettingsRoute,
                    isUploadRoute,
                    isAdminRoute,
                };
            };

            // ==== NAVIGATION ====
            /**
             * Define page methods.
             */
            const useNavigationMethods = () => {
                /** @param {'mobile-menu' | 'user-menu-items'} id */
                const onRefreshElement = (id) => {
                    const element = document.getElementById(id);
                    if (!!element) {
                    }
                };

                /** Check the hide menu. */
                const onCheckHideMenu = () => {
                    if (document.documentElement.clientWidth > 1280) {
                        const userDrop = document.getElementById('mobile-menu');
                        if (userDrop) {
                            userDrop.style.display = 'none';
                        }
                    }
                };

                /** Define toggle functions. */
                const onToggle = () => ({
                    userButton: (blurClick) => {
                        const userDrop =
                            document.getElementById('user-menu-items');
                        if (blurClick === 'blur') {
                            userDrop.style.display = 'none';
                        } else if (
                            userDrop.style.display === 'none' ||
                            !userDrop.style.display
                        ) {
                            userDrop.style.display = 'block';
                        } else {
                            userDrop.style.display = 'none';
                        }
                    },
                    userMobileButton: () => {
                        const userDrop = document.getElementById('mobile-menu');
                        if (
                            userDrop.style.display === 'none' ||
                            !userDrop.style.display
                        ) {
                            userDrop.style.display = 'block';
                        } else {
                            userDrop.style.display = 'none';
                        }
                    },
                });

                /** Define click functions. */
                const onClick = () => ({
                    get link() {
                        const logoutUser = async () => {
                            loginRedirect(); // Trigger redirect and then clear state.
                            await store.dispatch(`logout`);
                        };
                        const loginRedirect = async () => {
                            await router.push('/login');
                        };
                        const accountSettingsRedirect = async () => {
                            await router.push('/account/information');
                        };
                        const adminRedirect = async () => {
                            await router.push('/admin');
                        };
                        const adminAccountsRedirect = async () => {
                            await router.push('/admin/accounts');
                        };
                        const adminUsersRedirect = async () => {
                            await router.push('/admin/users');
                        };
                        const adminNARAStandardsRedirect = async () => {
                            await router.push('/admin/nara-standards');
                        };
                        return {
                            logoutUser,
                            loginRedirect,
                            accountSettingsRedirect,
                            adminRedirect,
                            adminAccountsRedirect,
                            adminUsersRedirect,
                            adminNARAStandardsRedirect,
                        };
                    },
                    get button() {
                        const openSwitchAccountModal = () => {
                            onShow();
                        };
                        const closeSwitchAccountModal = () => {
                            onHide();
                        };
                        const selectAccount = () => {
                            onHide();
                        };
                        return {
                            openSwitchAccountModal,
                            closeSwitchAccountModal,
                            selectAccount,
                        };
                    },
                });

                return {
                    onCheckHideMenu,
                    get onToggle() {
                        return onToggle();
                    },
                    get onClick() {
                        return onClick();
                    },
                };
            };

            // ==== COMPOSABLES ====
            const {
                store,
                account,
                user,
                isAdministrator,
                isDataAnalyst,
                isAuthenticatedUser,
            } = useSessionProperties(useStore());
            const {
                currentRouteName,
                isPublicRoute,
                isSelectAccountRoute,
                isAnalysisRoute,
                isAccountSettingsRoute,
                isDataManagerRoute,
                isAdminRoute,
                isUploadRoute,
            } = useRouteProperties(useRoute());
            const { onCheckHideMenu, onToggle, onClick } =
                useNavigationMethods();

            // ==== LIFECYCLE ====
            onMounted(async () => {
                await nextTick(function () {
                    window.addEventListener('resize', onCheckHideMenu);
                });

                if (isAdministrator.value) {
                    console.groupCollapsed(
                        `[initial::load] - '${router.currentRoute.value.fullPath}'`
                    );
                    console.log(`Logged in under administrator account.`);
                    console.table({
                        currentRoutePath: router.currentRoute.value.path,
                        currentRouteHash: router.currentRoute.value.hash,
                        currentRouteName: currentRouteName.value,
                        isAnalysisRoute: isAnalysisRoute.value,
                        isAccountSettingsRoute: isAccountSettingsRoute.value,
                        isDataManagerRoute: isDataManagerRoute.value,
                        isUploadRoute: isUploadRoute.value,
                        isAdminRoute: isAdminRoute.value,
                        redirectedFrom:
                            router.currentRoute.value.redirectedFrom
                                ?.fullPath ?? null,
                    });
                    console.table({
                        isPublicRoute: isPublicRoute.value,
                        isAdministrator: isAdministrator.value,
                        isAuthenticatedUser: isAuthenticatedUser.value,
                    });
                    console.groupEnd();
                }
            });

            // ==== EXPOSE ====
            return {
                // CONSTANTS
                Links,
                // STATE
                account,
                user,
                // CONDITIONALS
                isAdministrator,
                isDataAnalyst,
                isAuthenticatedUser,
                currentRouteName,
                isPublicRoute,
                isSelectAccountRoute,
                isDataManagerRoute,
                isAnalysisRoute,
                isAccountSettingsRoute,
                isUploadRoute,
                isAdminRoute,
                // EVENTS
                onToggle,
                onClick,
                onShow,
                onHide,
                onCheckHideMenu,
                openModal,
                isSwitchAccountOpen,
                setOpenModal,
            };
        },
    });
</script>

<style lang="scss">
    .main-nav-link.router-link-active {
        background-color: hsla(0, 0%, 100%, 0.15);
        opacity: 1;
    }

    #user-menu-items {
        display: none;
        z-index: 25;
    }

    #mobile-menu {
        display: none;
        z-index: 20;
    }

    .hamburger-menu:hover {
        background-color: #315779;
    }
</style>
