// <!-- TYPES -->
import { Model, FORMAT } from '@/models/Model';

import {
    DynamicEnumFactory,
    DynamicEnumGridFactory,
} from '@/utils/DynamicEnum';
import {
    NoteLocationHierarchy,
    NoteLocationHierarchyPayload,
    NoteLocationHierarchyResource,
} from '@/models/notes/NoteLocationHierarchy';

/** Model attribute names. */
const FIELDS = DynamicEnumFactory().fromKeys([
    'id',
    'name',
    'hierarchy',
    'hierarchyId',
]);

/** Resource <--> Payload aliases. */
const ALIASES = /** @type {const} */ ([
    [FIELDS.id, 'id'],
    [FIELDS.name, 'name'],
    [FIELDS.hierarchyId, 'location_hierarchy_id'],
    [FIELDS.hierarchy, 'hierarchy'],
]);

/** Resource and payload keys. */
const KEYS = DynamicEnumGridFactory().fromPairs(ALIASES);

/**
 * @class
 */
export class NoteLocationPayload {
    /** @type {Number} Location identifier. */
    id = null;
    /** @type {String} Location name. */
    name = '';
    /** @type {NoteLocationHierarchyPayload['id']} Hierarchy identifier. */
    location_hierarchy_id = null;
    /** @type {NoteLocationHierarchyPayload[]} */
    hierarchy = [];

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteLocationPayload>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the payload.
     * @param {NoteLocation} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);
        this.location_hierarchy_id = model.get(FIELDS.hierarchyId);

        /** @type {NoteLocationHierarchy[]} */
        const hierarchy = model.get(FIELDS.hierarchy) ?? [];
        this.hierarchy = hierarchy.map((node) => node.toPayload());

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {NoteLocationHierarchy[]} */
        const hierarchy = this.hierarchy.map(
            (node) =>
                new NoteLocationHierarchy({
                    payload: new NoteLocationHierarchyPayload(node),
                })
        );

        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
            [FIELDS.hierarchyId, this.location_hierarchy_id],
            [FIELDS.hierarchy, hierarchy],
        ];
    }
}

/**
 * @class
 */
export class NoteLocationResource {
    /** @type {Number} Location identifier. */
    id = null;
    /** @type {String} Location name. */
    name = '';
    /** @type {NoteLocationHierarchyResource['id']} Hierarchy identifier. */
    hierarchyId = null;
    /** @type {NoteLocationHierarchyResource[]} */
    hierarchy = [];

    /**
     * Assign attributes from another instance.
     * @param {Partial<import('@/types').ExcludeMethods<NoteLocationResource>>} attributes
     */
    constructor(attributes = {}) {
        Object.assign(this, attributes);
    }

    /**
     * Parse model state to get the payload.
     * @param {NoteLocation} model
     * @returns {this}
     */
    parseModel(model) {
        this.id = model.get(FIELDS.id);
        this.name = model.get(FIELDS.name);
        this.hierarchyId = model.get(FIELDS.hierarchyId);

        /** @type {NoteLocationHierarchy[]} */
        const hierarchy = model.get(FIELDS.hierarchy) ?? [];
        this.hierarchy = hierarchy.map((node) => node.toResource());

        // Return parsed instance.
        return this;
    }

    /**
     * Convert this payload into a model.
     * @returns {readonly [typeof FIELDS[keyof FIELDS], any][]}
     */
    entries() {
        /** @type {NoteLocationHierarchy[]} */
        const hierarchy = this.hierarchy.map(
            (node) =>
                new NoteLocationHierarchy({
                    resource: new NoteLocationHierarchyResource(node),
                })
        );

        /** @type {readonly [typeof FIELDS[keyof FIELDS], any][]} */
        return [
            [FIELDS.id, this.id],
            [FIELDS.name, this.name],
            [FIELDS.hierarchyId, this.hierarchyId],
            [FIELDS.hierarchy, hierarchy],
        ];
    }
}

/**
 * @class
 * @extends {Model<NoteLocationPayload, NoteLocationResource>}
 */
export class NoteLocation extends Model {
    _initialState() {
        return Model.ComposeStateUsingFields(FIELDS);
    }

    _registerAliases() {
        return Model.ComposeAliasesUsingTable(ALIASES);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<NoteLocationPayload>} payload
     * @returns {this}
     */
    parsePayload(payload) {
        const instance = new NoteLocationPayload(payload);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {NoteLocationPayload}
     */
    toPayload() {
        return new NoteLocationPayload().parseModel(this);
    }

    /**
     * Parse model from data structure.
     * @param {import('@/types').ExcludeMethods<NoteLocationResource>} resource
     * @returns {this}
     */
    parseResource(resource) {
        const instance = new NoteLocationResource(resource);
        return this.parseArray(instance.entries());
    }

    /**
     * Transform model into data structure.
     * @returns {NoteLocationResource}
     */
    toResource() {
        return new NoteLocationResource().parseModel(this);
    }
}
