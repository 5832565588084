// <!-- UTILITIES -->
import pick from 'just-pick';
import lte from 'lodash-es/lte';
import { toDate, isValid, clamp } from 'date-fns';

// <!-- TYPES -->
/** @typedef {import('@/utils/filters').IDate} IDate A native {@link Date} instance or JavaScript {@link ITimestamp}. */
/** @typedef {import('@/utils/filters').ITimestamp} ITimestamp Instant in datetime specifing time (in milliseconds) since the JavaScript epoch. */
/** @typedef {import('@/utils/filters').IUnixTimestamp} IUnixTimestamp Instant in datetime specifing time (in seconds) since the Unix epoch */
/** @typedef {import('@/utils/filters').IInterval} IInterval An object that combines two dates to represent the time interval. */

/**
 * @class
 * Record containing the inclusive start and end date range values.
 * @implements {IInterval}
 */
export class DateRange {
    /** Minimum allowed safe time (in milliseconds). */
    static MIN_SAFE_TIME = /** @type {const} */ (-864000000000000);

    /** Maximum allowed safe time (in milliseconds). */
    static MAX_SAFE_TIME = /** @type {const} */ (864000000000000);

    /** Minimum allowed safe date. */
    static get MIN_SAFE_DATE() {
        return toDate(DateRange.MIN_SAFE_TIME);
    }

    /** Maximum allowed safe date. */
    static get MAX_SAFE_DATE() {
        return toDate(DateRange.MAX_SAFE_TIME);
    }

    /**
     * Create an date range.
     * @param {Readonly<Partial<IInterval>>} [props]
     * @returns {IInterval}
     */
    static create = (props = {}) => new DateRange(props);

    /**
     * Clone an existing date range.
     * @param {Readonly<IInterval>} [source]
     * @returns {IInterval}
     */
    static clone = (source = new DateRange()) => new DateRange(source);

    /**
     * Extract only the data members from an existing instance.
     * @param {Readonly<IInterval>} [target]
     * @returns {IInterval}
     */
    static data = (target) => pick(target, 'start', 'end');

    /**
     * Clamps a date to the lower bound with the start of the interval and the upper bound with the end of the interval.
     * - When the date is less than the start of the interval (or undefined), the start is returned.
     * - When the date is greater than the end of the interval, the end is returned.
     * - Otherwise the date is returned.
     * @param {IDate} date
     * @param {Readonly<Partial<IInterval>>} range
     */
    static clamp = (date, range) => {
        // <!-- DESTRUCTURE -->
        const {
            start = DateRange.MIN_SAFE_TIME,
            end = DateRange.MAX_SAFE_TIME,
        } = range ?? {};

        // <!-- EXPOSE -->
        const input = date ?? start; // If no date is provided, we default to the start date of the range.
        const clamped = clamp(input, { start, end });
        return clamped;
    };

    /**
     * Determine if the supplied date range is valid.
     * @param {IInterval} range
     */
    static isValid = ({ start, end }) => {
        // Date range is valid when:
        // - Start date is not nil and is a number or valid Date instance.
        // - End date is not nil and is a number or valid Date instance.
        // - Start date is before the end date.
        if (isValid(start) && isValid(end)) {
            const timeStart = toDate(start).getTime();
            const isStartDateValid =
                !Number.isNaN(timeStart) && Number.isFinite(timeStart);

            const timeEnd = toDate(end).getTime();
            const isEndDateValid =
                !Number.isNaN(timeEnd) && Number.isFinite(timeEnd);

            const isIntervalValid = isStartDateValid && isEndDateValid;
            if (isIntervalValid) {
                // If the individual components are valid, ensure start is less than the end date.
                const isStartLessThanOrEqualToEnd = lte(timeStart, timeEnd);
                return isStartLessThanOrEqualToEnd === true;
            }
        }
        // Invalid interval components.
        return false;
    };

    /**
     * Create an date range.
     * @param {Readonly<Partial<IInterval>>} [props]
     */
    constructor(props) {
        const { start = NaN, end = NaN } = props ?? {};

        /** @type {IInterval['start']} The start of the date range. */
        this.start = start.valueOf();

        /** @type {IInterval['end']} The end of the date range. */
        this.end = end.valueOf();
    }
}

// <!-- DEFAULT -->
export default DateRange;
